import React from 'react';
// import { validateEmail } from '../helpers/general';
import { withPrefix } from 'gatsby';
import { Link } from 'gatsby';
import loginArrow from "../../static/AAA-images/login-arrow.svg"
// import * as styles from './forgot.module.css';

// import Layout from '../components/Layout/Layout';
// import FormInputField from '../components/FormInputField/FormInputField';
// import Button from '../components/Button';
// import AttributeGrid from '../components/AttributeGrid';

const ForgotPage = (props) => {
  // const [email, setEmail] = useState('');
  // const [error, setError] = useState('');

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validateEmail(email) !== true) {
  //     setError('Not a valid email address');
  //     return;
  //   }
  //   setEmail('');
  //   setError(''); 
  // };

  return (
    // <Layout disablePaddingBottom>
    //   <div className={styles.root}>
    //     <h1 className={styles.title}>Reset Password</h1>
    //     <p className={styles.message}>
    //       Fill in your email below to request a new password. An email will be
    //       sent to the address below containing a link to verify your email
    //       address.
    //     </p>
    //     <form
    //       className={styles.formContainer}
    //       noValidate
    //       onSubmit={(e) => handleSubmit(e)}
    //     >
    //       <FormInputField
    //         id={'email'}
    //         value={email}
    //         handleChange={(_, e) => setEmail(e)}
    //         type={'email'}
    //         labelName={'Email'}
    //         error={error}
    //       />
    //       <div className={styles.buttonContainer}>
    //         <Button fullWidth level={'primary'} type={'submit'}>
    //           reset password
    //         </Button>
    //       </div>
    //     </form>
    //   </div>
    //   <div className={styles.gridContainer}>
    //     <AttributeGrid />
    //   </div>
    // </Layout>

    <>
    
    <section className="login-background" style={{ backgroundImage: `url(${withPrefix('AAA-images/login-bg.png')})` }}>
            <div className="align-items-center container d-flex justify-content-center vh-100">
                <div className="w-32">          
                            <div className="bg-white row rounded-3  w-22">

                                <div className="col-md-12">
                                    <div className="py-5 px-3">
                                        <div className="pb-3">
                                        
                                            <div href="#" className="forgot-arrow cmn-backarrow">
                                                <div >
                                                  <Link to={`/login`}>
                                                    <img src={loginArrow} height="40px" width="40px" alt='backArrow' />
                                                  </Link>
                                                </div>
                                                <div>
                                                  <h6 className="fw-bolder fs-3">Forgot Password</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                                <div className="col-md-12 mt-2">
                                                    <small>A password reset link will sent to your email. If you didn't receive any mail within a few minutes, please retry.</small>
                                                </div>
                                        </div>
                                        <form className='subscribe'>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='natasha.james@mail.com' />
                                            </div>
                                            <div className="py-4 d-flex align-items-center justify-content-center gap-4">
                                                <div>
                                                    <Link to={`/login`} className="text-decoration-none fw-bold color small">Login</Link>
                                                </div>
                                                <button type="submit" className="btn button-primary text-white fw-bold">
                                                  <Link to={`/sendLink`} className='text-white'>Send Link</Link>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </section>
    
    </>
  );
};

export default ForgotPage;
