import React from 'react';
// import * as styles from './BlogPreviewGrid.module.css';

import BlogPreview from '../BlogPreview';

const BlogPreviewGrid = (props) => {
  const { data, hideReadMoreOnWeb, showExcerpt } = props;
  return (
    <div className="">
      <div className='text-heading'>
          <h5 className='fs-4 fw-bold pb-3'>New Blogs</h5>
      </div>
      <div className="g-4 row row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4">
        
      {data &&
        data.map((blog, index) => {
          return (
            <BlogPreview
              key={index}
              avatarImage= {blog.avatarImage}
              userName={blog.userName}
              cardTitle={blog.cardTitle}
              cardDescription={blog.cardDescription}
              cardImage={blog.cardImage}
              date={blog.date}
              role={blog.role}
              updatedTime={blog.updatedTime}
              link={blog.link}

              // image={blog.image}
              // altImage={blog.alt}
              // title={blog.title}
              // link={blog.link}
              // category={blog.category}
              // excerpt={blog.excerpt}
              // hideReadMoreOnWeb={hideReadMoreOnWeb}
              // showExcerpt={showExcerpt}
            />
          );
        })}
        </div>
    </div>
  );
};

export default BlogPreviewGrid;
