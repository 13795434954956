import * as React from 'react';

// import AttributeGrid from '../components/AttributeGrid';
import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import Quote from '../components/Quote';
import Blog from '../pages/blog';
// import Title from '../components/Title';

// import { generateMockBlogData, generateMockProductData } from '../helpers/mock';

// import * as styles from './index.module.css';
// import { navigate } from 'gatsby';

const IndexPage = () => {
  // const newArrivals = generateMockProductData(3, 'shirt');
  // const blogData = generateMockBlogData(3);

  // const goToShop = () => {
  //   navigate('/shop');
  // };

  return (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      <Hero
        maxWidth={'500px'}
        title={'AAA Academy'}
      />


      {/* Quote */}
      <Quote
        
      />

<Blog/>
      
      {/* <AttributeGrid /> */}
    </Layout>
  );
};

export default IndexPage;
