import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import useCaseFlow from "../../../static/AAA-images/useCaseFlow.png"
import customerRegistration from "../../../static/AAA-images/customerRegistration.png"
import thankYouForRegisteringUs from "../../../static/AAA-images/thankYouForRegisteringUs.png"
import customerList from "../../../static/AAA-images/customerList.png"
import sendSMS from "../../../static/AAA-images/sendSMS.jpg"
import sendMessageFromTwlio from "../../../static/AAA-images/sendMessageFromTwlio.jpg"
import customerListTrue from "../../../static/AAA-images/customerListTrue.png"
import addAnExistingSolution from "../../../static/AAA-images/addAnExistingSolution.png"
import createNewSolutionABP from "../../../static/AAA-images/createNewSolutionABP.png"
import abpWebHooksDemoAPI from "../../../static/AAA-images/abpWebHooksDemoAPI.png"
import solutionExplorer from "../../../static/AAA-images/solutionExplorer.png"
import createDtoClasses from "../../../static/AAA-images/createDtoClasses.png"
import publishSucceeded from "../../../static/AAA-images/publishSucceeded.png"
import activeNumbers from "../../../static/AAA-images/activeNumbers.png"
import messaging from "../../../static/AAA-images/messaging.png"
import createDatabaseImg from "../../../static/AAA-images/createDatabaseImg.png"
import deployingAbpImg from "../../../static/AAA-images/deployingAbpImg.png"
import integratingImg from "../../../static/AAA-images/integratingImg.png"
import { useState, useEffect } from "react";

import relatedblog12 from "../../../static/AAA-images/Create-database-per-tenant-in-SQL-Elastic-Pool-with-ABP-Commercial.png"
import relatedblog1 from "../../../static/AAA-images/Implement-Single-Sign-On-with-ABP-commercial.png"
import relatedblog2 from "../../../static/AAA-images/gradient.png"


const SamplePage = (props) => {
    const abpUsername = `abp login <username>`;
    const abpSuiteInstall = `abp suite install`;
    const abpSuite = `abp suite`;
    const classCustomer = `public class Customer: AuditedAggregateRoot<long>
    {
     public string Name { get; set; }
     public string Address { get; set; }
     public string MobileNumber { get; set; }
     public bool VerificationStatus { get; set; }
    }
    public class TwilioSmsLog : AuditedAggregateRoot<long>
    {
     public string FromNumber { get; set; }
     public string SmsText { get; set; }
    } `;
    const dbset = `public DbSet<Customer> customers { get; set; }`;

    const dbsetTwo = `public DbSet<TwilioSmsLog> twilioSmsLogs { get; set; }`;
    const AbpWebHooksDemoDataSeederContributor = `
    public class AbpWebHooksDemoDataSeederContributor: IDataSeedContributor, ITransientDependency
{
  private readonly IRepository <Customer> _customerRepository;
  public AbpWebHooksDemoDataSeederContributor (IRepository <Customer, long> customerRepository)
   {
    _customerRepository = customerRepository;
   }

    public async Task SeedAsync(DataSeedContext context)
     {
      //Customers
      if (await _customerRepository.GetCountAsync() <= 0)
        {
         await _customerRepository.InsertAsync(
         new Customer
            {
              Name = "Shiya",
              Address = "Address1",
              MobileNumber = "9633747314",
              VerificationStatus = false
            }
        );

         await _customerRepository.InsertAsync(
         new Customer
            {
              Name = "Vidya",
              Address = "Address2",
              MobileNumber = "7012458378",
              VerificationStatus = false
            }
        );
        }

    }
}`;
    const ICustomerAppService = `public interface ICustomerAppService : IApplicationService
    {
       Task<PagedResultDto<CustomerDto>> GetCustomerListListAsync(GetCustomerListDto input);
   
       Task<CustomerDto> CreateCustomerAsync(CreateCustomerDto input);
   
       Task UpdateCustomerASVerifiedAsync(long id);
   
       Task<TwilioSmsLogDto> CreateSmsLogAsync(CreateTwilioSmsLogDto input);
   
    } `;
    const AbpWebHooksDemoApplicationAutoMapperProfile = `
    public AbpWebHooksDemoApplicationAutoMapperProfile()
    {
       CreateMap<Customer, CustomerDto>();
       CreateMap<TwilioSmsLog, TwilioSmsLogDto>();
    }`;
    const customerAppService = `
    namespace AbpWebHooksDemo.Customers
{
 public class CustomerAppService : AbpWebHooksDemoAppService, ICustomerAppService
  {
    private readonly IRepository<Customer> _customerRepository;
    private readonly IRepository<TwilioSmsLog> _twilioSmsLogRepository;
    public CustomerAppService(IRepository<Customer, long> customerRepository, IRepository<TwilioSmsLog> twilioSmsLogRepository)
     {
        _customerRepository = customerRepository;
        _twilioSmsLogRepository = twilioSmsLogRepository;
     }
       
        public async Task<CustomerDto> CreateCustomerAsync(CreateCustomerDto input)
         {
            var customer = new Customer
            {
                Name = input.Name,
                Address = input.Address,
                MobileNumber =input.MobileNumber,
                VerificationStatus = input.VerificationStatus
            };
            await _customerRepository.InsertAsync(customer, autoSave: true);
            return ObjectMapper.Map<Customer, CustomerDto>(customer);
         }

        public async Task<TwilioSmsLogDto> CreateSmsLogAsync(CreateTwilioSmsLogDto input)
         {
            var twilioSmsLog = new TwilioSmsLog
            {
                SmsText = input.SmsText,
                FromNumber = input.FromNumber
            };
            await _twilioSmsLogRepository.InsertAsync(twilioSmsLog);
            //split sms text to get customer Id
            String[] strlist = twilioSmsLog.SmsText.Split("-", StringSplitOptions.RemoveEmptyEntries);
            long id = Convert.ToInt64(strlist[1]);
            await UpdateCustomerASVerifiedAsync(id);
            return ObjectMapper.Map<TwilioSmsLog, TwilioSmsLogDto>(twilioSmsLog);
         }

        public async Task<PagedResultDto<CustomerDto>> GetCustomerListListAsync(GetCustomerListDto input)
         {
            if (input.Sorting.IsNullOrWhiteSpace())
            {
                input.Sorting = nameof(Customer.Name);
            }

            var customers = await _customerRepository.GetListAsync();

            var totalCount = input.Filter == null
                ? await _customerRepository.CountAsync()
                : await _customerRepository.CountAsync(
                    customer => customer.Name.Contains(input.Filter));

            return new PagedResultDto<CustomerDto>(
                totalCount,
                ObjectMapper.Map<List<Customer>, List<CustomerDto>>(customers)
            );
         }

        public async Task UpdateCustomerASVerifiedAsync(long id)
         {
            var queryable = await _customerRepository.GetQueryableAsync();
            var customer = queryable.Where(x => x.Id == id).FirstOrDefault();
            customer.VerificationStatus = true;
            await _customerRepository.UpdateAsync(customer);
         }
    }
}`;
    const packageTwilio = `dotnet add package Twilio`;
    const packageTwilioASP = `dotnet add package Twilio.AspNet.Core`;
    const installTwilioASP = `Install-Package Twilio.AspNet.Core`;
    const usingTwilio = `
using Twilio.TwiML;
using Twilio.AspNet.Core;
using Twilio.AspNet.Common;`;
    const ManageTwilioController = `public class ManageTwilioController : TwilioController
 {
    private readonly CustomerAppService customerAppService;

    public ManageTwilioController(CustomerAppService _customerAppService)
    {
        customerAppService = _customerAppService;
    }

    //To handle Text message
    [HttpPost]
    public async Task<TwiMLResult> sms(SmsRequest request)
    {
        var response = new MessagingResponse();
        CreateTwilioSmsLogDto twilioSms = new CreateTwilioSmsLogDto();
        twilioSms.SmsText = request.Body;
        twilioSms.FromNumber= request.From;
        await customerAppService.CreateSmsLogAsync(twilioSms);
        response.Message("Your Verification Successfully Completed");
        return TwiML(response);
    }

    //To handle Voice message
    [HttpPost]
    public TwiMLResult voice(VoiceRequest request)
    {
        var response = new VoiceResponse();
        response.Say("Your Verification Successfully Completed");
        return TwiML(response);
    }
 } `;
    


    return (
        <Layout >
        <div className="">
            <Container>
                <div className="">
                    <section className='mb-5'>
                        <div className="container mt-5">
                            <div className="row ">
                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                    <div className="row d-flex justify-content-between py-2">
                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                            <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                                <p className="mb-0 small text-muted">Jul 22, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                                <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                                <div className="rate p-0">
                                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                                </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </span>
                                                </div>
    
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                            <div className="">
    
                                                <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                    <Link to="https://www.linkedin.com/company/hire-tech-team/">
    
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                        </svg>
                                                    </Link>
                                                    <Link to="https://twitter.com/HIRETECHTEAM">
                                                     
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                        </svg></Link>
                                                    <Link to="https://vimeo.com">
                                                      
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                        </svg></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-between">
    
                                        {/* Card details */}
                                        <h4 className="text-dark fw-bolder mt-5">Using Webhooks in ABP Commercial</h4>
                                         <h5 className="text-dark fw-bolder mt-5 mb-2">Why are we using webhook?</h5>
                                        <p className="details">Webhooks allow interaction between web-based applications with custom callbacks. The use of webhooks allows web applications to automatically communicate with other web-apps.</p>
                                        <p className="details">Unlike other APIs that poll for data, which means they must check constantly for triggering events, webhooks allow applications to push data from triggering events into other applications as soon as it appears.</p>
                                        <p className="details">This is like getting a notification when something changes in an application, rather than having to check it yourself frequently, which reduces wait time enables real-time data transfer.</p>
                                         <p className="details mb-5">The instant transfer of data between applications increases efficiency because you can simply use a webhook in your code to deliver data instead of long running processes or manual checking for changes. Webhooks operate entirely over the internet and so the communication happens in the form of HTTP messages.</p>
                                         <h4 className="text-dark fw-bolder mt-4 mb-2">Establishing communication between two applications with webhook</h4>
                                        <p className="details" > Here we connect Twilio (Publisher) and a ABP Commercial web application (subscriber). Webhooks rely on the presence of static URLs which point to APIs in the subscriber ‘s system that must be notified when an event occurs on the publisher system.</p>
                                         <p className="details">In this example, whenever a message is received on a Twilio number, it will push the payload (the SMS Details) to our ABP web application. We can save this payload to our database and perform some database operations based on the data. </p>

                                        
                                       
                                            <p className="details mb-5">Use Case</p>
                                            <img className="py-3" src={useCaseFlow} alt="Use Case Flow" />
                                            <p className="details py-4">Here we have a customer registration form.</p>
                                            <img className="py-3" src={customerRegistration} alt="Customer Registration" />
                                            <p className="details py-4">Customer will get a response message after submitting the registration form</p>
                                            <img className="py-3" src={thankYouForRegisteringUs} alt="Thank You For Registering Us" />
                                            <p className="details pt-4">The number displayed in the message is a Twilio number. If the customer sends SMS to this Twilio number our application will get notified about it. We will save the SMS details to our database table (AppTwilioSmsLogs).</p>
                                            <p className="details">Customer list</p>
                                            <p className="details mb-5">By default, all customer’s verification status is set as false. Once the SMS is sent, it will be updated as true after verifying the mobile number and customer Id from the SMS details.</p>
                                            <img className="py-3" src={customerList} alt="Customer List" />
                                            <p className="details py-4">Now we can send SMS to Twilio number</p>
                                            <img className="py-3 w-25" src={sendSMS} alt="Send SMS" />
                                            <p className="details py-4">Then customer will get response message and at the same time Twilio will send the SMS details to our web API through the webhook static URL which is configured in Twilio console.</p>
                                            <img className="py-3 w-25" src={sendMessageFromTwlio} alt="Send Message From Twlio" />
                                            <p className="details pt-4">SMS text contains a numeric field (eg: REG-23), this is our newly registered customer’s Id (fetched from customer table).</p>
                                            <p className="details mb-5">We will extract this customer id from this text when our API get SMS details from Twilio with webhook and check for the customer id existence in our db. If the customer id is valid, then we will update the customer table column “VerificationStatus” as true.</p>
                                            <img className="pt-3 pb-5" src={customerListTrue} alt="Customer List Table" />
                                            <h5 className="fw-bolder mb-2 pt-3">Web Application development</h5>
                                            <p className="details mb-2">Create a new solution in ABP Framework:</p>
                                            <p className="details mb-2">Login to your account</p>
                                            <p className="details mb-5">To use ABP Commercial features, you need to login your account using the ABP CLI:</p>
                                            <CodeSnippet codeString={abpUsername} />
                                            <p className="details pt-4 mb-2">Install the ABP Suite</p>
                                            <p className="details mb-2">ABP Suite is an application aims to assist you on your development.</p>
                                            <p className="details mb-5">First, you need to install the ABP Suite:</p>
                                            <CodeSnippet codeString={abpSuiteInstall} />
                                            <p className="details py-4">Run the ABP Suite with the following command:</p>
                                            <CodeSnippet codeString={abpSuite} />
                                            <img className="py-3 mt-4" src={addAnExistingSolution} alt="Add An Existing Solution" />
                                            <p className="details py-4">Click to the Create a new solution button and fill the modal form:</p>
                                            <img className="py-3" src={createNewSolutionABP} alt="Create New Solution ABP" />
                                            <h5 className="fw-bolder pt-5">Create the Database</h5>
                                            <p className="details">Check the connection string in the appsettings.json file under. HttpApi.Host project.</p>
                                            <p className="details mb-5">change the server’s name and database name as yours. Once you done with this, then add migration using NuGet Package Manager Console and update the database. To seed the initial data, you need to use. DbMigrator at least once for a new database. Initial seed data creates the admin user in the database (with the password is 1q2w3E*) which is then used to login to the application.</p>
                                            <h5 className="fw-bolder">Running the HTTP API Host (Server Side)</h5>
                                            <p className="details mb-5">Ensure that the. HttpApi.Host project is the startup project and run the application which will open a Swagger UI:</p>
                                            <img className="py-3" src={abpWebHooksDemoAPI} alt="abpWebHooksDemoAPI" />
                                            <h5 className="fw-bolder py-4">This is our solution</h5>
                                            <img className="py-3" src={solutionExplorer} alt="Solution Explorer" />
                                            <h5 className="fw-bolder pt-5 mb-2">Creating Custom entity</h5>
                                            <p className="details mb-2">We are going to create customer and Twilio SMS logs entity first</p>
                                            <p className="details mb-5">Create a new folder “Customers” in .Domain project and create two entity classes.</p>
                                            <CodeSnippet codeString={classCustomer} />
                                            <p className="details py-4">Add entities to the dB Context and map the Entities to a database table in the .Entity Framework Core project:</p>
                                            <CodeSnippet className="mb-4" codeString={dbset} />
                                            <CodeSnippet codeString={dbsetTwo} />
                                            <p className="details pt-5 mb-2">then add migration and update database.</p>
                                            <p className="details mb-2">Add sample seed data:</p>
                                            <p className="details mb-5">Create a class deriving from the IDataSeedContributor in the *.Domain project by copying the following code:</p>
                                            <CodeSnippet codeString={AbpWebHooksDemoDataSeederContributor} />
                                            <h5 className="fw-bolder pt-5 mb-2">Create the Application Service</h5>
                                            <p className="details mb-5">Create DTO classes for the application service</p>
                                            <img className="py-3" src={createDtoClasses} alt="Create Dto Classes" />
                                            <h5 className="fw-bolder pt-4 pb-5">Customer service Interface</h5>
                                            <CodeSnippet codeString={ICustomerAppService} />
                                            <p className="details pt-4">It will be needed to map customer entities to customer DTO objects while returning customer to the presentation layer.</p>
                                            <p className="details pb-5">AutoMapper library can automate this conversion when you define the proper mapping. The startup template comes with AutoMapper configured, so you can just define the mapping in the AbpWebHooksDemoApplicationAutoMapperProfile class in the AbpWebHooksDemo.Application project:</p>
                                            <CodeSnippet codeString={AbpWebHooksDemoApplicationAutoMapperProfile} />
                                            <p className="details pt-4 pb-5">Create customer service in the. Application project and paste the following code:</p>
                                            <CodeSnippet codeString={customerAppService} />
                                            <p className="details pt-4 pb-4">Set up an angular project. Then create new two forms “customer registration” and “customer list”.</p>
                                            <h5 className="fw-bolder pt-3 mb-2">Integrate Twilio into application</h5>
                                            <p className="details mb-2">First register in the Twilio website and buy a Twilio phone number.</p>
                                            <p className="details mb-5">You will get all the step-by-step details of the procedures from Twilio website. <strong> If you are using a trial Twilio account, you can only send text messages to</strong> Verified Caller IDs. <strong>Verify your phone number or the phone number you want to SMS if it is not on the list of Verified Caller IDs.</strong></p>
                                            <h5 className="fw-bolder mb-2">1.  Create a SMS Endpoint</h5>
                                            <p className="details mb-2">You need to create an endpoint for a POST request called “sms”. This endpoint is where Twilio will send an HTTP request when a message is sent to your Twilio phone number.</p>
                                            <p className="details mb-2">Our sms endpoint will be configured as the webhook URL in the Twilio Console.</p>
                                            <p className="details mb-2">The following code creates an endpoint handling HTTP POST requests to /sms. This endpoint returns TwiML or Twilio Markup Language to tell Twilio how to respond to the incoming message.</p>
                                            <p className="details mb-2">We used the Twilio SDK for C# and .NET. The Twilio SDK has APIs to build the TwiML for us. To add the SDK, you need to add the Twilio NuGet package.</p>
                                            <p className="details mb-5">You can install the NuGet package with the .NET CLI using the following command:</p>
                                            <CodeSnippet codeString={packageTwilio} />
                                            <CodeSnippet codeString={packageTwilioASP} />
                                            <p className="details py-4">Alternatively, from the Package Manager Console or Developer PowerShell, run the following command to install the latest version:</p>
                                            <CodeSnippet codeString={installTwilioASP} />
                                            <p className="details pt-5 mb-2">Create a new controller called ManageTwilioController in .HttpApi.Host project :</p>
                                            <p className="details pb-5">First add the following namespaces</p>
                                            <CodeSnippet codeString={usingTwilio} />
                                            <p className="details py-4">Then paste the following code:</p>
                                            <CodeSnippet codeString={ManageTwilioController} />
                                            <p className="details py-4">Publish your API in Azure and configure your API Url in Twilio console as webhook URL.</p>
                                            <img className="py-3" src={publishSucceeded} alt="Publish Succeeded" />
                                            <h5 className="fw-bolder mb-2 pt-5">2. Configuring your API in Twilio console</h5>
                                            <p className="details mb-2">Now you need to configure your Twilio Phone Number to send HTTP requests to your server. Navigate to Phone Numbers - Manage - Active Numbers.</p>
                                            <p className="details mb-2">Select the number you purchased earlier. Then scroll down to the Messaging section to configure what happens when a message comes to our Twilio number.</p>
                                            <p className="details mb-2">Update the section that says When a Message Comes In. Make sure Webhook is selected and paste your API URL HTTPS Forwarding followed by /sms URL in the text box to send webhook HTTP requests to the /sms endpoint.</p>
                                            <p className="details mb-5">Make sure the far right drop down has HTTP POST selected and save your changes to the phone number configuration.</p>
                                            <img className="py-3 mb-4" src={activeNumbers} alt="Active Numbers" />
                                            <img className="py-3 mb-4" src={messaging} alt="Messaging" />
                                            <p className="details py-4">Now, try sending a message to your Twilio Phone Number from your cell phone. Then Twilio will send SMS details to our API through webhook URL. We will update our customer as verified and save the SMS log details to our table in the database with these details.</p>
                                      
                                  
                                    </div>
                                   
    
                                    {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                                    <div className="py-3 mt-3">
                                        <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                        <span className="featured-date ms-4">Today at 5:42PM</span>
                                                        <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                        <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                        <span className="featured-date ms-4">Today at 5:42PM</span>
                                                        <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                        <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mx-5">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                        <span className="featured-date ms-4">Today at 5:42PM</span>
                                                        <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                        <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row aarw">
                                        <div className="col-12">
                                            <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
                                                        <img className="card-img-top" src={relatedblog12} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt='username avatar' className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">26/07/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Create database per tenant in SQL Elastic Pool with ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this short article let me show you the steps to create tenant wise databases... </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/sample">
                                                        <img className="card-img-top" src={relatedblog1} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt='username avatar' className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Implement Single Sign-On with ABP commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Learn how to implement SSO between an ASP.Net core MVC application and ABP Commercial...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/xamarinOfflineDataSync">
                                                        <img className="card-img-top" src={relatedblog2} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt='username avatar' className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Xamarin Offline data sync with Mongo Realm and RabbitMQ</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12 min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article you will learn how to integrate Realm in the Xamarin Forms project and sync the offline...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                    <div className="row d-flex justify-content-between py-3 align-items-center">
                                        <div className="col-md-6">
                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span>Kirti Kulkarni</span>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="align-items-end d-flex justify-content-end">
                                                <div className="footer-social d-flex gap-0">
                                                    <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                        {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                    <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                    <div>
                                        <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                        <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
                                            <div className="card mb-3 px-3 py-2">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                        <h5 className="card-title">
                                                            <div className="d-flex align-items-center">
                                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                        <div>
                                                                            <span className="fs-6">Kirti Kulkarni</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <p className="card-text details fw-bold lh-sm">Create database per tenant in SQL Elastic Pool with ABP Commercial</p>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                        <div className="card-body p-0">
                                                        <img src={createDatabaseImg} className="img-fluid" alt="Create database per tenant in SQL Elastic Pool with ABP Commercial" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
                                            <div className="card mb-3 px-3 py-2">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                        <h5 className="card-title">
                                                            <div className="d-flex align-items-center">
                                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                        <div>
                                                                            <span className="fs-6">Kirti Kulkarni</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <p className="card-text details fw-bold lh-sm">Deploying ABP angular application to Azure and App Insights integration</p>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                        <div className="card-body p-0">
                                                        <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link className="nav-link" to="/blog/integratingAndEnablingChat">
                                            <div className="card mb-3 px-3 py-2">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                        <h5 className="card-title">
                                                            <div className="d-flex align-items-center">
                                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                        <div>
                                                                            <span className="fs-6">Kirti Kulkarni</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <p className="card-text details fw-bold lh-sm">Integrating and enabling the Chat module in ABP Commercial</p>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                        <div className="card-body p-0">
                                                        <img src={integratingImg} className="img-fluid" alt="Integrating and enabling the Chat module in ABP Commercial" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </section>
    
                </div>
            </Container>
    
        </div>
    
    
    
    </Layout>
        // <Layout>
        //     <div className="">
        //         <Container>
        //             <section className='mb-5'>
        //                 <div className="container mt-5">
        //                     <div className="row ">
        //                         <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
        //                             <div className="row d-flex justify-content-between py-2">
        //                                 <div className="col-md-6">
        //                                     <div className="d-flex align-items-center">
        //                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                 <div>
        //                                                     <span className="fs-3">Kirti Kulkarni</span>
        //                                                     <p className="mb-0 text-muted">Jul 22, 2022 <span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
        //                                                     </p>
        //                                                 </div>
        //                                             </span>
        //                                         </div>
        //                                         <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
        //                                             <div className="rate p-0 d-flex align-items-end mt-4">
        //                                                 <span className="font-normal">5</span>
        //                                                 <input type="radio" id="star5" name="rate" value="5" />
        //                                                 <label htmlFor="star5" title="text">5 stars</label>
        //                                                 <input type="radio" id="star4" name="rate" value="4" />
        //                                                 <label htmlFor="star4" title="text">4 stars</label>
        //                                                 <input type="radio" id="star3" name="rate" value="3" />
        //                                                 <label htmlFor="star3" title="text">3 stars</label>
        //                                                 <input type="radio" id="star2" name="rate" value="2" />
        //                                                 <label htmlFor="star2" title="text">2 stars</label>
        //                                                 <input type="radio" id="star1" name="rate" value="1" />
        //                                                 <label htmlFor="star1" title="text">1 star</label>
        //                                             </div>
        //                                             <span className="font-normal">12</span>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className="col-md-6">
        //                                     <div className="align-items-end d-flex justify-content-end">
        //                                         <div className="footer-social d-flex gap-0">
        //                                             <Link to="https://www.linkedin.com/company/hire-tech-team/">
        //                                                 {/* <a href="https://www.linkedin.com/company/hire-tech-team/"> */}
        //                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        //                                                 </svg>
        //                                             </Link>
        //                                             <Link to="https://twitter.com/HIRETECHTEAM">
        //                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                     <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
        //                                                 </svg></Link>
        //                                             <Link to="https://vimeo.com">
        //                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                     <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
        //                                                 </svg></Link>

        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>


        //                             <div className="row d-flex justify-content-between">
        //                                 <h4 className="text-dark fw-bolder mt-4">Using Webhooks in ABP Commercial</h4>
        //                                 <h5 className="text-dark fw-bolder mt-4">Why are we using webhook?</h5>
        //                                 <p className="details">Webhooks allow interaction between web-based applications with custom callbacks. The use of webhooks allows web applications to automatically communicate with other web-apps.</p>
        //                                 <p className="details">Unlike other APIs that poll for data, which means they must check constantly for triggering events, webhooks allow applications to push data from triggering events into other applications as soon as it appears.</p>
        //                                 <p className="details">This is like getting a notification when something changes in an application, rather than having to check it yourself frequently, which reduces wait time enables real-time data transfer.</p>
        //                                 <p className="details">The instant transfer of data between applications increases efficiency because you can simply use a webhook in your code to deliver data instead of long running processes or manual checking for changes. Webhooks operate entirely over the internet and so the communication happens in the form of HTTP messages.</p>
        //                                 <h4 className="text-dark fw-bolder mt-4">Establishing communication between two applications with webhook</h4>
        //                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} > Here we connect Twilio (Publisher) and a ABP Commercial web application (subscriber). Webhooks rely on the presence of static URLs which point to APIs in the subscriber ‘s system that must be notified when an event occurs on the publisher system.</p>
        //                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} >In this example, whenever a message is received on a Twilio number, it will push the payload (the SMS Details) to our ABP web application. We can save this payload to our database and perform some database operations based on the data. </p>

        //                                 {!isLoggedIn &&
        //                                     <div className="row bg-light mb-5">
        //                                         <div className="col-lg-4">
        //                                             <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
        //                                         </div>
        //                                         <div className="col-lg-8">
        //                                             <h6 className="mt-3">To read the full post,</h6>
        //                                             <h4 className="fw-bolder">Sign up to AAA Academy</h4>
        //                                             <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
        //                                             <Link to={`/signUp`}>
        //                                                 <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
        //                                             </Link>
        //                                             <p className="mt-2">Already have an account?
        //                                                 <Link to="/login" className="text-decoration-none color"> Sign in
        //                                                 </Link> </p>
        //                                         </div>
        //                                     </div>}
        //                                 {isLoggedIn && <>
                                       
        //                                     <p className="details">Use Case</p>
        //                                     <img className="py-3" src={useCaseFlow} alt="Use Case Flow" />
        //                                     <p className="details">Here we have a customer registration form.</p>
        //                                     <img className="py-3" src={customerRegistration} alt="Customer Registration" />
        //                                     <p className="details">Customer will get a response message after submitting the registration form</p>
        //                                     <img className="py-3" src={thankYouForRegisteringUs} alt="Thank You For Registering Us" />
        //                                     <p className="details">The number displayed in the message is a Twilio number. If the customer sends SMS to this Twilio number our application will get notified about it. We will save the SMS details to our database table (AppTwilioSmsLogs).</p>
        //                                     <p className="details">Customer list</p>
        //                                     <p className="details">By default, all customer’s verification status is set as false. Once the SMS is sent, it will be updated as true after verifying the mobile number and customer Id from the SMS details.</p>
        //                                     <img className="py-3" src={customerList} alt="Customer List" />
        //                                     <p className="details">Now we can send SMS to Twilio number</p>
        //                                     <img className="py-3 w-25" src={sendSMS} alt="Send SMS" />
        //                                     <p className="details">Then customer will get response message and at the same time Twilio will send the SMS details to our web API through the webhook static URL which is configured in Twilio console.</p>
        //                                     <img className="py-3 w-25" src={sendMessageFromTwlio} alt="Send Message From Twlio" />
        //                                     <p className="details">SMS text contains a numeric field (eg: REG-23), this is our newly registered customer’s Id (fetched from customer table).</p>
        //                                     <p className="details">We will extract this customer id from this text when our API get SMS details from Twilio with webhook and check for the customer id existence in our db. If the customer id is valid, then we will update the customer table column “VerificationStatus” as true.</p>
        //                                     <img className="py-3" src={customerListTrue} alt="Customer List Table" />
        //                                     <h5 className="fw-bolder">Web Application development</h5>
        //                                     <p className="details">Create a new solution in ABP Framework:</p>
        //                                     <p className="details">Login to your account</p>
        //                                     <p className="details">To use ABP Commercial features, you need to login your account using the ABP CLI:</p>
        //                                     <CodeSnippet codeString={abpUsername} />
        //                                     <p className="details">Install the ABP Suite</p>
        //                                     <p className="details">ABP Suite is an application aims to assist you on your development.</p>
        //                                     <p className="details">First, you need to install the ABP Suite:</p>
        //                                     <CodeSnippet codeString={abpSuiteInstall} />
        //                                     <p className="details">Run the ABP Suite with the following command:</p>
        //                                     <CodeSnippet codeString={abpSuite} />
        //                                     <img className="py-3" src={addAnExistingSolution} alt="Add An Existing Solution" />
        //                                     <p className="details">Click to the Create a new solution button and fill the modal form:</p>
        //                                     <img className="py-3" src={createNewSolutionABP} alt="Create New Solution ABP" />
        //                                     <h5 className="fw-bolder">Create the Database</h5>
        //                                     <p className="details">Check the connection string in the appsettings.json file under. HttpApi.Host project.</p>
        //                                     <p className="details">change the server’s name and database name as yours. Once you done with this, then add migration using NuGet Package Manager Console and update the database. To seed the initial data, you need to use. DbMigrator at least once for a new database. Initial seed data creates the admin user in the database (with the password is 1q2w3E*) which is then used to login to the application.</p>
        //                                     <h5 className="fw-bolder">Running the HTTP API Host (Server Side)</h5>
        //                                     <p className="details">Ensure that the. HttpApi.Host project is the startup project and run the application which will open a Swagger UI:</p>
        //                                     <img className="py-3" src={abpWebHooksDemoAPI} alt="abpWebHooksDemoAPI" />
        //                                     <h5 className="fw-bolder">This is our solution</h5>
        //                                     <img className="py-3" src={solutionExplorer} alt="Solution Explorer" />
        //                                     <h5 className="fw-bolder">Creating Custom entity</h5>
        //                                     <p className="details">We are going to create customer and Twilio SMS logs entity first</p>
        //                                     <p className="details">Create a new folder “Customers” in .Domain project and create two entity classes.</p>
        //                                     <CodeSnippet codeString={classCustomer} />
        //                                     <p className="details">Add entities to the dB Context and map the Entities to a database table in the .Entity Framework Core project:</p>
        //                                     <CodeSnippet codeString={dbset} />
        //                                     <CodeSnippet codeString={dbsetTwo} />
        //                                     <p className="details">then add migration and update database.</p>
        //                                     <p className="details">Add sample seed data:</p>
        //                                     <p className="details">Create a class deriving from the IDataSeedContributor in the *.Domain project by copying the following code:</p>
        //                                     <CodeSnippet codeString={AbpWebHooksDemoDataSeederContributor} />
        //                                     <h5 className="fw-bolder">Create the Application Service</h5>
        //                                     <p className="details">Create DTO classes for the application service</p>
        //                                     <img className="py-3" src={createDtoClasses} alt="Create Dto Classes" />
        //                                     <h5 className="fw-bolder">Customer service Interface</h5>
        //                                     <CodeSnippet codeString={ICustomerAppService} />
        //                                     <p className="details">It will be needed to map customer entities to customer DTO objects while returning customer to the presentation layer.</p>
        //                                     <p className="details">AutoMapper library can automate this conversion when you define the proper mapping. The startup template comes with AutoMapper configured, so you can just define the mapping in the AbpWebHooksDemoApplicationAutoMapperProfile class in the AbpWebHooksDemo.Application project:</p>
        //                                     <CodeSnippet codeString={AbpWebHooksDemoApplicationAutoMapperProfile} />
        //                                     <p className="details">Create customer service in the. Application project and paste the following code:</p>
        //                                     <CodeSnippet codeString={customerAppService} />
        //                                     <p className="details">Set up an angular project. Then create new two forms “customer registration” and “customer list”.</p>
        //                                     <h5 className="fw-bolder">Integrate Twilio into application</h5>
        //                                     <p className="details">First register in the Twilio website and buy a Twilio phone number.</p>
        //                                     <p className="details">You will get all the step-by-step details of the procedures from Twilio website. <strong> If you are using a trial Twilio account, you can only send text messages to</strong> Verified Caller IDs. <strong>Verify your phone number or the phone number you want to SMS if it is not on the list of Verified Caller IDs.</strong></p>
        //                                     <h5 className="fw-bolder">1.  Create a SMS Endpoint</h5>
        //                                     <p className="details">You need to create an endpoint for a POST request called “sms”. This endpoint is where Twilio will send an HTTP request when a message is sent to your Twilio phone number.</p>
        //                                     <p className="details">Our sms endpoint will be configured as the webhook URL in the Twilio Console.</p>
        //                                     <p className="details">The following code creates an endpoint handling HTTP POST requests to /sms. This endpoint returns TwiML or Twilio Markup Language to tell Twilio how to respond to the incoming message.</p>
        //                                     <p className="details">We used the Twilio SDK for C# and .NET. The Twilio SDK has APIs to build the TwiML for us. To add the SDK, you need to add the Twilio NuGet package.</p>
        //                                     <p className="details">You can install the NuGet package with the .NET CLI using the following command:</p>
        //                                     <CodeSnippet codeString={packageTwilio} />
        //                                     <CodeSnippet codeString={packageTwilioASP} />
        //                                     <p className="details">Alternatively, from the Package Manager Console or Developer PowerShell, run the following command to install the latest version:</p>
        //                                     <CodeSnippet codeString={installTwilioASP} />
        //                                     <p className="details">Create a new controller called ManageTwilioController in .HttpApi.Host project :</p>
        //                                     <p className="details">First add the following namespaces</p>
        //                                     <CodeSnippet codeString={usingTwilio} />
        //                                     <p className="details">Then paste the following code:</p>
        //                                     <CodeSnippet codeString={ManageTwilioController} />
        //                                     <p className="details">Publish your API in Azure and configure your API Url in Twilio console as webhook URL.</p>
        //                                     <img className="py-3" src={publishSucceeded} alt="Publish Succeeded" />
        //                                     <h5 className="fw-bolder">2. Configuring your API in Twilio console</h5>
        //                                     <p className="details">Now you need to configure your Twilio Phone Number to send HTTP requests to your server. Navigate to Phone Numbers - Manage - Active Numbers.</p>
        //                                     <p className="details">Select the number you purchased earlier. Then scroll down to the Messaging section to configure what happens when a message comes to our Twilio number.</p>
        //                                     <p className="details">Update the section that says When a Message Comes In. Make sure Webhook is selected and paste your API URL HTTPS Forwarding followed by /sms URL in the text box to send webhook HTTP requests to the /sms endpoint.</p>
        //                                     <p className="details">Make sure the far right drop down has HTTP POST selected and save your changes to the phone number configuration.</p>
        //                                     <img className="py-3" src={activeNumbers} alt="Active Numbers" />
        //                                     <img className="py-3" src={messaging} alt="Messaging" />
        //                                     <p className="details">Now, try sending a message to your Twilio Phone Number from your cell phone. Then Twilio will send SMS details to our API through webhook URL. We will update our customer as verified and save the SMS log details to our table in the database with these details.</p></>}
        //                                 </div>
        //                                 <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
        //                                 <div className="py-3">
        //                                     <h4 className="text-dark fw-bolder">Featured Comments</h4>
        //                                     <div className="d-flex align-items-center">
        //                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                             <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                 <div>
        //                                                     <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
        //                                                     <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
        //                                                 </div>
        //                                             </span>
        //                                         </div>
        //                                     </div>
        //                                     <p className="text-muted mx-5">Reply</p>
        //                                     <div className="d-flex align-items-center">
        //                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                             <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                 <div>
        //                                                     <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
        //                                                     <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
        //                                                 </div>
        //                                             </span>
        //                                         </div>
        //                                     </div>
        //                                     <p className="text-muted mx-5">Reply</p>
        //                                     <div className="d-flex align-items-center mx-5">
        //                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                             <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                 <div>
        //                                                     <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
        //                                                     <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
        //                                                 </div>
        //                                             </span>
        //                                         </div>
        //                                     </div>
        //                                     <p className="text-muted mx-5">Reply</p>
        //                                 </div>
        //                                 <div className="row aarw">
        //                                     <div className="col-12">
        //                                         <h5 className="related-title">More Articles from Joe Thomson</h5>
        //                                     </div>
        //                                     <div className="col-12">
        //                                         <div className="col-12 col-md-6 col-lg-4 mb-4">

        //                                             <div className="card-aa ca1 border-0 h-100">
        //                                                 <Link className="nav-link" to="/footer">
        //                                                     <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
        //                                                     <div className="card-body-aa px-3">
        //                                                         <div className="row mb-2">
        //                                                             <div className="col-md-8">
        //                                                                 <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
        //                                                             </div>
        //                                                             <div className="col-md-4 card-date mt-1">19/02/2023</div>
        //                                                         </div>
        //                                                         <div className="position mb-2">Software Development</div>

        //                                                         <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
        //                                                         <div className="d-flex justify-content-between">
        //                                                             <p className="font-colored">Beginner -5 min</p>
        //                                                             <div>
        //                                                                 <div className="rate p-0">
        //                                                                     <span className="font-normal">4.5</span>
        //                                                                     <input type="radio" id="star5" name="rate" value="5" />
        //                                                                     <label htmlFor="star5" title="text">5 stars</label>
        //                                                                     <input type="radio" id="star4" name="rate" value="4" />
        //                                                                     <label htmlFor="star4" title="text">4 stars</label>
        //                                                                     <input type="radio" id="star3" name="rate" value="3" />
        //                                                                     <label htmlFor="star3" title="text">3 stars</label>
        //                                                                     <input type="radio" id="star2" name="rate" value="2" />
        //                                                                     <label htmlFor="star2" title="text">2 stars</label>
        //                                                                     <input type="radio" id="star1" name="rate" value="1" />
        //                                                                     <label htmlFor="star1" title="text">1 star</label>
        //                                                                 </div>
        //                                                                 <span className="font-normal">520</span>
        //                                                             </div>

        //                                                         </div>
        //                                                         <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
        //                                                     </div>
        //                                                 </Link>
        //                                             </div>

        //                                         </div>
        //                                         <div className="col-12 col-md-6 col-lg-4 mb-4">

        //                                             <div className="card-aa ca1 border-0 h-100">
        //                                                 <Link className="nav-link" to="/footer">
        //                                                     <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
        //                                                     <div className="card-body-aa px-3">
        //                                                         <div className="row mb-2">
        //                                                             <div className="col-md-8">
        //                                                                 <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
        //                                                             </div>
        //                                                             <div className="col-md-4 card-date mt-1">19/02/2023</div>
        //                                                         </div>
        //                                                         <div className="position mb-2">Software Development</div>

        //                                                         <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
        //                                                         <div className="d-flex justify-content-between">
        //                                                             <p className="font-colored">Beginner -5 min</p>
        //                                                             <div>
        //                                                                 <div className="rate p-0">
        //                                                                     <span className="font-normal">4.5</span>
        //                                                                     <input type="radio" id="star5" name="rate" value="5" />
        //                                                                     <label htmlFor="star5" title="text">5 stars</label>
        //                                                                     <input type="radio" id="star4" name="rate" value="4" />
        //                                                                     <label htmlFor="star4" title="text">4 stars</label>
        //                                                                     <input type="radio" id="star3" name="rate" value="3" />
        //                                                                     <label htmlFor="star3" title="text">3 stars</label>
        //                                                                     <input type="radio" id="star2" name="rate" value="2" />
        //                                                                     <label htmlFor="star2" title="text">2 stars</label>
        //                                                                     <input type="radio" id="star1" name="rate" value="1" />
        //                                                                     <label htmlFor="star1" title="text">1 star</label>
        //                                                                 </div>
        //                                                                 <span className="font-normal">520</span>
        //                                                             </div>

        //                                                         </div>
        //                                                         <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
        //                                                     </div>
        //                                                 </Link>
        //                                             </div>
        //                                         </div>
        //                                         <div className="col-12 col-md-6 col-lg-4 mb-4">

        //                                             <div className="card-aa ca1 border-0 h-100">
        //                                                 <Link className="nav-link" to="/footer">
        //                                                     <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
        //                                                     <div className="card-body-aa px-3">
        //                                                         <div className="row mb-2">
        //                                                             <div className="col-md-8">
        //                                                                 <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
        //                                                             </div>
        //                                                             <div className="col-md-4 card-date mt-1">19/02/2023</div>
        //                                                         </div>
        //                                                         <div className="position mb-2">Software Development</div>

        //                                                         <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
        //                                                         <div className="d-flex justify-content-between">
        //                                                             <p className="font-colored">Beginner -5 min</p>
        //                                                             <div>
        //                                                                 <div className="rate p-0">
        //                                                                     <span className="font-normal">4.5</span>
        //                                                                     <input type="radio" id="star5" name="rate" value="5" />
        //                                                                     <label htmlFor="star5" title="text">5 stars</label>
        //                                                                     <input type="radio" id="star4" name="rate" value="4" />
        //                                                                     <label htmlFor="star4" title="text">4 stars</label>
        //                                                                     <input type="radio" id="star3" name="rate" value="3" />
        //                                                                     <label htmlFor="star3" title="text">3 stars</label>
        //                                                                     <input type="radio" id="star2" name="rate" value="2" />
        //                                                                     <label htmlFor="star2" title="text">2 stars</label>
        //                                                                     <input type="radio" id="star1" name="rate" value="1" />
        //                                                                     <label htmlFor="star1" title="text">1 star</label>
        //                                                                 </div>
        //                                                                 <span className="font-normal">520</span>
        //                                                             </div>

        //                                                         </div>
        //                                                         <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
        //                                                     </div>
        //                                                 </Link>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
        //                                 <div className="row d-flex justify-content-between py-3">
        //                                     <div className="col-md-6">
        //                                         <div className="d-flex align-items-center">
        //                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                 <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                     <div>
        //                                                         <span>Kirti Kulkarni</span>
        //                                                     </div>
        //                                                 </span>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="col-md-6">
        //                                         <div className="align-items-end d-flex justify-content-end">

        //                                             <div className="footer-social d-flex gap-0">
        //                                                 <Link to="https://www.linkedin.com/company/hire-tech-team/">
        //                                                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                         <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        //                                                     </svg>
        //                                                 </Link>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <h6 className="about-author">ABOUT THE AUTHOR</h6>
        //                                 <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
        //                                 <div>
        //                                     <h5 className="fw-medium mb-3">Latest Articles</h5>
        //                                     <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
        //                                         <div className="card mb-3 px-3 py-2">
        //                                             <div className="row g-0">
        //                                                 <div className="col-md-8">
        //                                                     <h5 className="card-title">
        //                                                         <div className="d-flex align-items-center">
        //                                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                                 <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                                     <div>
        //                                                                         <span className="fs-6">Kirti Kulkarni</span>
        //                                                                     </div>
        //                                                                 </span>
        //                                                             </div>
        //                                                         </div>
        //                                                     </h5>
        //                                                     <p className="card-text details">Create database per tenant in SQL Elastic Pool with ABP Commercial</p>
        //                                                 </div>
        //                                                 <div className="col-md-4">
        //                                                     <div className="card-body">
        //                                                         <img src={createDatabaseImg} className="img-fluid" alt="Create database per tenant in SQL Elastic Pool with ABP Commercial" />
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </Link>
        //                                     <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
        //                                         <div className="card mb-3 px-3 py-2">
        //                                             <div className="row g-0">
        //                                                 <div className="col-md-8">
        //                                                     <h5 className="card-title">
        //                                                         <div className="d-flex align-items-center">
        //                                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                                 <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                                     <div>
        //                                                                         <span className="fs-6">Kirti Kulkarni</span>
        //                                                                     </div>
        //                                                                 </span>
        //                                                             </div>
        //                                                         </div>
        //                                                     </h5>
        //                                                     <p className="card-text details">Deploying ABP angular application to Azure and App Insights integration</p>
        //                                                 </div>
        //                                                 <div className="col-md-4">
        //                                                     <div className="card-body">
        //                                                         <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </Link>
        //                                     <Link className="nav-link" to="/blog/integratingAndEnablingChat">
        //                                         <div className="card mb-3 px-3 py-2">
        //                                             <div className="row g-0">
        //                                                 <div className="col-md-8">
        //                                                     <h5 className="card-title">
        //                                                         <div className="d-flex align-items-center">
        //                                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                                 <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                                     <div>
        //                                                                         <span className="fs-6">Kirti Kulkarni</span>
        //                                                                     </div>
        //                                                                 </span>
        //                                                             </div>
        //                                                         </div>
        //                                                     </h5>
        //                                                     <p className="card-text details">Integrating and enabling the Chat module in ABP Commercial</p>
        //                                                 </div>
        //                                                 <div className="col-md-4">
        //                                                     <div className="card-body">
        //                                                         <img src={integratingImg} className="img-fluid" alt="Integrating and enabling the Chat module in ABP Commercial" />
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </Link>
        //                                 </div>

        //                             </div>

        //                         </div>
        //                     </div>
        //             </section>
        //         </Container>

        //     </div>



        // </Layout>

    );
};

export default SamplePage;
