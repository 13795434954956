import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import setAsStartupProjectDb from "../../../static/AAA-images/setAsStartupProjectDb.png"
import successfullyCompletedDbMigrations from "../../../static/AAA-images/successfullyCompletedDbMigrations.png"
import bookStoreAPISwagger from "../../../static/AAA-images/bookStoreAPISwagger.png"
import LoginForm from "../../../static/AAA-images/LoginForm.png"
import SQLHelperClass from "../../../static/AAA-images/SQLHelperClass.png"
import leftSideMenu from "../../../static/AAA-images/leftSideMenu.png"
import newTenant from "../../../static/AAA-images/newTenant.png"
import newTenantInformation from "../../../static/AAA-images/newTenantInformation.png"
import newTenantConnectionString from "../../../static/AAA-images/newTenantConnectionString.png"
import elasticPoolServerPOC from "../../../static/AAA-images/elasticPoolServerPOC.png"
import deployingAbpImg from "../../../static/AAA-images/deployingAbpImg.png"
import webhooksImg from "../../../static/AAA-images/webhooksImg.png"
import integratingImg from "../../../static/AAA-images/integratingImg.png"
import { useState, useEffect } from "react";

import relatedblog12 from "../../../static/AAA-images/Using-Webhooks-in-ABP-Commercial.png"
import relatedblog13 from "../../../static/AAA-images/customLoginPageImg.png"
import relatedblog14 from "../../../static/AAA-images/Deploying-ABP-angular-application-to-Azure-and-App-Insights-integration.png"

const SamplePage = (props) => {
    const connString = `"ConnectionStrings": 
    {
        "Default": 
        "Server=localhost;Database=BookStore;Trusted_Connection=True"
    }  `;
    const dotnetRun  = `dotnet run`;
    const yarn  = `yarn`;
    const yarnStart = `yarn start`;
    const usingSystem  = `using System;
    using System.Collections.Generic;
    using System.Data;
    using System.Data.SqlClient;
    using System.Linq;
    using System.Text;
    using System.Threading.Tasks;
        
    namespace Acme.BookStore.Data
     {
        
      public class SQLHelperUtilityClass
        {
            public static void AddTenantWiseDbToElasticPool(string dbName)
             {
                SqlConnection conn = new SqlConnection("Data Source=localhost;" + "Initial Catalog=dbname;" + "User ID=userid;" + "Password=pass;" + "Connection Timeout=3600");
                try
                {
                    SqlCommand cmd = new SqlCommand("add_db_elastic_pool", conn);
                    cmd.CommandType = CommandType.Text;
                    cmd.CommandText = "CREATE DATABASE " + dbName + " ( SERVICE_OBJECTIVE = ELASTIC_POOL (name = elasticpoolname ));";
                    conn.Open();
                    cmd.ExecuteNonQuery();
                    conn.Close();
                }
                    catch (Exception ex)
                    {
                        if (conn.State == ConnectionState.Open)
                        {
                            conn.Close();
                        }
                    }
                }
        }`;
    const getDbName = `private async Task<string> GetDBName(string connectionString)
    {
        try
        {
            await Task.Yield();
            string[] stringSeparators = connectionString.Contains("Initial Catalog") == true ? new string[] { "Initial Catalog=", ";Persist Security " } : new string[] { "Database=", ";Persist Security " };
            string[] firstNames = connectionString.Split(stringSeparators, StringSplitOptions.TrimEntries);
            return firstNames[1];
        }
        catch (Exception ex)
        {
            _logger.LogException(ex);
            return "";
        }
    }
    `;
    const stringConnectionString = `string connectionString = tenantConfiguration.ConnectionStrings.FirstOrDefault().ToString();
    foreach (var migrator in _dbSchemaMigrators)
        {
            await migrator.MigrateAsync();
            string dbName = await GetDBName(connectionString);                                              
            SQLHelperUtilityClass.AddTenantWiseDbToElasticPool(dbName);  
        } `;
        const codeComplete = `
        
using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;
using Microsoft.Extensions.Logging;
using Volo.Abp.Data;
using Volo.Abp.DependencyInjection;
using Volo.Abp.EventBus.Distributed;
using Volo.Abp.Identity;
using Volo.Abp.MultiTenancy;
using Volo.Abp.Uow; 
namespace Acme.BookStore.Data; 
public class BookStoreTenantDatabaseMigrationHandler :
    IDistributedEventHandler<TenantCreatedEto>,
    IDistributedEventHandler<TenantConnectionStringUpdatedEto>,
    IDistributedEventHandler<ApplyDatabaseMigrationsEto>,
    ITransientDependency
{
    private readonly IEnumerable<IBookStoreDbSchemaMigrator> _dbSchemaMigrators;
    private readonly ICurrentTenant _currentTenant;
    private readonly IUnitOfWorkManager _unitOfWorkManager;
    private readonly IDataSeeder _dataSeeder;
    private readonly ITenantStore _tenantStore;
    private readonly ILogger<BookStoreTenantDatabaseMigrationHandler> _logger; 
    public BookStoreTenantDatabaseMigrationHandler(
        IEnumerable<IBookStoreDbSchemaMigrator> dbSchemaMigrators,
        ICurrentTenant currentTenant,
        IUnitOfWorkManager unitOfWorkManager,
        IDataSeeder dataSeeder,
        ITenantStore tenantStore,
        ILogger<BookStoreTenantDatabaseMigrationHandler> logger)
    {
        _dbSchemaMigrators = dbSchemaMigrators;
        _currentTenant = currentTenant;
        _unitOfWorkManager = unitOfWorkManager;
        _dataSeeder = dataSeeder;
        _tenantStore = tenantStore;
        _logger = logger;
    } 
        public async Task HandleEventAsync(TenantCreatedEto eventData)
    {
        await MigrateAndSeedForTenantAsync(
        eventData.Id,
        eventData.Properties.GetOrDefault("AdminEmail") ?? BookStoreConsts.AdminEmailDefaultValue,
        eventData.Properties.GetOrDefault("AdminPassword") ?? BookStoreConsts.AdminPasswordDefaultValue
        );
    } 
        public async Task HandleEventAsync(TenantConnectionStringUpdatedEto eventData)
    {
        if (eventData.ConnectionStringName != ConnectionStrings.DefaultConnectionStringName ||
        eventData.NewValue.IsNullOrWhiteSpace())
    {
        return;
    } 
        await MigrateAndSeedForTenantAsync(
        eventData.Id,
        BookStoreConsts.AdminEmailDefaultValue,
        BookStoreConsts.AdminPasswordDefaultValue
    ); 
        /* You may want to move your data from the old database to the new database!
        * It is up to you. If you don't make it, new database will be empty
        * (and tenant's admin password is reset to 1q2w3E*).
        */
    } 
        public async Task HandleEventAsync(ApplyDatabaseMigrationsEto eventData)
    {
        if (eventData.TenantId == null)
    {
        return;
    } 
        await MigrateAndSeedForTenantAsync(
        eventData.TenantId.Value,
        BookStoreConsts.AdminEmailDefaultValue,
        BookStoreConsts.AdminPasswordDefaultValue
        );
    } 
        private async Task MigrateAndSeedForTenantAsync(
        Guid tenantId,
        string adminEmail,
        string adminPassword)
    {
        try
    {
        using (_currentTenant.Change(tenantId))
    {
        // Create database tables if needed
        using (var uow = _unitOfWorkManager.Begin(requiresNew: true, isTransactional: false))
    {
        var tenantConfiguration = await _tenantStore.FindAsync(tenantId);
        if (tenantConfiguration?.ConnectionStrings != null &&
        !tenantConfiguration.ConnectionStrings.Default.IsNullOrWhiteSpace())
    {
        //string connString = "Server=WAILAP173\\SQLEXPRESS;Initial Catalog=BookStoreProjectDem0;Persist Security Info=False;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=3600;";
        string connectionString = tenantConfiguration.ConnectionStrings.FirstOrDefault().ToString();
        foreach (var migrator in _dbSchemaMigrators)
    {
        await migrator.MigrateAsync();
        string dbName = await GetDBName(connectionString);
        SQLHelperUtilityClass.AddTenantWiseDbToElasticPool(dbName);
    }
    }
        await uow.CompleteAsync();
    } 
        // Seed data
        using (var uow = _unitOfWorkManager.Begin(requiresNew: true, isTransactional: true))
    {
        await _dataSeeder.SeedAsync(
        new DataSeedContext(tenantId)
        .WithProperty(IdentityDataSeedContributor.AdminEmailPropertyName, adminEmail)
        .WithProperty(IdentityDataSeedContributor.AdminPasswordPropertyName, adminPassword)
        ); 
        await uow.CompleteAsync();
    }
    }
    }
        catch (Exception ex)
    {
        _logger.LogException(ex);
    }
    }   
        private async Task<string> GetDBName(string connectionString)
    {
        try
    {
        await Task.Yield();
        string[] stringSeparators = connectionString.Contains("Initial Catalog") == true ? new string[]
    { 
        "Initial Catalog=", ";Persist Security " } : new string[] { "Database=", ";Persist Security " };
        string[] firstNames = connectionString.Split(stringSeparators, StringSplitOptions.TrimEntries);
        return firstNames[1];
    }
        catch (Exception ex)
    {
        _logger.LogException(ex);
        return "";
    }
    }
}
`;
   
  


  return (

    <Layout >
    <div className="">
        <Container>
            <div className="">
                <section className='mb-5'>
                    <div className="container mt-5">
                        <div className="row ">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                <div className="row d-flex justify-content-between py-2">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                        <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                            <p className="mb-0 small text-muted">Jul 26, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                            <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                                <div className="rate p-0">
                                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                                </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                      
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div className="">

                                            <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                <Link to="https://www.linkedin.com/company/hire-tech-team/">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </Link>
                                                <Link to="https://twitter.com/HIRETECHTEAM">
                                                 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                    </svg></Link>
                                                <Link to="https://vimeo.com">
                                                  
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                    </svg></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between">

                                {/* Card details */}

                                <h4 className="text-dark fw-bolder mt-5 mb-3">Create database per tenant in SQL Elastic Pool with ABP Commercial</h4>
                                 <p className="details">In this short article let me show you the steps to create tenant wise databases and add them to the elastic pool server programmatically.</p>
                                 <h5 className="fw-bolder mb-2 pt-5">Scenario</h5>
                                 <p className="details mb-5">ABP Commercial multitenant application with database per tenant.</p>
                                 <h5 className="fw-bolder mb-2">What is SQL Elastic Pool Server?</h5>
                                 <p className="details mb-2">Azure SQL Database elastic pools are a simple, cost-effective solution for managing and scaling multiple databases that have varying and unpredictable usage demands.</p>
                                 <p className="details mb-2">In certain multi-tenant application scenarios where the customer has multiple databases having highly varying workloads and it needs a great performance in an irregular manner, Elastic pools provide an effective solution</p>
                                 <p className="details mb-2">The databases in an elastic pool reside on a single server and share a set number of resources at a set price. Elastic pools in SQL Database enable SaaS applications to optimize the price and performance for a group of databases and restrict to a fixed budget. This is perfect solution for a multi-tenant SaaS application.</p>
                                 <p className="details mb-5">The host can control Azure costs based on usage.</p>
                                 <h5 className="fw-bolder mb-2">Let’s get Started</h5>
                                 <p className="details mb-2 ">This tutorial assumes that you've already purchased an ABP Commercial license and have an active ABP Commercial account. </p>
                                 <p className="details  mb-2 " >So go to the link for creating the new solution.</p>

                                   
                                   
                                
                                <p className="details mb-2"><Link to="https://docs.abp.io/en/commercial/latest/getting-started-create-solution?UI=NG&DB=EF&Tiered=Yes" className="text-decoration-none link-primary">https://docs.abp.io/en/commercial/latest/getting-started-create-solution?UI=NG&DB=EF&Tiered=Yes</Link></p>
                                <p className="details mb-5 pb-3">As we covered all those steps for creating new solution, we start by opening the project and checking the connection string in the appsettings.json file under the  .HttpApi.Host project.</p>
                                <CodeSnippet codeString={connString} />
                                <h5 className="fw-bolder mb-3 pt-5">Database Migrations</h5>
                                <p className="details mb-2">The solution uses the Entity Framework Core Code First Migration approach. It comes with a  .DbMigrator console application which <strong> applies the database migrations</strong> and also <strong>seeds the initial data.</strong> It is useful on the <strong>development</strong> as well as on <strong>production</strong> environment.</p>
                                <p className="details mb-5">.DbMigrator project has its own appsettings.json. So, if you have changed the connection string above, you should also change this one.</p>
                                <h5 className="fw-bolder mb-2">The Initial Migration</h5>
                                <p className="details mb-2">.DbMigrator application automatically<strong>creates the Initial migration on first</strong> run.</p>
                                <p className="details mb-5">Now, open a command line terminal in the folder of the .DbMigrator project and run the following command:</p>
                                <CodeSnippet codeString={dotnetRun} />
                                <h5 className="fw-bolder mb-2 pt-5">Running the DbMigrator</h5>
                                <p className="details mb-5"><strong>Right click to the .DbMigrator project and select</strong> Set as StartUp Project</p>
                                <img className="py-3" src={setAsStartupProjectDb} alt="set As Startup Project" />
                                <p className="details py-4"><strong>Hit F5 (or Ctrl+F5) to run the application. It will have an output like shown below:</strong></p>
                                <img className="pb-5" src={successfullyCompletedDbMigrations} alt="Successfully Completed Db Migrations" />
                                <p className="details mb-4 pb-3">Initial seed data creates the admin user in the database (with the password is 1q2w3E*) which is then used to login to the application. So, you need to use .DbMigrator at least once for a new database.</p>
                                <h5 className="fw-bolder mb-2">Running the Application </h5>
                                <p className="details mb-2"><span className="fw-bolder">Running the HTTP API Host (Server Side)</span></p>
                                <p className="details mb-2">Ensure that the. HttpApi.Host project is the start-up project and run the application which will open a Swagger UI:</p>
                                <p className="details mb-4">Use Ctrl+F5 in Visual Studio (instead of F5) to run the application without debugging. If you don't have a debug purpose, this will be faster.</p>
                                <img className="pt-3 pb-5" src={bookStoreAPISwagger} alt="Book Store API In Swagger" />
                                <h5 className="fw-bolder mb-2">Running the Angular Application</h5>
                                <p className="details mb-5">Go to the angular folder, open a command line terminal, type the yarn command (we suggest to the yarn package manager while npm install will also work)</p>
                                <CodeSnippet codeString={yarn} />
                                <p className="details mb-2 pt-4">Bash</p>
                                <p className="details mb-2">Copy</p>
                                <p className="details mb-5">Once all node modules are loaded, execute yarn start (or npm start) command:</p>
                                <CodeSnippet codeString={yarnStart} />
                                <p className="details mb-2 pt-4">Bash</p>
                                <p className="details mb-2">copy</p>
                                <p className="details mb-5">It may take a longer time for the first build. Once it finishes, it opens the Angular UI in your default browser with the localhost:4200 address.</p>
                                <img className="pt-3 pb-5" src={LoginForm} alt="Login Form" />
                                <p className="details mb-5">Enter <strong>admin</strong> as the username and <strong>1q2w3E*</strong> as the password to login to the application. The application is up and running. You can start developing your application based on this start-up template.</p>
                                <h4 className="text-dark fw-bolder mb-4 pb-3">Steps for Adding Tenant Databases into Elastic Pool Server</h4>
                                <h5 className="fw-bolder mb-2">Changes on the Backend</h5>
                                <p className="details mb-4">1.Create A new class SQLHelperUtilityClass.cs  into the Data folder  .Domain project</p>
                                <img className="py-3" src={SQLHelperClass} alt="SQL Helper Class" />
                                <p className="details py-4">SQLHelperUtilityClass.cs</p>
                                <CodeSnippet codeString={usingSystem} />
                                <p className="details py-4">2.Create a private function in the file TenantDatabaseMigrationHandler.cs file</p>
                                <CodeSnippet codeString={getDbName} />
                                <p className="details py-4">Call the above function in the same file migrator foreach loop</p>
                                <CodeSnippet codeString={stringConnectionString} />
                                <p className="details py-4">The complete code for the class now looks like this</p>
                                <CodeSnippet codeString={codeComplete} />
                                <h5 className="fw-bolder mb-2 pt-5">Changes on the Frontend</h5>
                                <p className="details mb-4">1. After login as a host admin, click on the Saas option then Tenants of the left-side menu.</p>
                                <img className="py-3 w-50" src={leftSideMenu} alt="Left Side Menu" />
                                <p className="details py-4">2. Click on the + New tenant button which is present on the right-side corner</p>
                                <img className="py-3" src={newTenant} alt="Click on the + New tenant button which is present on the right-side corner" />
                                <p className="details py-4">3. After clicking fill all tenant information include the connection strings.</p>
                                <img className="py-3" src={newTenantInformation} alt="After clicking fill all tenant information include the connection strings." />
                                <p className="details pt-4">
                                    Make sure that the default connection string format is in the form of :<br />
                                    (Server=elasticpoolserverpoc;Initial Catalog=Tenant11;Persist Security Info=False;).</p>
                                <p className="details pb-4">In this case elasticpoolserverpoc is created and managed by the host in their Azure portal. Please refer to this link for creating the SQL elastic pool <Link to="https://learn.microsoft.com/en-us/azure/azure-sql/database/elastic-pool-overview?view=azuresql#create-a-new-sql-database-elastic-pool-by-using-the-azure-portal" className="text-decoration-none link-primary"> https://docs.microsoft.com/en-us/azure/azure-sql/database/elastic-pool-overview?view=azuresql#create-a-new-sql-database-elastic-pool-by-using-the-azure-portal</Link></p>
                                <img className="py-3" src={newTenantConnectionString} alt="New Tenant Connection String" />
                                <p className="details py-4">Once the tenant is created, the tenant database is automatically created in the Azure SQL elastic pool that is maintained by the host. If you check out your Azure portal, you will find these databases added under the SQL elastic pool as shown in the figure below.</p>
                                <img className="py-3" src={elasticPoolServerPOC} alt="Elastic Pool Server POC Database" />
                                <p className="details py-4">Please do let us know any specific scenario or user story that you would like us to demonstrate. HireTechTeam developers have implemented huge number of varied solutions for our customers with ABP commercial. Write to us at : <Link to="mailto:info@waiin.com" className="text-decoration-none link-primary"> info@waiin.com</Link></p>
                              
                                </div>
                               

                                {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                                <div className="py-3 mt-3">
                                    <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                    <div className="d-flex align-items-center">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mx-5">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row aarw">
                                    <div className="col-12">
                                        <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                    </div>
                                </div>
                                <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
                                                        <img className="card-img-top" src={relatedblog12} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/07/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Using Webhooks in ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Webhooks allow interaction between web-based applications with custom callbacks...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/createCustomLoginPageAbp">
                                                        <img className="card-img-top" src={relatedblog13} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">08/07/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Create a custom Login page in ABP Commercial Angular App</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article and video, we will demonstrate how to work with the default login page of ABP...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/footer">
                                                        <img className="card-img-top" src={relatedblog14} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">02/09/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Deploying ABP angular application to Azure and App Insights integration</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Learn how to implement SSO between an ASP.Net core MVC application and ABP Commercial...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                <div className="row d-flex justify-content-between py-3 align-items-center">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span>Kirti Kulkarni</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="align-items-end d-flex justify-content-end">
                                            <div className="footer-social d-flex gap-0">
                                                <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                    {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                <div>
                                    <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                    <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Deploying ABP angular application to Azure and App Insights integration</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Using Webhooks in ABP Commercial</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={webhooksImg} className="img-fluid" alt="Using Webhooks in ABP Commercial" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="nav-link" to="/blog/integratingAndEnablingChat">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Integrating and enabling the Chat module in ABP Commercial</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={integratingImg} className="img-fluid" alt="Integrating and enabling the Chat module in ABP Commercial" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </Container>

    </div>



</Layout>
//     <Layout>
//       <div className="">
//         <Container>
//         <section className='mb-5'>
//                 <div className="container mt-5">
//                     <div className="row ">
//                         <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
//                             <div className="row d-flex justify-content-between py-2">
//                                 <div className="col-md-6">
//                                     <div className="d-flex align-items-center">
//                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                 <div>
//                                                     <span className="fs-3">Kirti Kulkarni</span>
//                                                     <p className="mb-0 text-muted">Jul 26, 2022 <span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
//                                                     </p>
//                                                 </div>
//                                             </span>
//                                         </div>
//                                         <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
//                                             <div className="rate p-0 d-flex align-items-end mt-4">
//                                                 <span className="font-normal">5</span>
//                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                 <label htmlFor="star1" title="text">1 star</label>
//                                             </div>
//                                             <span className="font-normal">12</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="align-items-end d-flex justify-content-end">
//                                         <div className="footer-social d-flex gap-0">
//                                         <Link to="https://www.linkedin.com/company/hire-tech-team/">
//                                             {/* <a href="https://www.linkedin.com/company/hire-tech-team/"> */}
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
//                                                 </svg>
//                                           </Link>
//                                           <Link to="https://www.linkedin.com/company/hire-tech-team/">
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                 <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
//                                             </svg></Link>
//                                             <Link to="https://vimeo.com">
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                 <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
//                                             </svg></Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>


//                             <div className="row d-flex justify-content-between">
//                                 <h4 className="text-dark fw-bolder mt-4">Create database per tenant in SQL Elastic Pool with ABP Commercial</h4>
//                                 <p className="details">In this short article let me show you the steps to create tenant wise databases and add them to the elastic pool server programmatically.</p>
//                                 <h5 className="fw-bolder">Scenario</h5>
//                                 <p className="details">ABP Commercial multitenant application with database per tenant.</p>
//                                 <h5 className="fw-bolder">What is SQL Elastic Pool Server?</h5>
//                                 <p className="details">Azure SQL Database elastic pools are a simple, cost-effective solution for managing and scaling multiple databases that have varying and unpredictable usage demands.</p>
//                                 <p className="details">In certain multi-tenant application scenarios where the customer has multiple databases having highly varying workloads and it needs a great performance in an irregular manner, Elastic pools provide an effective solution</p>
//                                 <p className="details">The databases in an elastic pool reside on a single server and share a set number of resources at a set price. Elastic pools in SQL Database enable SaaS applications to optimize the price and performance for a group of databases and restrict to a fixed budget. This is perfect solution for a multi-tenant SaaS application.</p>
//                                 <p className="details">The host can control Azure costs based on usage.</p>
//                                 <h5 className="fw-bolder">Let’s get Started</h5>
//                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} >This tutorial assumes that you've already purchased an ABP Commercial license and have an active ABP Commercial account. </p>
//                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} >So go to the link for creating the new solution.</p>

// {!isLoggedIn &&
//     <div className="row bg-light mb-5">
//         <div className="col-lg-4">
//             <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
//         </div>
//         <div className="col-lg-8">
//             <h6 className="mt-3">To read the full post,</h6>
//             <h4 className="fw-bolder">Sign up to AAA Academy</h4>
//             <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
//             <Link to={`/signUp`}>
//                 <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
//             </Link>
//             <p className="mt-2">Already have an account?
//                 <Link to="/login" className="text-decoration-none color"> Sign in
//                 </Link> </p>
//         </div>
//     </div>}
// {isLoggedIn && <>
                                
//                                 <p className="details"><Link to="https://docs.abp.io/en/commercial/latest/getting-started-create-solution?UI=NG&DB=EF&Tiered=Yes" className="text-decoration-none">https://docs.abp.io/en/commercial/latest/getting-started-create-solution?UI=NG&DB=EF&Tiered=Yes</Link></p>
//                                 <p className="details">As we covered all those steps for creating new solution, we start by opening the project and checking the connection string in the appsettings.json file under the  .HttpApi.Host project.</p>
//                                 <CodeSnippet codeString={connString} />
//                                 <h5 className="fw-bolder">Database Migrations</h5>
//                                 <p className="details">The solution uses the Entity Framework Core Code First Migration approach. It comes with a  .DbMigrator console application which <strong> applies the database migrations</strong> and also <strong>seeds the initial data.</strong> It is useful on the <strong>development</strong> as well as on <strong>production</strong> environment.</p>
//                                 <p className="details">.DbMigrator project has its own appsettings.json. So, if you have changed the connection string above, you should also change this one.</p>
//                                 <h5 className="fw-bolder">The Initial Migration</h5>
//                                 <p className="details">.DbMigrator application automatically<strong>creates the Initial migration on first</strong> run.</p>
//                                 <p className="details">Now, open a command line terminal in the folder of the .DbMigrator project and run the following command:</p>
//                                 <CodeSnippet codeString={dotnetRun} />
//                                 <h5 className="fw-bolder">Running the DbMigrator</h5>
//                                 <p className="details"><strong>Right click to the .DbMigrator project and select</strong> Set as StartUp Project</p>
//                                 <img className="py-3" src={setAsStartupProjectDb} alt="set As Startup Project" />
//                                 <p className="details"><strong>Hit F5 (or Ctrl+F5) to run the application. It will have an output like shown below:</strong></p>
//                                 <img className="py-3" src={successfullyCompletedDbMigrations} alt="Successfully Completed Db Migrations" />
//                                 <p className="details">Initial seed data creates the admin user in the database (with the password is 1q2w3E*) which is then used to login to the application. So, you need to use .DbMigrator at least once for a new database.</p>
//                                 <h5 className="fw-bolder">Running the Application </h5>
//                                 <p className="details"><span className="fw-bolder">Running the HTTP API Host (Server Side)</span></p>
//                                 <p className="details">Ensure that the. HttpApi.Host project is the start-up project and run the application which will open a Swagger UI:</p>
//                                 <p className="details">Use Ctrl+F5 in Visual Studio (instead of F5) to run the application without debugging. If you don't have a debug purpose, this will be faster.</p>
//                                 <img className="py-3" src={bookStoreAPISwagger} alt="Book Store API In Swagger" />
//                                 <h5 className="fw-bolder">Running the Angular Application</h5>
//                                 <p className="details">Go to the angular folder, open a command line terminal, type the yarn command (we suggest to the yarn package manager while npm install will also work)</p>
//                                 <CodeSnippet codeString={yarn} />
//                                 <p className="details">Bash</p>
//                                 <p className="details">Copy</p>
//                                 <p className="details">Once all node modules are loaded, execute yarn start (or npm start) command:</p>
//                                 <CodeSnippet codeString={yarnStart} />
//                                 <p className="details">Bash</p>
//                                 <p className="details">copy</p>
//                                 <p className="details">It may take a longer time for the first build. Once it finishes, it opens the Angular UI in your default browser with the localhost:4200 address.</p>
//                                 <img className="py-3" src={LoginForm} alt="Login Form" />
//                                 <p className="details">Enter <strong>admin</strong> as the username and <strong>1q2w3E*</strong> as the password to login to the application. The application is up and running. You can start developing your application based on this start-up template.</p>
//                                 <h4 className="text-dark fw-bolder ">Steps for Adding Tenant Databases into Elastic Pool Server</h4>
//                                 <h5 className="fw-bolder">Changes on the Backend</h5>
//                                 <p className="details">1.Create A new class SQLHelperUtilityClass.cs  into the Data folder  .Domain project</p>
//                                 <img className="py-3" src={SQLHelperClass} alt="SQL Helper Class" />
//                                 <p className="details">SQLHelperUtilityClass.cs</p>
//                                 <CodeSnippet codeString={usingSystem} />
//                                 <p className="details">2.Create a private function in the file TenantDatabaseMigrationHandler.cs file</p>
//                                 <CodeSnippet codeString={getDbName} />
//                                 <p className="details">Call the above function in the same file migrator foreach loop</p>
//                                 <CodeSnippet codeString={stringConnectionString} />
//                                 <p className="details">The complete code for the class now looks like this</p>
//                                 <CodeSnippet codeString={codeComplete} />
//                                 <h5 className="fw-bolder">Changes on the Frontend</h5>
//                                 <p className="details">1. After login as a host admin, click on the Saas option then Tenants of the left-side menu.</p>
//                                 <img className="py-3 w-50" src={leftSideMenu} alt="Left Side Menu" />
//                                 <p className="details">2. Click on the + New tenant button which is present on the right-side corner</p>
//                                 <img className="py-3" src={newTenant} alt="Click on the + New tenant button which is present on the right-side corner" />
//                                 <p className="details">3. After clicking fill all tenant information include the connection strings.</p>
//                                 <img className="py-3" src={newTenantInformation} alt="After clicking fill all tenant information include the connection strings." />
//                                 <p className="details">
//                                     Make sure that the default connection string format is in the form of :<br />
//                                     (Server=elasticpoolserverpoc;Initial Catalog=Tenant11;Persist Security Info=False;).</p>
//                                 <p className="details">In this case elasticpoolserverpoc is created and managed by the host in their Azure portal. Please refer to this link for creating the SQL elastic pool <Link to="https://learn.microsoft.com/en-us/azure/azure-sql/database/elastic-pool-overview?view=azuresql#create-a-new-sql-database-elastic-pool-by-using-the-azure-portal" className="text-decoration-none"> https://docs.microsoft.com/en-us/azure/azure-sql/database/elastic-pool-overview?view=azuresql#create-a-new-sql-database-elastic-pool-by-using-the-azure-portal</Link></p>
//                                 <img className="py-3" src={newTenantConnectionString} alt="New Tenant Connection String" />
//                                 <p className="details">Once the tenant is created, the tenant database is automatically created in the Azure SQL elastic pool that is maintained by the host. If you check out your Azure portal, you will find these databases added under the SQL elastic pool as shown in the figure below.</p>
//                                 <img className="py-3" src={elasticPoolServerPOC} alt="Elastic Pool Server POC Database" />
//                                 <p className="details">Please do let us know any specific scenario or user story that you would like us to demonstrate. HireTechTeam developers have implemented huge number of varied solutions for our customers with ABP commercial. Write to us at : <Link to="mailto:info@waiin.com" className="text-decoration-none">info@waiin.com</Link></p></>}
//                             </div>
//                             <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
//                             <div className="py-3">
//                                 <h4 className="text-dark fw-bolder">Featured Comments</h4>
//                                 <div className="d-flex align-items-center">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>

//                                 <div className="d-flex align-items-center">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>

//                                 <div className="d-flex align-items-center mx-5">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>
//                             </div>
//                             <div className="row aarw">
//                                 <div className="col-12">
//                                     <h5 className="related-title">More Articles from Joe Thomson</h5>
//                                 </div>
//                                 <div className="col-12">
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>

//                                     </div>
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
//                             <div className="row d-flex justify-content-between py-3">
//                                 <div className="col-md-6">
//                                     <div className="d-flex align-items-center">
//                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                 <div>
//                                                     <span>Kirti Kulkarni</span>
//                                                 </div>
//                                             </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="align-items-end d-flex justify-content-end">

//                                         <div className="footer-social d-flex gap-0">
// <Link to="https://www.linkedin.com/company/hire-tech-team/">


//                                             {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
//                                                 </svg>
//                                                 </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <h6 className="about-author">ABOUT THE AUTHOR</h6>
//                             <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
//                             <div>
//                                 <h5 className="fw-medium mb-3">Latest Articles</h5>
//                                 <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
//                                 <div className="card mb-3 px-3 py-2">
//                                     <div className="row g-0">
//                                         <div className="col-md-8">
//                                             <h5 className="card-title">
//                                                 <div className="d-flex align-items-center">
//                                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                         <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                             <div>
//                                                                 <span className="fs-6">Kirti Kulkarni</span>
//                                                             </div>
//                                                         </span>
//                                                     </div>
//                                                 </div>
//                                             </h5>
//                                             <p className="card-text details">Deploying ABP angular application to Azure and App Insights integration</p>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className="card-body">
//                                                 <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 </Link>
//                                 <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
//                                 <div className="card mb-3 px-3 py-2">
//                                     <div className="row g-0">
//                                         <div className="col-md-8">
//                                             <h5 className="card-title">
//                                                 <div className="d-flex align-items-center">
//                                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                         <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                             <div>
//                                                                 <span className="fs-6">Kirti Kulkarni</span>
//                                                             </div>
//                                                         </span>
//                                                     </div>
//                                                 </div>
//                                             </h5>
//                                             <p className="card-text details">Using Webhooks in ABP Commercial</p>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className="card-body">
//                                                 <img src={webhooksImg} className="img-fluid" alt="Using Webhooks in ABP Commercial" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 </Link>
//                                 <Link className="nav-link" to="/blog/integratingAndEnablingChat">
//                                 <div className="card mb-3 px-3 py-2">
//                                     <div className="row g-0">
//                                         <div className="col-md-8">
//                                             <h5 className="card-title">
//                                                 <div className="d-flex align-items-center">
//                                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                         <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                             <div>
//                                                                 <span className="fs-6">Kirti Kulkarni</span>
//                                                             </div>
//                                                         </span>
//                                                     </div>
//                                                 </div>
//                                             </h5>
//                                             <p className="card-text details">Integrating and enabling the Chat module in ABP Commercial</p>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className="card-body">
//                                                 <img src={integratingImg} className="img-fluid" alt="Integrating and enabling the Chat module in ABP Commercial" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 </Link>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </section>
//         </Container>
        
//       </div>
  
        
      
//     </Layout>
    
  );
};

export default SamplePage;
