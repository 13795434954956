import React from 'react';
import './Hero.css';
// import aaaLogo from '../../../static/AAA-images/AAA-icon.svg'
// import Button from '../Button';
// import { Link } from 'gatsby';

const Hero = (props) => {
  const {
    title,
  } = props;
  
  return (
    <>
    
    <section className="bannerOverlay">
            <div id="videoContainer">
                <iframe title="myFrame" id="explainer-video-content-frame" width="1440" height="570" src="https://player.vimeo.com/video/502176120?h=afc5155287?rel=0&controls=0&autoplay=1&loop=1&muted=1" allow="autoplay; fullscreen; loop" allowFullScreen> </iframe>
            </div>
            {/* <div className="overlay1"> */}
            <div className="overlay1">
                <div className="overlay__inner">
                    {/* <img src={aaaLogo} alt="AAA Academy" className="d-flex justify-content-center mx-auto" /> */}
                    <h5 className="text-white visibility-hidden pt-3 at pb-2">{title}</h5>
                    <div className="w-10 mx-auto visibility-hidden">
                        <hr className="aaa-hr" />
                    </div>
                    <h1 className="text-white aaa-banner-head pt-3 fade-in three1">ACCELERATE<span className="aaa-dot">.</span> AUTOMATE<span className="aaa-dot">.</span> AUGMENT<span className="aaa-dot">.</span></h1>
                </div>
            </div>
        </section>
    
    </>
  );
};

export default Hero;
