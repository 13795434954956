import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import localSwaggerApiApplicationListing from "../../../static/AAA-images/localSwaggerApiApplicationListing.png"
import angularFrontendApplication from "../../../static/AAA-images/angularFrontendApplication.png"
import createAWebApplication from "../../../static/AAA-images/createAWebApplication.png"
import multipleAzureServices from "../../../static/AAA-images/multipleAzureServices.png"
import createWebApp from "../../../static/AAA-images/createWebApp.png"
import clickOnWebApp from "../../../static/AAA-images/clickOnWebApp.png"
import nowYouWillSeeTheCreateWebAppWindow from "../../../static/AAA-images/nowYouWillSeeTheCreateWebAppWindow.png"
import appServicePlan from "../../../static/AAA-images/appServicePlan.png"
import webAppByMicrosoft from "../../../static/AAA-images/webAppByMicrosoft.png"
import deploymentIsInProgress from "../../../static/AAA-images/deploymentIsInProgress.png"
import yourDeploymentIsComplete from "../../../static/AAA-images/yourDeploymentIsComplete.png"
import appService from "../../../static/AAA-images/appService.png"
import getPublishProfile from "../../../static/AAA-images/getPublishProfile.png"
import azureAppServiceUrl from "../../../static/AAA-images/azureAppServiceUrl.png"
import clickToCreteWebApp from "../../../static/AAA-images/clickToCreteWebApp.png"
import multipleAzureServicesWillSee from "../../../static/AAA-images/multipleAzureServicesWillSee.png"
import webAppService from "../../../static/AAA-images/webAppService.png"
import createWebAppWithInstanceDetails from "../../../static/AAA-images/createWebAppWithInstanceDetails.png"
import createWebAppByMicrosoft from "../../../static/AAA-images/createWebAppByMicrosoft.png"
import deplymentIsInProgressImage from "../../../static/AAA-images/deplymentIsInProgressImage.png"
import deploymentCompleted from "../../../static/AAA-images/deploymentCompleted.png"
import azureAppInsightsPocUi from "../../../static/AAA-images/azureAppInsightsPocUi.png"
import webAppServiceWillLook from "../../../static/AAA-images/webAppServiceWillLook.png"
import createAzureDatabase from "../../../static/AAA-images/createAzureDatabase.png"
import searchForSql from "../../../static/AAA-images/searchForSql.png"
import azureSQL from "../../../static/AAA-images/azureSQL.png"
import SQLDatabases from "../../../static/AAA-images/sqlDatabases.png"
import sqlDatabaseCreate from "../../../static/AAA-images/sqlDatabaseCreate.png"
import createSqlDatabasesNew from "../../../static/AAA-images/createSqlDatabasesNew.png"
import configureSQL from "../../../static/AAA-images/configureSQL.png"
import selectedAllOptions from "../../../static/AAA-images/selectedAllOptions.png"
import deploymentDetails from "../../../static/AAA-images/deploymentDetails.png"
import sqlServerIsReady from "../../../static/AAA-images/sqlServerIsReady.png"
import clickOnConnectionString from "../../../static/AAA-images/clickOnConnectionString.png"
import adoNET from "../../../static/AAA-images/adoNET.png"
import azureApp from "../../../static/AAA-images/azureApp.png"
import appSettings from "../../../static/AAA-images/appSettings.png"
import connectionString from "../../../static/AAA-images/connectionString.png"
import setAsStartup from "../../../static/AAA-images/setAsStartup.png"
import greenArrowDBMigrate from "../../../static/AAA-images/greenArrowDBMigrate.png"
import migration from "../../../static/AAA-images/migration.png"
import solutionHTTPApi from "../../../static/AAA-images/solutionHTTPApi.png"
import tabs from "../../../static/AAA-images/tabs.png"
import clickOnPublish from "../../../static/AAA-images/clickOnPublish.png"
import clickOnNew from "../../../static/AAA-images/clickOnNew.png"
import publishImportProfile from "../../../static/AAA-images/publishImportProfile.png"
import publishingProfile from "../../../static/AAA-images/publishingProfile.png"
import publishImportSettingsFile from "../../../static/AAA-images/publishImportSettingsFile.png"
import publishOption from "../../../static/AAA-images/publishOption.png"
import swaggerPocApi from "../../../static/AAA-images/swaggerPocApi.png"
import environments from "../../../static/AAA-images/environments.png"
import environmentprodts from "../../../static/AAA-images/environment-prod-ts.png"
import environmentCode from "../../../static/AAA-images/environmentCode.png"
import newTerminal from "../../../static/AAA-images/newTerminal.png"
import ngBuild from "../../../static/AAA-images/ngBuild.png"
import fileAzureAppInsightsPOC from "../../../static/AAA-images/fileAzureAppInsightsPOC.png"
import POCUI from "../../../static/AAA-images/POCUI.png"
import selectNotepadOption from "../../../static/AAA-images/selectNotepadOption.png"
import rawCode from "../../../static/AAA-images/rawCode.jpg"
import rawCodeTwo from "../../../static/AAA-images/rawCodeTwo.jpg"
import rawCodeThree from "../../../static/AAA-images/rawCodeThree.jpg"
import winSCR from "../../../static/AAA-images/winSCR.png"
import connectingToWaws from "../../../static/AAA-images/connectingToWaws.jpg"
import connectToHttp from "../../../static/AAA-images/connectToHttp.png"
import downloadsUI from "../../../static/AAA-images/downloadsUI.png"
import selectADestination from "../../../static/AAA-images/selectADestination.png"
import wwwRoot from "../../../static/AAA-images/wwwRoot.png"
import selectFilesToUpload from "../../../static/AAA-images/selectFilesToUpload.png"
import uploadFilesToRemoteDirectory from "../../../static/AAA-images/uploadFilesToRemoteDirectory.png"
import uploadScreen from "../../../static/AAA-images/uploadScreen.png"
import copyURl from "../../../static/AAA-images/copyURl.png"
import AbpHomePage from "../../../static/AAA-images/AbpHomePage.png"
import POCWebApp from "../../../static/AAA-images/POCWebApp.png"
import applicationInsights from "../../../static/AAA-images/applicationInsights.png"
import appInTwo from "../../../static/AAA-images/appInTwo.png"
import appInThree from "../../../static/AAA-images/appInThree.png"
import applyMonitoringSettings from "../../../static/AAA-images/applyMonitoringSettings.png"
import pocWebAppApplication from "../../../static/AAA-images/pocWebAppApplication.png"
import pocDashboard from "../../../static/AAA-images/pocDashboard.png"
import dashboardPOC from "../../../static/AAA-images/dashboardPOC.png"
import createDatabaseImg from "../../../static/AAA-images/createDatabaseImg.png"
import webhooksImg from "../../../static/AAA-images/webhooksImg.png"
import integratingImg from "../../../static/AAA-images/integratingImg.png"
import { useState, useEffect } from "react";

import relatedblog11 from "../../../static/AAA-images/File-Management-Module.png"
import relatedblog12 from "../../../static/AAA-images/work-with-pdf.jpg"
import relatedblog13 from "../../../static/AAA-images/customLoginPageImg.png"

const SamplePage = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const loggedValue = localStorage.getItem('isLoggedIn');
        loggedValue === 'true' ? setIsLoggedIn(true) : setIsLoggedIn(false);
        console.log('isLoggedIn', isLoggedIn);
    }, []);

    function onLogout() {
        localStorage.setItem('isLoggedIn', 'false');
        setIsLoggedIn(false);
    }



    return (
        <Layout >
            <div className="">
                <Container>
                    <div className="">
                        <section className='mb-5'>
                            <div className="container mt-5">
                                <div className="row ">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                        <div className="row d-flex justify-content-between py-2">
                                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                                <div className="d-flex align-items-center">
                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                            <div>
                                                                <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                                <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                                    <p className="mb-0 small text-muted"> Sep 02, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                                    <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                            <div className="rate p-0">
                                                                                <input type="radio" id="star5" name="rate" value="5" />
                                                                                <label htmlFor="star5" title="text">5 stars</label>
                                                                                <input type="radio" id="star4" name="rate" value="4" />
                                                                                <label htmlFor="star4" title="text">4 stars</label>
                                                                                <input type="radio" id="star3" name="rate" value="3" />
                                                                                <label htmlFor="star3" title="text">3 stars</label>
                                                                                <input type="radio" id="star2" name="rate" value="2" />
                                                                                <label htmlFor="star2" title="text">2 stars</label>
                                                                                <input type="radio" id="star1" name="rate" value="1" />
                                                                                <label htmlFor="star1" title="text">1 star</label>
                                                                            </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="">

                                                    <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                        <Link to="https://www.linkedin.com/company/hire-tech-team/">

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                            </svg>
                                                        </Link>
                                                        <Link to="https://twitter.com/HIRETECHTEAM">

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                            </svg></Link>
                                                        <Link to="https://vimeo.com">

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                            </svg></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <h4 className="text-dark fw-bolder mt-4 mb-3">Deploying ABP angular application to Azure and App Insights integration</h4>
                                            <h5 className="fw-bolder  mb-3">Introduction</h5>
                                            <p className="details">In this article, I will share the steps for manually deploying an ABP Commercial Angular application and backend on Azure and how to integrate Azure App Insights with the application.</p>
                                            <p className="details">Azure App Insights is an application performance management service for web applications and helps to monitor your website performance and to get insight into tenants and users. It enables you to monitor and improve the performance of your application.It’s designed to ensure optimal application performance. It also has a powerful analytic tool that helps you diagnose issues and gain an understanding of how people are using your web application.</p>
                                            <p className="details">This article assumes that you have already created an ABP Commercial application based on the modular application template, with Angular as UI framework and Entity Framework Core as database provider.</p>
                                            <p className="details">1.In case you have not already done, please follow the instructions outlined here to run the ABP application locally</p>
                                            <p className="details"><Link to="https://docs.abp.io/en/commercial/latest/getting-started-running-solution?UI=NG&DB=EF&Tiered=No" className="text-decoration-none">https:docs.abp.io/en/commercial/latest/getting-started-running-solution?UI=NG&DB=EF&Tiered=No</Link></p>
                                            <p className="details">2.Here is the local Swagger API Application listing as part of the Backend application </p>
                                            <img className="py-3" src={localSwaggerApiApplicationListing} alt="Local Swagger Api Application Listing" />
                                            <p className="details">This is the Angular frontend application .</p>
                                            <img className="py-3" src={angularFrontendApplication} alt="This is the Angular frontend application" />
                                            <p className="details">Creating an Azure App Service for Backend </p>
                                            <p className="details" >Open your resource group of Azure Portal </p>

                                            

                                                <p className="details">Click on <strong>Create</strong> to create a Web Application</p>
                                                <img className="py-3" src={createAWebApplication} alt="Click on Create to create a Web Application" />
                                                <p className="details">a. Now you will see multiple azure services.</p>
                                                <img className="py-3" src={multipleAzureServices} alt="Multiple Azure Services" />
                                                <p className="details">b. From it, we need a Web App, click on Create</p>
                                                <img className="py-3 w-50" src={createWebApp} alt="Create Web App" />
                                                <p className="details">4. Click on Web App</p>
                                                <img className="py-3 w-50" src={clickOnWebApp} alt="clickOnWebApp" />
                                                <p className="details">5. Now you will see the Create web app window. Here you can see Your subscription and resource group name. Make sure you have selected the correct resource group. </p>
                                                <img className="py-3" src={nowYouWillSeeTheCreateWebAppWindow} alt="Now You Will See The Create Web App Window" />
                                                <p className="details">a. Now give the appropriate name to your database (it must be unique) and select the publishing format. Here we are publishing code so selecting the code option.</p>
                                                <p className="details">b. In the Runtime stack, select the technology/Language in which your product is created. Here we used .net6 (LTS).</p>
                                                <p className="details">c. Select the Operating system as per your requirement(We are selecting Linux here).</p>
                                                <p className="details">d. Same for the region, select as your requirement we are selecting central India here.</p>
                                                <p className="details">e. Now in SKU and size click on the change size option. Here we can see three category plans dev/test, Production, and Isolated.</p>
                                                <p className="details">*Dev/Test: If your application is under development or testing mode you should use this option.</p>
                                                <p className="details">*Production: For live applications which are already getting used by customers you should use this option.</p>
                                                <p className="details">*Isolated: In this case, it runs dedicated Azure VMs on dedicated Azure Virtual Networks. It provides network isolation on top of computing isolation to your apps. It provides the maximum scale-out capabilities.</p>
                                                <p className="details mt-5">Here we already changed the size of SKU, hence you are not able to see the change size option above, but you can</p>
                                                <p className="details">See the change size option as shown below.</p>
                                                <img className="py-3" src={appServicePlan} alt="App Service Plan" />
                                                <p className="details">f. Select Dev/Test option and click on Apply.</p>
                                                <p className="details">6. Click Deployment (Next) = Networking (Next) = Monitoring (Next) = Tags (Next). Keep the default setting as it is.</p>
                                                <p className="details">7. Now on the Review + Create page click on Create. It will start creating your web application</p>
                                                <img className="py-3" src={webAppByMicrosoft} alt="Web App By Microsoft" />
                                                <p className="details">You will see the following page showing your deployment is in progress, wait till it’s done.</p>
                                                <img className="py-3" src={deploymentIsInProgress} alt="Deployment Is In Progress" />
                                                <p className="details">And here we go …..web app service for your backend application is ready</p>
                                                <img className="py-3" src={yourDeploymentIsComplete} alt="Your Deployment Is Complete" />
                                                <p className="details">This is how your web app service will look.</p>
                                                <img className="py-3" src={appService} alt="This is how your web app service will look." />
                                                <p className="details">Now Click on ‘Get publish profile ‘.One file will be get downloaded here, we will use it later</p>
                                                <img className="py-3" src={getPublishProfile} alt="Get Publish Profile" />
                                                <p className="details">In your resource group, now you can see your app service running.</p>
                                                <p className="details">This is the URL of your azure web app service we will be using this URL while publishing your application in this web app service. Copy this URL somewhere in notepad as a backend web app we will use this later.</p>
                                                <img className="py-3" src={azureAppServiceUrl} alt="Azure App Service Url" />
                                                <p className="details">Now we need to create one more web app service for publishing your angular project too.</p>
                                                <h5 className="fw-bolder mt-3">Creating Frontend App Service </h5>
                                                <p className="details mt-3">1. Open your resource group of Azure Portal</p>
                                                <p className="details">2. Click on Create to create a Web Application</p>
                                                <img className="py-3" src={clickToCreteWebApp} alt="Click To Crete WebApp" />
                                                <p className="details">3. Now you will see multiple azure services.</p>
                                                <img className="py-3 w-50" src={multipleAzureServicesWillSee} alt="Multiple Azure Services Will See" />
                                                <p> <img className="py-3" src={webAppService} alt="Web App Service" /></p>
                                                <p className="details">4. Now you will see the Create web app window. Here you can see Your subscription and resource group name. Make sure you have selected the correct resource group</p>
                                                <p className="details">Fill in all the details carefully as we did it before.</p>
                                                <p className="details">While giving the name to this app service try to use UI at the end of your app service name. It will be easy for you to differentiate your app service as a back-end application and the front-end one. (Not compulsory step)</p>
                                                <p className="details">Just while selecting the Runtime Stack option make sure, you select the Node option here as we are going to publish your angular application inside this app service</p>
                                                <img className="py-3" src={createWebAppWithInstanceDetails} alt="createWebAppWithInstanceDetails" />
                                                <img className="py-3" src={createWebAppByMicrosoft} alt="Create Web App By Microsoft" />
                                                <img className="py-3" src={deplymentIsInProgressImage} alt="Deployment Is In Progress" />
                                                <img className="py-3" src={deploymentCompleted} alt="Deployment Completed" />
                                                <p className="details">Now App service for publishing Your Angular application is also ready.</p>
                                                <p className="details">Click on Get publish Profile one file will get downloaded we will be using this later.</p>
                                                <img className="py-3" src={azureAppInsightsPocUi} alt="azureAppInsightsPocUi" />
                                                <p className="details">This is how your web app service will look.</p>
                                                <img className="py-3" src={webAppServiceWillLook} alt="Web App Service Will Look" />
                                                <p className="details">This URL we will use later while publishing your backend application to this azure app service.</p>
                                                <p className="details">Now we need to create azure SQL for our azure web application. SQL servers on our local machines are not accessible here.</p>
                                                <p className="details">Now Go to Home and click on your resource group. You will get redirected to the resource group dashboard. Here click on Create.</p>
                                                <h5 className="fw-bolder my-4">Creating Azure Database </h5>
                                                <img className="py-3" src={createAzureDatabase} alt="Create Azure Database" />
                                                <p className="details">Search for SQL, you will see multiple SQL database options here.</p>
                                                <img className="py-3" src={searchForSql} alt="Search for SQL, you will see multiple SQL database options here." />
                                                <p className="details">Click on Create = Azure SQL</p>
                                                <img className="py-3 w-50" src={azureSQL} alt="Azure SQL" />
                                                <p className="details">Now we want SQL databases here click on create</p>
                                                <img className="py-3 w-50" src={SQLDatabases} alt="sql Databases" />
                                                <p className="details">Give the appropriate Server Name and select the location as per your need. Here we are using Central India as a location. Use the same location for the web application and its database to avoid latency.</p>
                                                <img className="py-3" src={sqlDatabaseCreate} alt="SQL Database Create" />
                                                <p className="details">While giving server name if you are creating it for the first time then give the name to your server as per your requirement.</p>
                                                <img className="py-3" src={createSqlDatabasesNew} alt="create Sql Databases New" />
                                                <p className="details text-danger">** Remember your password you will need it again while editing your database connection string.</p>
                                                <p className="details">When you click on configure database following window will get displayed.</p>
                                                <p className="details">We are selecting a Basic plan here you can choose as per your requirement. If you are creating this server for testing or learning purpose we recommend you to select the Basic one as this will be the cheapest.</p>
                                                <p className="details">Click on Apply.</p>
                                                <img className="py-3" src={configureSQL} alt="Configure SQL" />
                                                <p className="details">Make sure you have selected all options carefully</p>
                                                <img className="py-3" src={selectedAllOptions} alt="Selected All Options" />
                                                <img className="py-3" src={deploymentDetails} alt="deploymentDetails" />
                                                <p className="details">Here we go your SQL server with the database is ready.</p>
                                                <img className="py-3" src={sqlServerIsReady} alt="Here we go your SQL server with the database is ready." />
                                                <p className="details">Now click on connection strings,</p>
                                                <img className="py-3 w-50" src={clickOnConnectionString} alt="Now click on connection strings" />
                                                <p className="details">The following window will appear.</p>
                                                <p className="details">Here you need to enter your password which you entered earlier. Remove “your password” and enter the password instead.</p>
                                                <img className="py-3" src={adoNET} alt="ado NET" />
                                                <p className="details">Copy this connection string. Store it somewhere in notepad we will be using this ahead.</p>
                                                <h5 className="fw-bolder my-4">Publish Backend Application to Azure app service </h5>
                                                <p className="details">Now open your application Visual Studio</p>
                                                <p className="details">open appsettings.json</p>
                                                <p className="details">Replace the “SelfUrl” and “Authority” URL with the URL of your backend web app service. (Which we suggested you copy in notepad).</p>
                                                <p className="details">If you didn’t note down URLs open your resource group = open app service for your backend application and copy the URL, paste it here. Make sure you are in the right app service</p>
                                                <img className="py-3" src={azureApp} alt="Azure App" />
                                                <p className="details">Do the same step for your Angular application too, for finding URL.</p>
                                                <p className="details">Replace UIappService URL with your ulappservice URL</p>
                                                <img className="py-3" src={appSettings} alt="appSettings" />
                                                <p className="details">Now change your connection string also. With the connection string of your azure database.</p>
                                                <p className="details">This is how your appSetting.json file should look after replacing the URL and connection string.</p>
                                                <img className="py-3" src={connectionString} alt="Connection String" />
                                                <p className="details">Open your DbMigartor project and replace the connection string inside this project too.</p>
                                                <p className="details">Now right click on the Dbmigrator project click ‘Set as Startup Project ‘</p>
                                                <img className="py-3" src={setAsStartup} alt="Set As Startup" />
                                                <p className="details">Click on Green arrow to Run the DB migrator project. As shown below</p>
                                                <img className="py-3" src={greenArrowDBMigrate} alt="Click on Green arrow to Run the DB migrator project. As shown below" />
                                                <p className="details">After successful migration, your window will look like as follows</p>
                                                <img className="py-3" src={migration} alt="Migration" />
                                                <p className="details">Make sure your public IP has access to Azure Server. Update Azure Firewall accordingly to get access to Azure Server.</p>
                                                <p className="details">Now click on Your  HttpApi.Host project set as a start-up project.</p>
                                                <img className="py-3" src={solutionHTTPApi} alt="Now click on Your  HttpApi.Host project set as a start-up project." />
                                                <p> <img className="py-3 w-50" src={tabs} alt="Set As Startup Project" /></p>
                                                <p className="details">Now we need to publish this application to the Azure app service</p>
                                                <p className="details">Click on publish.</p>
                                                <img className="py-3" src={clickOnPublish} alt="Click On Publish" />
                                                <p className="details">Select Web Deploy.pubxml.</p>
                                                <p className="details">Click on New</p>
                                                <img className="py-3" src={clickOnNew} alt="clickOnNew" />
                                                <p className="details">Click on Import profile == Next</p>
                                                <img className="py-3" src={publishImportProfile} alt="Publish Import Profile" />
                                                <p className="details">Now click on Browse, Go to your downloads, There will be two publishing profile which you  downloaded earlier.</p>
                                                <p className="details">This is how your publishing profile files should get displayed in downloads</p>
                                                <img className="py-3" src={publishingProfile} alt="Publishing Profile" />
                                                <p className="details">Select publishing profile file of your backend application.</p>
                                                <p className="details">Click on Finish.</p>
                                                <img className="py-3" src={publishImportSettingsFile} alt="Publish Import Settings File" />
                                                <p className="details">Now in Top right corner you will see Publish option. Click on it.</p>
                                                <img className="py-3" src={publishOption} alt="Publish Option" />
                                                <p className="details">After your publishing done it will render you to swagger page of you backend application.</p>
                                                <p className="details">If you are able to see swagger page like below it means you have successfully published your backend application to your azure app service.</p>
                                                <img className="py-3" src={swaggerPocApi} alt="swaggerPocApi" />
                                                <p className="details">Now we need to publish your angular application to your azure app service.</p>
                                                <p className="details">Now open your angular project in Vs code or any editor you prefer.</p>
                                                <p className="details">We are using visual studio code here.</p>
                                                <h5 className="fw-bolder my-4">Deploying Angular application to Azure </h5>
                                                <p className="details">On the left side of your screen, you can see sidebar as follow</p>
                                                <p className="details">Expand environments folder. You will see environment.prod.ts file and environment.ts file</p>
                                                <img className="py-3" src={environments} alt="environments" />
                                                <p className="details">Now like we did changes in your appsettings.json file for publishing backend application we need to do changes in these to file.</p>
                                                <p className="details">Double click on environment.prod.ts and open it replace baseUrl link with url of your UI web app service</p>
                                                <p className="details">And same for the Isssuer and url inside Default. paste backend web app service url with these two.</p>
                                                <img className="py-3 w-50" src={environmentprodts} alt="environment.prod.ts " />
                                                <p className="details">Follow same steps for environment.ts file</p>
                                                <img className="py-3" src={environmentCode} alt="environment.ts file" />
                                                <p className="details">Now right click on Terminal and select new terminal option</p>
                                                <img className="py-3" src={newTerminal} alt="New Terminal" />
                                                <p className="details">This will open your terminal in main folder path as follow.</p>
                                                <p className="details">PS D:\ABP.IO\AzureAppInsightsPOC\angular</p>
                                                <p className="details">Now paste the following command in your terminal.</p>
                                                <p className="details">ng build --prod</p>
                                                <img className="py-3" src={ngBuild} alt="ng Build" />
                                                <p className="details">Press Enter.</p>
                                                <p className="details">This command will generate dist folder in your project. Which we need to publish your angular application to your azure app service.</p>
                                                <img className="py-3" src={fileAzureAppInsightsPOC} alt="Azure App Insights POC" />
                                                <p className="details">Now go to your web Ap service which we created to publish angular app.</p>
                                                <p className="details">If you haven’t downloaded your Publish profile of uI app service, then download it as shown below.</p>
                                                <img className="py-3" src={POCUI} alt="POC UI" />
                                                <p className="details">Now open This file with Notepad.</p>
                                                <img className="py-3" src={selectNotepadOption} alt="Select Notepad Option" />
                                                <img className="py-3" src={rawCode} alt="Raw Code" />
                                                <p className="details">Now for the next step you need the WinSCP application. Open your WinSCP application and paste your publish Url, </p>
                                                <img className="py-3" src={rawCodeTwo} alt="Raw Code Two" />
                                                <p className="details">Username </p>
                                                <img className="py-3" src={rawCodeThree} alt="Raw Code Three" />
                                                <p className="details">Password in your WinSCP login profile.</p>
                                                <img className="py-3" src={winSCR} alt="win SCR" />
                                                <img className="py-3" src={connectingToWaws} alt="Connecting To Waws" />
                                                <p className="details">Here now we are connected to Http. On right side there is your cloud container and on the left side is your local machine</p>
                                                <img className="py-3" src={connectToHttp} alt="Here now we are connected to Http. On right side there is your cloud container and on the left side is your local machine" />
                                                <p className="details">Now go inside your local machine = go to downloads. There you will find your Zipped dist folder.</p>
                                                <p className="details">Now create a new folder to extract the zip file of your dist file.</p>
                                                <img className="py-3" src={downloadsUI} alt="Downloads UI" />
                                                <p className="details">Now right click on your dist zip file choose the extract all option. Select the folder path which we created earlier to Extract the file.</p>
                                                <p className="details">Click on select Folder (your file will get extracted).</p>
                                                <img className="py-3" src={selectADestination} alt="Select A Destination" />
                                                <p className="details">Now open this folder from WinSCP</p>
                                                <img className="py-3" src={wwwRoot} alt="www Root" />
                                                <p className="details">Now select all those files and click on upload</p>
                                                <img className="py-3" src={selectFilesToUpload} alt="Select Files ToUpload" />
                                                <p className="details">Click on “ok”.</p>
                                                <img className="py-3" src={uploadFilesToRemoteDirectory} alt="Upload Files To RemoteDirectory" />
                                                <p className="details">Wait Till your all files get uploaded. It may take some time.</p>
                                                <p className="details">After completing the upload your screen will look like this.</p>
                                                <img className="py-3" src={uploadScreen} alt="After completing the upload your screen will look like this." />
                                                <p className="details">Your Deployment is Done.</p>
                                                <p className="details">Now go to your resource group and open the UI app service.</p>
                                                <p className="details">Copy UI app URL and paste it into your browser.</p>
                                                <img className="py-3" src={copyURl} alt="Copy UI app URL and paste it into your browser" />
                                                <p className="details">if you are able to see the Home page of your application then Congratulations it’s done….</p>
                                                <img className="py-3" src={AbpHomePage} alt="AbpHomePage" />
                                                <p className="details">Now Final task is to enable application insights of your freshly created application.</p>
                                                <h5 className="fw-bolder my-4">Enabling Azure Application Insights </h5>
                                                <p className="details">Now go to your web app service (Make sure you are in your backend app service, not the UI one)</p>
                                                <p className="details">Click on Application insights</p>
                                                <img className="py-3" src={POCWebApp} alt="POC Web App" />
                                                <p className="details">It will render your application insights page</p>
                                                <img className="py-3" src={applicationInsights} alt="applicationInsights" />
                                                <img className="py-3" src={appInTwo} alt="app In Two" />
                                                <img className="py-3" src={appInThree} alt="appInThree" />
                                                <img className="py-3" src={applyMonitoringSettings} alt="Apply Monitoring Settings" />
                                                <img className="py-3" src={pocWebAppApplication} alt="poc Web App Application" />
                                                <img className="py-3" src={pocDashboard} alt="pocDashboard" />
                                                <img className="py-3" src={dashboardPOC} alt="Dashboard POC" />
                                                <h5 className="fw-bolder my-4">How Application Insights works </h5>
                                                <p className="details">Application Insights inserts a small package to your application and set up the Application Insights resource within Azure, thus sending the data to Azure to collect information. Your web application is monitored, and it sends telemetry data to the Insight portal (the portal itself is Azure, but the application can be pretty much anywhere – cloud or on-premises).</p>
                                                <h5 className="fw-bolder my-4">What data you can collect with Application Insights  </h5>
                                                <ul className="ms-4">
                                                    <li>The most popular webpages in your application, at what time of day and where is that traffic coming from?</li>
                                                    <li>Dependency rates or response times and failure rates to find out if there’s an external service that’s causing performance issues on your app.</li>
                                                    <li>Exceptions for both server and browser information, as well as page views and 5load performance from the end users’ side.</li>
                                                    <li>Session info – who, what, when, where.</li>
                                                    <li>Performance and host diagnostics – giving you a complete picture of what’s happening in your application.</li>
                                                    <li>Trace logs for correlating trace events with requests to help you get a deeper insight into the data and dig deeper into the diagnostics to improve performance.</li>
                                                </ul>


                                        </div>


                                        {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                                        <div className="py-3 mt-3">
                                            <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                    <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                            <span className="featured-date ms-4">Today at 5:42PM</span>
                                                            <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                            <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                    <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                            <span className="featured-date ms-4">Today at 5:42PM</span>
                                                            <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                            <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mx-5">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                    <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                            <span className="featured-date ms-4">Today at 5:42PM</span>
                                                            <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                            <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row aarw">
                                            <div className="col-12">
                                                <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/fileManagementModule">
                                                        <img className="card-img-top" src={relatedblog11} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">23/06/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>

                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">File Management Module</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">ABP Commercial provides the file management module that can be used to upload, download, and organize files in a folder structure.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
                                                        <img className="card-img-top" src={relatedblog12} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">28/06/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>

                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Work with PDF's in ABP Commercial Project using PDFTron</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this short article we will integrate PDFTron in an ABP commercial Angular application. PDFTron...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/createCustomLoginPageAbp">
                                                        <img className="card-img-top" src={relatedblog13} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">08/07/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Create a custom Login page in ABP Commercial Angular App</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article and video, we will demonstrate how to work with the default login page of ABP...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                        <div className="row d-flex justify-content-between py-3 align-items-center">
                                            <div className="col-md-6">
                                                <div className="d-flex align-items-center">
                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                            <div>
                                                                <span>Kirti Kulkarni</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="align-items-end d-flex justify-content-end">
                                                    <div className="footer-social d-flex gap-0">
                                                        <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                            {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                        <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                        <div>
                                            <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                            <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
                                                <div className="card mb-3 px-3 py-2">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                            <h5 className="card-title">
                                                                <div className="d-flex align-items-center">
                                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                            <div>
                                                                                <span className="fs-6">Kirti Kulkarni</span>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </h5>
                                                            <p className="card-text details fw-bold lh-sm">Create database per tenant in SQL Elastic Pool with ABP Commercial</p>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                            <div className="card-body p-0">
                                                                <img src={createDatabaseImg} className="img-fluid" alt="Create database per tenant in SQL Elastic Pool with ABP Commercial" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
                                                <div className="card mb-3 px-3 py-2">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                            <h5 className="card-title">
                                                                <div className="d-flex align-items-center">
                                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                            <div>
                                                                                <span className="fs-6">Kirti Kulkarni</span>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </h5>
                                                            <p className="card-text details fw-bold lh-sm">Using Webhooks in ABP Commercial</p>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                            <div className="card-body p-0">
                                                                <img src={webhooksImg} className="img-fluid" alt="Using Webhooks in ABP Commercial" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link className="nav-link" to="/blog/integratingAndEnablingChat">
                                                <div className="card mb-3 px-3 py-2">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                            <h5 className="card-title">
                                                                <div className="d-flex align-items-center">
                                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                            <div>
                                                                                <span className="fs-6">Kirti Kulkarni</span>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </h5>
                                                            <p className="card-text details fw-bold lh-sm">Integrating and enabling the Chat module in ABP Commercial</p>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                            <div className="card-body p-0">
                                                                <img src={integratingImg} className="img-fluid" alt="Integrating and enabling the Chat module in ABP Commercial" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>
                </Container>

            </div>



        </Layout>
        //     <Layout>
        //       <div className="">
        //         <Container>
        //         <section className='mb-5'>
        //                 <div className="container mt-5">
        //                     <div className="row ">
        //                         <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
        //                             <div className="row d-flex justify-content-between py-2">
        //                                 <div className="col-md-6">
        //                                     <div className="d-flex align-items-center">
        //                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                 <div>
        //                                                     <span className="fs-3">Kirti Kulkarni</span>
        //                                                     <p className="mb-0 text-muted">Sep 02, 2022 <span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
        //                                                     </p>
        //                                                 </div>
        //                                             </span>
        //                                         </div>
        //                                         <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
        //                                             <div className="rate p-0 d-flex align-items-end mt-4">
        //                                                 <span className="font-normal">5</span>
        //                                                 <input type="radio" id="star5" name="rate" value="5" />
        //                                                 <label htmlFor="star5" title="text">5 stars</label>
        //                                                 <input type="radio" id="star4" name="rate" value="4" />
        //                                                 <label htmlFor="star4" title="text">4 stars</label>
        //                                                 <input type="radio" id="star3" name="rate" value="3" />
        //                                                 <label htmlFor="star3" title="text">3 stars</label>
        //                                                 <input type="radio" id="star2" name="rate" value="2" />
        //                                                 <label htmlFor="star2" title="text">2 stars</label>
        //                                                 <input type="radio" id="star1" name="rate" value="1" />
        //                                                 <label htmlFor="star1" title="text">1 star</label>
        //                                             </div>
        //                                             <span className="font-normal">12</span>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className="col-md-6">
        //                                     <div className="align-items-end d-flex justify-content-end">
        //                                         <div className="footer-social d-flex gap-0">
        //                                         <Link to="https://www.linkedin.com/company/hire-tech-team/">

        //                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        //                                                 </svg>
        //                                           </Link>
        //                                           <Link to="https://www.linkedin.com/company/hire-tech-team/">
        //                                             <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                 <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
        //                                             </svg></Link>
        //                                             <Link to="https://vimeo.com">
        //                                             <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                 <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
        //                                             </svg></Link>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>


        //                             <div className="row d-flex justify-content-between">
        //                                 <h4 className="text-dark fw-bolder mt-4">Deploying ABP angular application to Azure and App Insights integration</h4>
        //                                 <h5 className="fw-bolder">Introduction</h5>
        //                                 <p className="details">In this article, I will share the steps for manually deploying an ABP Commercial Angular application and backend on Azure and how to integrate Azure App Insights with the application.</p>
        //                                 <p className="details">Azure App Insights is an application performance management service for web applications and helps to monitor your website performance and to get insight into tenants and users. It enables you to monitor and improve the performance of your application.It’s designed to ensure optimal application performance. It also has a powerful analytic tool that helps you diagnose issues and gain an understanding of how people are using your web application.</p>
        //                                 <p className="details">This article assumes that you have already created an ABP Commercial application based on the modular application template, with Angular as UI framework and Entity Framework Core as database provider.</p>
        //                                 <p className="details">1.In case you have not already done, please follow the instructions outlined here to run the ABP application locally</p>
        //                                 <p className="details"><Link to="https://docs.abp.io/en/commercial/latest/getting-started-running-solution?UI=NG&DB=EF&Tiered=No" className="text-decoration-none">https://docs.abp.io/en/commercial/latest/getting-started-running-solution?UI=NG&DB=EF&Tiered=No</Link></p>
        //                                 <p className="details">2.Here is the local Swagger API Application listing as part of the Backend application </p>
        //                                 <img className="py-3" src={localSwaggerApiApplicationListing} alt="Local Swagger Api Application Listing" />
        //                                 <p className="details">This is the Angular frontend application .</p>
        //                                 <img className="py-3" src={angularFrontendApplication} alt="This is the Angular frontend application" />
        //                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} >Creating an Azure App Service for Backend </p>
        //                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} >Open your resource group of Azure Portal </p>

        // {!isLoggedIn &&
        //     <div className="row bg-light mb-5">
        //         <div className="col-lg-4">
        //             <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
        //         </div>
        //         <div className="col-lg-8">
        //             <h6 className="mt-3">To read the full post,</h6>
        //             <h4 className="fw-bolder">Sign up to AAA Academy</h4>
        //             <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
        //             <Link to={`/signUp`}>
        //                 <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
        //             </Link>
        //             <p className="mt-2">Already have an account?
        //                 <Link to="/login" className="text-decoration-none color"> Sign in
        //                 </Link> </p>
        //         </div>
        //     </div>}
        // {isLoggedIn && <>

        //                                 <p className="details">Click on <strong>Create</strong> to create a Web Application</p>
        //                                 <img className="py-3" src={createAWebApplication} alt="Click on Create to create a Web Application" />
        //                                 <p className="details">a. Now you will see multiple azure services.</p>
        //                                 <img className="py-3" src={multipleAzureServices} alt="Multiple Azure Services" />
        //                                 <p className="details">b. From it, we need a Web App, click on Create</p>
        //                                 <img className="py-3 w-50" src={createWebApp} alt="Create Web App" />
        //                                 <p className="details">4. Click on Web App</p>
        //                                 <img className="py-3 w-50" src={clickOnWebApp} alt="clickOnWebApp" />
        //                                 <p className="details">5. Now you will see the Create web app window. Here you can see Your subscription and resource group name. Make sure you have selected the correct resource group. </p>
        //                                 <img className="py-3" src={nowYouWillSeeTheCreateWebAppWindow} alt="Now You Will See The Create Web App Window" />
        //                                 <p className="details">a. Now give the appropriate name to your database (it must be unique) and select the publishing format. Here we are publishing code so selecting the code option.</p>
        //                                 <p className="details">b. In the Runtime stack, select the technology/Language in which your product is created. Here we used .net6 (LTS).</p>
        //                                 <p className="details">c. Select the Operating system as per your requirement(We are selecting Linux here).</p>
        //                                 <p className="details">d. Same for the region, select as your requirement we are selecting central India here.</p>
        //                                 <p className="details">e. Now in SKU and size click on the change size option. Here we can see three category plans dev/test, Production, and Isolated.</p>
        //                                 <p className="details">*Dev/Test: If your application is under development or testing mode you should use this option.</p>
        //                                 <p className="details">*Production: For live applications which are already getting used by customers you should use this option.</p>
        //                                 <p className="details">*Isolated: In this case, it runs dedicated Azure VMs on dedicated Azure Virtual Networks. It provides network isolation on top of computing isolation to your apps. It provides the maximum scale-out capabilities.</p>
        //                                 <p className="details mt-5">Here we already changed the size of SKU, hence you are not able to see the change size option above, but you can</p>
        //                                 <p className="details">See the change size option as shown below.</p>
        //                                 <img className="py-3" src={appServicePlan} alt="App Service Plan" />
        //                                 <p className="details">f. Select Dev/Test option and click on Apply.</p>
        //                                 <p className="details">6. Click Deployment (Next) = Networking (Next) = Monitoring (Next) = Tags (Next). Keep the default setting as it is.</p>
        //                                 <p className="details">7. Now on the Review + Create page click on Create. It will start creating your web application</p>
        //                                 <img className="py-3" src={webAppByMicrosoft} alt="Web App By Microsoft" />
        //                                 <p className="details">You will see the following page showing your deployment is in progress, wait till it’s done.</p>
        //                                 <img className="py-3" src={deploymentIsInProgress} alt="Deployment Is In Progress" />
        //                                 <p className="details">And here we go …..web app service for your backend application is ready</p>
        //                                 <img className="py-3" src={yourDeploymentIsComplete} alt="Your Deployment Is Complete" />
        //                                 <p className="details">This is how your web app service will look.</p>
        //                                 <img className="py-3" src={appService} alt="This is how your web app service will look." />
        //                                 <p className="details">Now Click on ‘Get publish profile ‘.One file will be get downloaded here, we will use it later</p>
        //                                 <img className="py-3" src={getPublishProfile} alt="Get Publish Profile" />
        //                                 <p className="details">In your resource group, now you can see your app service running.</p>
        //                                 <p className="details">This is the URL of your azure web app service we will be using this URL while publishing your application in this web app service. Copy this URL somewhere in notepad as a backend web app we will use this later.</p>
        //                                 <img className="py-3" src={azureAppServiceUrl} alt="Azure App Service Url" />
        //                                 <p className="details">Now we need to create one more web app service for publishing your angular project too.</p>
        //                                 <h5 className="fw-bolder mt-3">Creating Frontend App Service </h5>
        //                                 <p className="details mt-3">1. Open your resource group of Azure Portal</p>
        //                                 <p className="details">2. Click on Create to create a Web Application</p>
        //                                 <img className="py-3" src={clickToCreteWebApp} alt="Click To Crete WebApp" />
        //                                 <p className="details">3. Now you will see multiple azure services.</p>
        //                                 <img className="py-3 w-50" src={multipleAzureServicesWillSee} alt="Multiple Azure Services Will See" />
        //                                 <p> <img className="py-3" src={webAppService} alt="Web App Service" /></p>
        //                                 <p className="details">4. Now you will see the Create web app window. Here you can see Your subscription and resource group name. Make sure you have selected the correct resource group</p>
        //                                 <p className="details">Fill in all the details carefully as we did it before.</p>
        //                                 <p className="details">While giving the name to this app service try to use UI at the end of your app service name. It will be easy for you to differentiate your app service as a back-end application and the front-end one. (Not compulsory step)</p>
        //                                 <p className="details">Just while selecting the Runtime Stack option make sure, you select the Node option here as we are going to publish your angular application inside this app service</p>
        //                                 <img className="py-3" src={createWebAppWithInstanceDetails} alt="createWebAppWithInstanceDetails" />
        //                                 <img className="py-3" src={createWebAppByMicrosoft} alt="Create Web App By Microsoft" />
        //                                 <img className="py-3" src={deplymentIsInProgressImage} alt="Deployment Is In Progress" />
        //                                 <img className="py-3" src={deploymentCompleted} alt="Deployment Completed" />
        //                                 <p className="details">Now App service for publishing Your Angular application is also ready.</p>
        //                                 <p className="details">Click on Get publish Profile one file will get downloaded we will be using this later.</p>
        //                                 <img className="py-3" src={azureAppInsightsPocUi} alt="azureAppInsightsPocUi" />
        //                                 <p className="details">This is how your web app service will look.</p>
        //                                 <img className="py-3" src={webAppServiceWillLook} alt="Web App Service Will Look" />
        //                                 <p className="details">This URL we will use later while publishing your backend application to this azure app service.</p>
        //                                 <p className="details">Now we need to create azure SQL for our azure web application. SQL servers on our local machines are not accessible here.</p>
        //                                 <p className="details">Now Go to Home and click on your resource group. You will get redirected to the resource group dashboard. Here click on Create.</p>
        //                                 <h5 className="fw-bolder my-4">Creating Azure Database </h5>
        //                                 <img className="py-3" src={createAzureDatabase} alt="Create Azure Database" />
        //                                 <p className="details">Search for SQL, you will see multiple SQL database options here.</p>
        //                                 <img className="py-3" src={searchForSql} alt="Search for SQL, you will see multiple SQL database options here." />
        //                                 <p className="details">Click on Create = Azure SQL</p>
        //                                 <img className="py-3 w-50" src={azureSQL} alt="Azure SQL" />
        //                                 <p className="details">Now we want SQL databases here click on create</p>
        //                                 <img className="py-3 w-50" src={SQLDatabases} alt="sql Databases" />
        //                                 <p className="details">Give the appropriate Server Name and select the location as per your need. Here we are using Central India as a location. Use the same location for the web application and its database to avoid latency.</p>
        //                                 <img className="py-3" src={sqlDatabaseCreate} alt="SQL Database Create" />
        //                                 <p className="details">While giving server name if you are creating it for the first time then give the name to your server as per your requirement.</p>
        //                                 <img className="py-3" src={createSqlDatabasesNew} alt="create Sql Databases New" />
        //                                 <p className="details text-danger">** Remember your password you will need it again while editing your database connection string.</p>
        //                                 <p className="details">When you click on configure database following window will get displayed.</p>
        //                                 <p className="details">We are selecting a Basic plan here you can choose as per your requirement. If you are creating this server for testing or learning purpose we recommend you to select the Basic one as this will be the cheapest.</p>
        //                                 <p className="details">Click on Apply.</p>
        //                                 <img className="py-3" src={configureSQL} alt="Configure SQL" />
        //                                 <p className="details">Make sure you have selected all options carefully</p>
        //                                 <img className="py-3" src={selectedAllOptions} alt="Selected All Options" />
        //                                 <img className="py-3" src={deploymentDetails} alt="deploymentDetails" />
        //                                 <p className="details">Here we go your SQL server with the database is ready.</p>
        //                                 <img className="py-3" src={sqlServerIsReady} alt="Here we go your SQL server with the database is ready." />
        //                                 <p className="details">Now click on connection strings,</p>
        //                                 <img className="py-3 w-50" src={clickOnConnectionString} alt="Now click on connection strings" />
        //                                 <p className="details">The following window will appear.</p>
        //                                 <p className="details">Here you need to enter your password which you entered earlier. Remove “your password” and enter the password instead.</p>
        //                                 <img className="py-3" src={adoNET} alt="ado NET" />
        //                                 <p className="details">Copy this connection string. Store it somewhere in notepad we will be using this ahead.</p>
        //                                 <h5 className="fw-bolder my-4">Publish Backend Application to Azure app service </h5>
        //                                 <p className="details">Now open your application Visual Studio</p>
        //                                 <p className="details">open appsettings.json</p>
        //                                 <p className="details">Replace the “SelfUrl” and “Authority” URL with the URL of your backend web app service. (Which we suggested you copy in notepad).</p>
        //                                 <p className="details">If you didn’t note down URLs open your resource group = open app service for your backend application and copy the URL, paste it here. Make sure you are in the right app service</p>
        //                                 <img className="py-3" src={azureApp} alt="Azure App" />
        //                                 <p className="details">Do the same step for your Angular application too, for finding URL.</p>
        //                                 <p className="details">Replace UIappService URL with your ulappservice URL</p>
        //                                 <img className="py-3" src={appSettings} alt="appSettings" />
        //                                 <p className="details">Now change your connection string also. With the connection string of your azure database.</p>
        //                                 <p className="details">This is how your appSetting.json file should look after replacing the URL and connection string.</p>
        //                                 <img className="py-3" src={connectionString} alt="Connection String" />
        //                                 <p className="details">Open your DbMigartor project and replace the connection string inside this project too.</p>
        //                                 <p className="details">Now right click on the Dbmigrator project click ‘Set as Startup Project ‘</p>
        //                                 <img className="py-3" src={setAsStartup} alt="Set As Startup" />
        //                                 <p className="details">Click on Green arrow to Run the DB migrator project. As shown below</p>
        //                                 <img className="py-3" src={greenArrowDBMigrate} alt="Click on Green arrow to Run the DB migrator project. As shown below" />
        //                                 <p className="details">After successful migration, your window will look like as follows</p>
        //                                 <img className="py-3" src={migration} alt="Migration" />
        //                                 <p className="details">Make sure your public IP has access to Azure Server. Update Azure Firewall accordingly to get access to Azure Server.</p>
        //                                 <p className="details">Now click on Your  HttpApi.Host project set as a start-up project.</p>
        //                                 <img className="py-3" src={solutionHTTPApi} alt="Now click on Your  HttpApi.Host project set as a start-up project." />
        //                                 <p> <img className="py-3 w-50" src={tabs} alt="Set As Startup Project" /></p>
        //                                 <p className="details">Now we need to publish this application to the Azure app service</p>
        //                                 <p className="details">Click on publish.</p>
        //                                 <img className="py-3" src={clickOnPublish} alt="Click On Publish" />
        //                                 <p className="details">Select Web Deploy.pubxml.</p>
        //                                 <p className="details">Click on New</p>
        //                                 <img className="py-3" src={clickOnNew} alt="clickOnNew" />
        //                                 <p className="details">Click on Import profile == Next</p>
        //                                 <img className="py-3" src={publishImportProfile} alt="Publish Import Profile" />
        //                                 <p className="details">Now click on Browse, Go to your downloads, There will be two publishing profile which you  downloaded earlier.</p>
        //                                 <p className="details">This is how your publishing profile files should get displayed in downloads</p>
        //                                 <img className="py-3" src={publishingProfile} alt="Publishing Profile" />
        //                                 <p className="details">Select publishing profile file of your backend application.</p>
        //                                 <p className="details">Click on Finish.</p>
        //                                 <img className="py-3" src={publishImportSettingsFile} alt="Publish Import Settings File" />
        //                                 <p className="details">Now in Top right corner you will see Publish option. Click on it.</p>
        //                                 <img className="py-3" src={publishOption} alt="Publish Option" />
        //                                 <p className="details">After your publishing done it will render you to swagger page of you backend application.</p>
        //                                 <p className="details">If you are able to see swagger page like below it means you have successfully published your backend application to your azure app service.</p>
        //                                 <img className="py-3" src={swaggerPocApi} alt="swaggerPocApi" />
        //                                 <p className="details">Now we need to publish your angular application to your azure app service.</p>
        //                                 <p className="details">Now open your angular project in Vs code or any editor you prefer.</p>
        //                                 <p className="details">We are using visual studio code here.</p>
        //                                 <h5 className="fw-bolder my-4">Deploying Angular application to Azure </h5>
        //                                 <p className="details">On the left side of your screen, you can see sidebar as follow</p>
        //                                 <p className="details">Expand environments folder. You will see environment.prod.ts file and environment.ts file</p>
        //                                 <img className="py-3" src={environments} alt="environments" />
        //                                 <p className="details">Now like we did changes in your appsettings.json file for publishing backend application we need to do changes in these to file.</p>
        //                                 <p className="details">Double click on environment.prod.ts and open it replace baseUrl link with url of your UI web app service</p>
        //                                 <p className="details">And same for the Isssuer and url inside Default. paste backend web app service url with these two.</p>
        //                                 <img className="py-3 w-50" src={environmentprodts} alt="environment.prod.ts " />
        //                                 <p className="details">Follow same steps for environment.ts file</p>
        //                                 <img className="py-3" src={environmentCode} alt="environment.ts file" />
        //                                 <p className="details">Now right click on Terminal and select new terminal option</p>
        //                                 <img className="py-3" src={newTerminal} alt="New Terminal" />
        //                                 <p className="details">This will open your terminal in main folder path as follow.</p>
        //                                 <p className="details">PS D:\ABP.IO\AzureAppInsightsPOC\angular</p>
        //                                 <p className="details">Now paste the following command in your terminal.</p>
        //                                 <p className="details">ng build --prod</p>
        //                                 <img className="py-3" src={ngBuild} alt="ng Build" />
        //                                 <p className="details">Press Enter.</p>
        //                                 <p className="details">This command will generate dist folder in your project. Which we need to publish your angular application to your azure app service.</p>
        //                                 <img className="py-3" src={fileAzureAppInsightsPOC} alt="Azure App Insights POC" />
        //                                 <p className="details">Now go to your web Ap service which we created to publish angular app.</p>
        //                                 <p className="details">If you haven’t downloaded your Publish profile of uI app service, then download it as shown below.</p>
        //                                 <img className="py-3" src={POCUI} alt="POC UI" />
        //                                 <p className="details">Now open This file with Notepad.</p>
        //                                 <img className="py-3" src={selectNotepadOption} alt="Select Notepad Option" />
        //                                 <img className="py-3" src={rawCode} alt="Raw Code" />
        //                                 <p className="details">Now for the next step you need the WinSCP application. Open your WinSCP application and paste your publish Url, </p>
        //                                 <img className="py-3" src={rawCodeTwo} alt="Raw Code Two" />
        //                                 <p className="details">Username </p>
        //                                 <img className="py-3" src={rawCodeThree} alt="Raw Code Three" />
        //                                 <p className="details">Password in your WinSCP login profile.</p>
        //                                 <img className="py-3" src={winSCR} alt="win SCR" />
        //                                 <img className="py-3" src={connectingToWaws} alt="Connecting To Waws" />
        //                                 <p className="details">Here now we are connected to Http. On right side there is your cloud container and on the left side is your local machine</p>
        //                                 <img className="py-3" src={connectToHttp} alt="Here now we are connected to Http. On right side there is your cloud container and on the left side is your local machine" />
        //                                 <p className="details">Now go inside your local machine = go to downloads. There you will find your Zipped dist folder.</p>
        //                                 <p className="details">Now create a new folder to extract the zip file of your dist file.</p>
        //                                 <img className="py-3" src={downloadsUI} alt="Downloads UI" />
        //                                 <p className="details">Now right click on your dist zip file choose the extract all option. Select the folder path which we created earlier to Extract the file.</p>
        //                                 <p className="details">Click on select Folder (your file will get extracted).</p>
        //                                 <img className="py-3" src={selectADestination} alt="Select A Destination" />
        //                                 <p className="details">Now open this folder from WinSCP</p>
        //                                 <img className="py-3" src={wwwRoot} alt="www Root" />
        //                                 <p className="details">Now select all those files and click on upload</p>
        //                                 <img className="py-3" src={selectFilesToUpload} alt="Select Files ToUpload" />
        //                                 <p className="details">Click on “ok”.</p>
        //                                 <img className="py-3" src={uploadFilesToRemoteDirectory} alt="Upload Files To RemoteDirectory" />
        //                                 <p className="details">Wait Till your all files get uploaded. It may take some time.</p>
        //                                 <p className="details">After completing the upload your screen will look like this.</p>
        //                                 <img className="py-3" src={uploadScreen} alt="After completing the upload your screen will look like this." />
        //                                 <p className="details">Your Deployment is Done.</p>
        //                                 <p className="details">Now go to your resource group and open the UI app service.</p>
        //                                 <p className="details">Copy UI app URL and paste it into your browser.</p>
        //                                 <img className="py-3" src={copyURl} alt="Copy UI app URL and paste it into your browser" />
        //                                 <p className="details">if you are able to see the Home page of your application then Congratulations it’s done….</p>
        //                                 <img className="py-3" src={AbpHomePage} alt="AbpHomePage" />
        //                                 <p className="details">Now Final task is to enable application insights of your freshly created application.</p>
        //                                 <h5 className="fw-bolder my-4">Enabling Azure Application Insights </h5>
        //                                 <p className="details">Now go to your web app service (Make sure you are in your backend app service, not the UI one)</p>
        //                                 <p className="details">Click on Application insights</p>
        //                                 <img className="py-3" src={POCWebApp} alt="POC Web App" />
        //                                 <p className="details">It will render your application insights page</p>
        //                                 <img className="py-3" src={applicationInsights} alt="applicationInsights" />
        //                                 <img className="py-3" src={appInTwo} alt="app In Two" />
        //                                 <img className="py-3" src={appInThree} alt="appInThree" />
        //                                 <img className="py-3" src={applyMonitoringSettings} alt="Apply Monitoring Settings" />
        //                                 <img className="py-3" src={pocWebAppApplication} alt="poc Web App Application" />
        //                                 <img className="py-3" src={pocDashboard} alt="pocDashboard" />
        //                                 <img className="py-3" src={dashboardPOC} alt="Dashboard POC" />
        //                                 <h5 className="fw-bolder my-4">How Application Insights works </h5>
        //                                 <p className="details">Application Insights inserts a small package to your application and set up the Application Insights resource within Azure, thus sending the data to Azure to collect information. Your web application is monitored, and it sends telemetry data to the Insight portal (the portal itself is Azure, but the application can be pretty much anywhere – cloud or on-premises).</p>
        //                                 <h5 className="fw-bolder my-4">What data you can collect with Application Insights  </h5>
        //                                 <ul className="ms-4">
        //                                     <li>The most popular webpages in your application, at what time of day and where is that traffic coming from?</li>
        //                                     <li>Dependency rates or response times and failure rates to find out if there’s an external service that’s causing performance issues on your app.</li>
        //                                     <li>Exceptions for both server and browser information, as well as page views and 5load performance from the end users’ side.</li>
        //                                     <li>Session info – who, what, when, where.</li>
        //                                     <li>Performance and host diagnostics – giving you a complete picture of what’s happening in your application.</li>
        //                                     <li>Trace logs for correlating trace events with requests to help you get a deeper insight into the data and dig deeper into the diagnostics to improve performance.</li>
        //                                 </ul></>}
        //                             </div>
        //                             <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
        //                             <div className="py-3">
        //                                 <h4 className="text-dark fw-bolder">Featured Comments</h4>
        //                                 <div className="d-flex align-items-center">
        //                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                             <div>
        //                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
        //                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
        //                                             </div>
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                                 <p className="text-muted mx-5">Reply</p>

        //                                 <div className="d-flex align-items-center">
        //                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                             <div>
        //                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
        //                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
        //                                             </div>
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                                 <p className="text-muted mx-5">Reply</p>

        //                                 <div className="d-flex align-items-center mx-5">
        //                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                             <div>
        //                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
        //                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
        //                                             </div>
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                                 <p className="text-muted mx-5">Reply</p>
        //                             </div>
        //                             <div className="row aarw">
        //                                 <div className="col-12">
        //                                     <h5 className="related-title">More Articles from Joe Thomson</h5>
        //                                 </div>
        //                                 <div className="col-12">
        //                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

        //                                         <div className="card-aa ca1 border-0 h-100">
        //                                             <Link className="nav-link" to="/footer">
        //                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
        //                                                 <div className="card-body-aa px-3">
        //                                                     <div className="row mb-2">
        //                                                         <div className="col-md-8">
        //                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
        //                                                         </div>
        //                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
        //                                                     </div>
        //                                                     <div className="position mb-2">Software Development</div>

        //                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
        //                                                     <div className="d-flex justify-content-between">
        //                                                         <p className="font-colored">Beginner -5 min</p>
        //                                                         <div>
        //                                                             <div className="rate p-0">
        //                                                                 <span className="font-normal">4.5</span>
        //                                                                 <input type="radio" id="star5" name="rate" value="5" />
        //                                                                 <label htmlFor="star5" title="text">5 stars</label>
        //                                                                 <input type="radio" id="star4" name="rate" value="4" />
        //                                                                 <label htmlFor="star4" title="text">4 stars</label>
        //                                                                 <input type="radio" id="star3" name="rate" value="3" />
        //                                                                 <label htmlFor="star3" title="text">3 stars</label>
        //                                                                 <input type="radio" id="star2" name="rate" value="2" />
        //                                                                 <label htmlFor="star2" title="text">2 stars</label>
        //                                                                 <input type="radio" id="star1" name="rate" value="1" />
        //                                                                 <label htmlFor="star1" title="text">1 star</label>
        //                                                             </div>
        //                                                             <span className="font-normal">520</span>
        //                                                         </div>

        //                                                     </div>
        //                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
        //                                                 </div>
        //                                             </Link>
        //                                         </div>
        //                                     </div>
        //                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

        //                                         <div className="card-aa ca1 border-0 h-100">
        //                                             <Link className="nav-link" to="/footer">
        //                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
        //                                                 <div className="card-body-aa px-3">
        //                                                     <div className="row mb-2">
        //                                                         <div className="col-md-8">
        //                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
        //                                                         </div>
        //                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
        //                                                     </div>
        //                                                     <div className="position mb-2">Software Development</div>

        //                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
        //                                                     <div className="d-flex justify-content-between">
        //                                                         <p className="font-colored">Beginner -5 min</p>
        //                                                         <div>
        //                                                             <div className="rate p-0">
        //                                                                 <span className="font-normal">4.5</span>
        //                                                                 <input type="radio" id="star5" name="rate" value="5" />
        //                                                                 <label htmlFor="star5" title="text">5 stars</label>
        //                                                                 <input type="radio" id="star4" name="rate" value="4" />
        //                                                                 <label htmlFor="star4" title="text">4 stars</label>
        //                                                                 <input type="radio" id="star3" name="rate" value="3" />
        //                                                                 <label htmlFor="star3" title="text">3 stars</label>
        //                                                                 <input type="radio" id="star2" name="rate" value="2" />
        //                                                                 <label htmlFor="star2" title="text">2 stars</label>
        //                                                                 <input type="radio" id="star1" name="rate" value="1" />
        //                                                                 <label htmlFor="star1" title="text">1 star</label>
        //                                                             </div>
        //                                                             <span className="font-normal">520</span>
        //                                                         </div>

        //                                                     </div>
        //                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
        //                                                 </div>
        //                                             </Link>
        //                                         </div>
        //                                     </div>
        //                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

        //                                         <div className="card-aa ca1 border-0 h-100">
        //                                             <Link className="nav-link" to="/footer">
        //                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
        //                                                 <div className="card-body-aa px-3">
        //                                                     <div className="row mb-2">
        //                                                         <div className="col-md-8">
        //                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
        //                                                         </div>
        //                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
        //                                                     </div>
        //                                                     <div className="position mb-2">Software Development</div>

        //                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
        //                                                     <div className="d-flex justify-content-between">
        //                                                         <p className="font-colored">Beginner -5 min</p>
        //                                                         <div>
        //                                                             <div className="rate p-0">
        //                                                                 <span className="font-normal">4.5</span>
        //                                                                 <input type="radio" id="star5" name="rate" value="5" />
        //                                                                 <label htmlFor="star5" title="text">5 stars</label>
        //                                                                 <input type="radio" id="star4" name="rate" value="4" />
        //                                                                 <label htmlFor="star4" title="text">4 stars</label>
        //                                                                 <input type="radio" id="star3" name="rate" value="3" />
        //                                                                 <label htmlFor="star3" title="text">3 stars</label>
        //                                                                 <input type="radio" id="star2" name="rate" value="2" />
        //                                                                 <label htmlFor="star2" title="text">2 stars</label>
        //                                                                 <input type="radio" id="star1" name="rate" value="1" />
        //                                                                 <label htmlFor="star1" title="text">1 star</label>
        //                                                             </div>
        //                                                             <span className="font-normal">520</span>
        //                                                         </div>

        //                                                     </div>
        //                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
        //                                                 </div>
        //                                             </Link>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
        //                             <div className="row d-flex justify-content-between py-3">
        //                                 <div className="col-md-6">
        //                                     <div className="d-flex align-items-center">
        //                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                 <div>
        //                                                     <span>Kirti Kulkarni</span>
        //                                                 </div>
        //                                             </span>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className="col-md-6">
        //                                     <div className="align-items-end d-flex justify-content-end">

        //                                         <div className="footer-social d-flex gap-0">

        //                                             <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes">
        //                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
        //                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        //                                                 </svg>
        //                                             </a>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <h6 className="about-author">ABOUT THE AUTHOR</h6>
        //                             <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
        //                             <div>
        //                                 <h5 className="fw-medium mb-3">Latest Articles</h5>
        //                                 <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
        //                                 <div className="card mb-3 px-3 py-2">
        //                                     <div className="row g-0">
        //                                         <div className="col-md-8">
        //                                             <h5 className="card-title">
        //                                                 <div className="d-flex align-items-center">
        //                                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                         <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                             <div>
        //                                                                 <span className="fs-6">Kirti Kulkarni</span>
        //                                                             </div>
        //                                                         </span>
        //                                                     </div>
        //                                                 </div>
        //                                             </h5>
        //                                             <p className="card-text details">Create database per tenant in SQL Elastic Pool with ABP Commercial</p>
        //                                         </div>
        //                                         <div className="col-md-4">
        //                                             <div className="card-body">
        //                                                 <img src={createDatabaseImg} className="img-fluid" alt="Create database per tenant in SQL Elastic Pool with ABP Commercial" />
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 </Link>
        //                                 <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
        //                                 <div className="card mb-3 px-3 py-2">
        //                                     <div className="row g-0">
        //                                         <div className="col-md-8">
        //                                             <h5 className="card-title">
        //                                                 <div className="d-flex align-items-center">
        //                                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                         <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                             <div>
        //                                                                 <span className="fs-6">Kirti Kulkarni</span>
        //                                                             </div>
        //                                                         </span>
        //                                                     </div>
        //                                                 </div>
        //                                             </h5>
        //                                             <p className="card-text details">Using Webhooks in ABP Commercial</p>
        //                                         </div>
        //                                         <div className="col-md-4">
        //                                             <div className="card-body">
        //                                                 <img src={webhooksImg} className="img-fluid" alt="Using Webhooks in ABP Commercial" />
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 </Link>
        //                                 <Link className="nav-link" to="/blog/integratingAndEnablingChat">
        //                                 <div className="card mb-3 px-3 py-2">
        //                                     <div className="row g-0">
        //                                         <div className="col-md-8">
        //                                             <h5 className="card-title">
        //                                                 <div className="d-flex align-items-center">
        //                                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
        //                                                         <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
        //                                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
        //                                                             <div>
        //                                                                 <span className="fs-6">Kirti Kulkarni</span>
        //                                                             </div>
        //                                                         </span>
        //                                                     </div>
        //                                                 </div>
        //                                             </h5>
        //                                             <p className="card-text details">Integrating and enabling the Chat module in ABP Commercial</p>
        //                                         </div>
        //                                         <div className="col-md-4">
        //                                             <div className="card-body">
        //                                                 <img src={integratingImg} className="img-fluid" alt="Integrating and enabling the Chat module in ABP Commercial" />
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 </Link>
        //                             </div>
        //                         </div>

        //                     </div>
        //                 </div>
        //             </section>
        //         </Container>

        //       </div>



        //     </Layout>

    );
};

export default SamplePage;
