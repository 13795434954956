import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import runningABPApp from "../../../static/AAA-images/runningABPApp.png"
import installationOfAbpSuite from "../../../static/AAA-images/installationOfAbpSuite.png"
import addAnAbpSuite from "../../../static/AAA-images/addAnAbpSuite.png"
import abpSuiteTemplate from "../../../static/AAA-images/abpSuiteTemplate.png"
import chat from "../../../static/AAA-images/chat.png"
import moduleInstalling from "../../../static/AAA-images/moduleInstalling.png"
import followingReferences from "../../../static/AAA-images/followingReferences.png"
import bookStoreApplicationModuleClass from "../../../static/AAA-images/bookStoreApplicationModuleClass.png"
import enableChatFeature from "../../../static/AAA-images/enableChatFeature.png"
import assignFeature from "../../../static/AAA-images/assignFeature.png"
import features from "../../../static/AAA-images/features.png"
import usersPermissions from "../../../static/AAA-images/usersPermissions.png"
import permissionsForChat from "../../../static/AAA-images/permissionsForChat.png"
import message from "../../../static/AAA-images/message.png"
import mainDash from "../../../static/AAA-images/mainDash.png"
import webhooksImg from "../../../static/AAA-images/webhooksImg.png"
import createDatabaseImg from "../../../static/AAA-images/createDatabaseImg.png"
import deployingAbpImg from "../../../static/AAA-images/deployingAbpImg.png"
import { useState, useEffect } from "react";

import relatedblog1 from "../../../static/AAA-images/Implement-Single-Sign-On-with-ABP-commercial.png"
import relatedblog2 from "../../../static/AAA-images/gradient.png"
import relatedblog3 from "../../../static/AAA-images/Integrating-Elsa-.NET-Workflows-with-ABP-Commercial.png"

const SamplePage = (props) => {
    const appUse = `
    app.Use(async (httpContext, next) =>
    {
        var accessToken = httpContext.Request.Query["access_token"];
   
        var path = httpContext.Request.Path;
         if (!string.IsNullOrEmpty(accessToken) &&
            (path.StartsWithSegments("/signalr-hubs/chat")))
            {
               httpContext.Request.Headers["Authorization"] = "Bearer " + accessToken;
            }
   
            await next();
     }); `;
    
     const ProjectName = "";
  return (
    <Layout >
    <div className="">
        <Container>
            <div className="">
                <section className='mb-5'>
                    <div className="container mt-5">
                        <div className="row ">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                <div className="row d-flex justify-content-between py-2">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                        <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                            <p className="mb-0 small text-muted">Nov 03, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                            <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                                <div className="rate p-0">
                                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                                </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                      
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div className="">

                                            <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                <Link to="https://www.linkedin.com/company/hire-tech-team/">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </Link>
                                                <Link to="https://twitter.com/HIRETECHTEAM">
                                                 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                    </svg></Link>
                                                <Link to="https://vimeo.com">
                                                  
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                    </svg></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between">
                                <h4 className="text-dark fw-bolder mt-5 mb-4">Integrating and enabling the Chat module in ABP Commercial</h4>
                                 <h5 className="fw-bolder mt-4 mb-2">Introduction</h5>
                                 <p className="details mb-2">This module implements real time messaging between users for an application.</p>
                                 <p className="details mb-2">ABP Commercial chat module provides chat features and increases your user experience & user engagement by facilitating users to connect with other users.</p>
                                 <p className="details">Let us see how you can integrate the ABP chat module.</p>
                                 <h5 className="fw-bolder mt-5 mb-2">Let's begin </h5>
                                 <p className="details mb-5" >This document assumes that you already created an ABP Web application with Angular as UI framework and Entity Framework Core as database provider. </p>
                                 <p className="details" > </p>
{/* 
                                             {!isLoggedIn &&
                                                <div className="row signup-banner py-4 mx-0 mb-5 align-items-center">
                                                <div className="col-lg-4 text-center mb-4">
                                                    <img className="w-75" src={readPostImg} alt="Logo" />
                                                </div>
                                                <div className="col-lg-8">
                                                    <h6 className="fs-5 fw-light">To read the full post,</h6>
                                                    <h4 className="fw-semibold">Sign up to AAA Academy</h4>
                                                    <p className="py-3 pe-xxl-5 pe-xl-5 pe-lg-5 pe-0">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
                                                    <Link to={`/signup`}>
                                                        <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
                                                    </Link>
                                                    <p className="mt-2 small">Already have an account?
                                                        <Link to="/login" className="text-decoration-none link-primary"> Sign In
                                                        </Link> 
                                                    </p>
                                                </div>
                                            </div>}
                                            {isLoggedIn && <> */}
                             
                                <h5 className="fw-bolder mb-3">Running the ABP application </h5>
                                <img className="py-3" src={runningABPApp} alt="Running the ABP application " />
                                <h5 className="fw-bolder mb-2 mt-5">Installation of abp suite </h5>
                                <p className="details mb-5">Go to command prompt and type abp suite, which will open the abp suite localhost.</p>
                                <img className="py-3" src={installationOfAbpSuite} alt="Installation Of Abp Suite" />
                                <p className="details py-4">1. It will list out recently used solution or else you can add your existing solution.  </p>
                                <img className="py-3" src={addAnAbpSuite} alt=" It will list out recently used solution or else you can add your existing solution.  " />
                                <p className="details py-4">2. Verify that your project is selected as current solution on top right corner of the abp suite screen. Select Modules from top menu, which will list out all available modules of abp suite.  </p>
                                <img className="py-3" src={abpSuiteTemplate} alt="Verify that your project is selected as current solution on top right corner of the abp suite screen. Select Modules from top menu, which will list out all available modules of abp suite" />
                                <p className="details pt-4 mb-2">From here you can install or update new packages, find the module named chat.</p>
                                <p className="details mb-4">3. Click on Add as Package of chat module.  </p>
                                <img className="py-3 mb-4" src={chat} alt="chat" />
                                <img className="py-3 mb-4" src={moduleInstalling} alt="Module Installing" />
                                <p className="details mb-2">This will install chat module as a nuget package to your solution</p>
                                <p className="details mb-5">4. This will add following references </p>
                                <img className="py-3 mb-4" src={followingReferences} alt="Following References" />
                                <img className="py-3 mb-4" src={bookStoreApplicationModuleClass} alt="Book Store Application Module Class" />
                                {/* commented code for {} error */}
                                <p className="details mb-2">in ProjectName ApplicationModule</p>
                                <p className="details mb-2">The Volo.Chat.SignalR package will be added in :- ProjectName.HttpApi.Host project, {ProjectName}.HttpApi.Host project</p>
                                <p className="details mb-2">5. SignalR Access Token Configuration for Angular Projects  </p>
                                <p className="details mb-2">Here is a sample configuration for this:</p>
                                <p className="details mb-5">In ProjectName HttpApiHostModule</p>
                                <CodeSnippet codeString={appUse} />
                                <p className="details py-4">6. Enable chat feature to an Edition  </p>
                                <img className="py-3" src={enableChatFeature} alt="Enable Chat Feature" />
                                <p className="details py-4">7. Create a new tenant and assign chat feature enabled edition to it  </p>
                                <img className="py-3" src={assignFeature} alt=" Create a new tenant and assign chat feature enabled edition to it  " />
                                <p className="details py-4">Also enable chat feature to the newly created tenant</p>
                                <img className="py-3" src={features} alt="Also enable chat feature to the newly created tenant" />
                                <p className="details py-4">8.Login to the tenant user, create new User from Administration/Identity Management/Users.
                                    Enable chat permission to that user as follows</p>
                                <img className="py-3 mb-4" src={usersPermissions} alt="Login to the tenant user, create new User from Administration/Identity Management/Users.  
                                Enable chat permission to that user as follows"/>
                                <img className="py-3" src={permissionsForChat} alt="Permissions For Chat" />
                                <p className="details py-4">9. You will observe a chat icon on the tool bar. From there you can use the chat feature  </p>
                                <img className="py-3 mb-4" src={message} alt=" You will observe a chat icon on the tool bar. From there you can use the chat feature " />
                                <img className="py-3 mb-4" src={mainDash} alt="mainDash" />
                                <p className="details"></p>
                                <h5 className="fw-bolder mb-2">Conclusion</h5>
                                <p className="details mb-4">This article provides the steps to integrate the ABP commercial chat module for real time messaging between users in the application. It also provides a page for searching the users in an application for new conversations, a contact list for recent conversations, and a total unread message count badge on the menu icon and lazy loaded conversations.</p>
                                  
                              
                                </div>
                               

                                {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                                <div className="py-3 mt-3">
                                    <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                    <div className="d-flex align-items-center">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mx-5">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row aarw">
                                    <div className="col-12">
                                        <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                    </div>
                                </div>
                                <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/sample">
                                                        <img className="card-img-top" src={relatedblog1} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Implement Single Sign-On with ABP commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Learn how to implement SSO between an ASP.Net core MVC application and ABP Commercial...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/xamarinOfflineDataSync">
                                                        <img className="card-img-top" src={relatedblog2} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Xamarin Offline data sync with Mongo Realm and RabbitMQ</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12 min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article you will learn how to integrate Realm in the Xamarin Forms project and sync the offline...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/integratingElsaNet">
                                                        <img className="card-img-top" src={relatedblog3} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">14/06/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Integrating Elsa .NET Workflows with ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner - 12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article we have demonstrated about how to integrate .NET 5 Open-Source Workflow...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                <div className="row d-flex justify-content-between py-3 align-items-center">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span>Kirti Kulkarni</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="align-items-end d-flex justify-content-end">
                                            <div className="footer-social d-flex gap-0">
                                                <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                <div>
                                    <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                    <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Deploying ABP angular application to Azure and App Insights integration</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Using Webhooks in ABP Commercial</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={webhooksImg} className="img-fluid" alt="Using Webhooks in ABP Commercial" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Create database per tenant in SQL Elastic Pool with ABP Commercial</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={createDatabaseImg} className="img-fluid" alt="Create database per tenant in SQL Elastic Pool with ABP Commercial" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </Container>

    </div>



</Layout>
//     <Layout>
//       <div className="">
//         <Container>
//         <section className='mb-5'>
//                 <div className="container mt-5">
//                     <div className="row ">
//                         <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
//                             <div className="row d-flex justify-content-between py-2">
//                                 <div className="col-md-6">
//                                     <div className="d-flex align-items-center">
//                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                 <div>
//                                                     <span className="fs-3">Kirti Kulkarni</span>
//                                                     <p className="mb-0 text-muted">Nov 03, 2022<span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
//                                                     </p>
//                                                 </div>
//                                             </span>
//                                         </div>
//                                         <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
//                                             <div className="rate p-0 d-flex align-items-end mt-4">
//                                                 <span className="font-normal">5</span>
//                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                 <label htmlFor="star1" title="text">1 star</label>
//                                             </div>
//                                             <span className="font-normal">12</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="align-items-end d-flex justify-content-end">
//                                         <div className="footer-social d-flex gap-0">
//                                             <Link to="https://www.linkedin.com/company/hire-tech-team/">
//                                                 {/* <a href="https://www.linkedin.com/company/hire-tech-team/"> */}
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
//                                                 </svg>
//                                             </Link>
//                                             <Link to="https://twitter.com/HIRETECHTEAM">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
//                                                 </svg></Link>
//                                             <Link to="https://vimeo.com">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
//                                                 </svg></Link>



//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>


//                             <div className="row d-flex justify-content-between">
//                                 <h4 className="text-dark fw-bolder mt-4">Integrating and enabling the Chat module in ABP Commercial</h4>
//                                 <h5 className="fw-bolder my-4">Introduction</h5>
//                                 <p className="details">This module implements real time messaging between users for an application.</p>
//                                 <p className="details">ABP Commercial chat module provides chat features and increases your user experience & user engagement by facilitating users to connect with other users.</p>
//                                 <p className="details">Let us see how you can integrate the ABP chat module.</p>
//                                 <h5 className="fw-bolder my-4">Let’s begin </h5>
//                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} >This document assumes that you already created an ABP Web application with Angular as UI framework and Entity Framework Core as database provider. </p>
//  <p className={`details ${!isLoggedIn && 'opacity-25'}`} > </p>

//                                             {!isLoggedIn &&
//                                                 <div className="row bg-light mb-5">
//                                                     <div className="col-lg-4">
//                                                         <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
//                                                     </div>
//                                                     <div className="col-lg-8">
//                                                         <h6 className="mt-3">To read the full post,</h6>
//                                                         <h4 className="fw-bolder">Sign up to AAA Academy</h4>
//                                                         <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
//                                                         <Link to={`/signUp`}>
//                                                             <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
//                                                         </Link>
//                                                         <p className="mt-2">Already have an account?
//                                                             <Link to="/login" className="text-decoration-none color"> Sign in
//                                                             </Link> </p>
//                                                     </div>
//                                                 </div>}
//                                             {isLoggedIn && <>
                             
//                                 <h5 className="fw-bolder my-4">Running the ABP application </h5>
//                                 <img className="py-3" src={runningABPApp} alt="Running the ABP application " />
//                                 <h5 className="fw-bolder my-4">Installation of abp suite </h5>
//                                 <p className="details">Go to command prompt and type abp suite, which will open the abp suite localhost.</p>
//                                 <img className="py-3" src={installationOfAbpSuite} alt="Installation Of Abp Suite" />
//                                 <p className="details">1. It will list out recently used solution or else you can add your existing solution.  </p>
//                                 <img className="py-3" src={addAnAbpSuite} alt=" It will list out recently used solution or else you can add your existing solution.  " />
//                                 <p className="details">2. Verify that your project is selected as current solution on top right corner of the abp suite screen. Select Modules from top menu, which will list out all available modules of abp suite.  </p>
//                                 <img className="py-3" src={abpSuiteTemplate} alt="Verify that your project is selected as current solution on top right corner of the abp suite screen. Select Modules from top menu, which will list out all available modules of abp suite" />
//                                 <p className="details">From here you can install or update new packages, find the module named chat.</p>
//                                 <p className="details">3. Click on Add as Package of chat module.  </p>
//                                 <img className="py-3" src={chat} alt="chat" />
//                                 <img className="py-3" src={moduleInstalling} alt="Module Installing" />
//                                 <p className="details">This will install chat module as a nuget package to your solution</p>
//                                 <p className="details">4. This will add following references </p>
//                                 <img className="py-3" src={followingReferences} alt="Following References" />
//                                 <img className="py-3" src={bookStoreApplicationModuleClass} alt="Book Store Application Module Class" />
//                                 {/* commented code for {} error */}
//                                 <p className="details">in ProjectName ApplicationModule</p>
//                                 <p className="details">The Volo.Chat.SignalR package will be added in :- ProjectName.HttpApi.Host project, {ProjectName}.HttpApi.Host project</p>
//                                 <p className="details">5. SignalR Access Token Configuration for Angular Projects  </p>
//                                 <p className="details">Here is a sample configuration for this:</p>
//                                 <p className="details">In ProjectName HttpApiHostModule</p>
//                                 <CodeSnippet codeString={appUse} />
//                                 <p className="details">6. Enable chat feature to an Edition  </p>
//                                 <img className="py-3" src={enableChatFeature} alt="Enable Chat Feature" />
//                                 <p className="details">7. Create a new tenant and assign chat feature enabled edition to it  </p>
//                                 <img className="py-3" src={assignFeature} alt=" Create a new tenant and assign chat feature enabled edition to it  " />
//                                 <p className="details">Also enable chat feature to the newly created tenant</p>
//                                 <img className="py-3" src={features} alt="Also enable chat feature to the newly created tenant" />
//                                 <p className="details">8.Login to the tenant user, create new User from Administration/Identity Management/Users.
//                                     Enable chat permission to that user as follows</p>
//                                 <img className="py-3" src={usersPermissions} alt="Login to the tenant user, create new User from Administration/Identity Management/Users.  
// Enable chat permission to that user as follows"/>
//                                 <img className="py-3" src={permissionsForChat} alt="Permissions For Chat" />
//                                 <p className="details">9. You will observe a chat icon on the tool bar. From there you can use the chat feature  </p>
//                                 <img className="py-3" src={message} alt=" You will observe a chat icon on the tool bar. From there you can use the chat feature " />
//                                 <img className="py-3" src={mainDash} alt="mainDash" />
//                                 <p className="details"></p>
//                                 <h5 className="fw-bolder my-4">Conclusion</h5>
//                                 <p className="details">This article provides the steps to integrate the ABP commercial chat module for real time messaging between users in the application. It also provides a page for searching the users in an application for new conversations, a contact list for recent conversations, and a total unread message count badge on the menu icon and lazy loaded conversations.</p></>}
//                             </div>
//                             <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
//                             <div className="py-3">
//                                 <h4 className="text-dark fw-bolder">Featured Comments</h4>
//                                 <div className="d-flex align-items-center">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>

//                                 <div className="d-flex align-items-center">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>

//                                 <div className="d-flex align-items-center mx-5">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>
//                             </div>
//                             <div className="row aarw">
//                                 <div className="col-12">
//                                     <h5 className="related-title">More Articles from Joe Thomson</h5>
//                                 </div>
//                                 <div className="col-12">
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>

//                                     </div>
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
//                             <div className="row d-flex justify-content-between py-3">
//                                 <div className="col-md-6">
//                                     <div className="d-flex align-items-center">
//                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                 <div>
//                                                     <span>Kirti Kulkarni</span>
//                                                 </div>
//                                             </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="align-items-end d-flex justify-content-end">

//                                         <div className="footer-social d-flex gap-0">


//                                             <Link to="https://www.linkedin.com/company/hire-tech-team/">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
//                                                 </svg>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <h6 className="about-author">ABOUT THE AUTHOR</h6>
//                             <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
//                             <div>
//                                 <h5 className="fw-medium mb-3">Latest Articles</h5>
//                                 <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
//                                     <div className="card mb-3 px-3 py-2">
//                                         <div className="row g-0">
//                                             <div className="col-md-8">
//                                                 <h5 className="card-title">
//                                                     <div className="d-flex align-items-center">
//                                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                                 <div>
//                                                                     <span className="fs-6">Kirti Kulkarni</span>
//                                                                 </div>
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </h5>
//                                                 <p className="card-text details">Deploying ABP angular application to Azure and App Insights integration</p>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card-body">
//                                                     <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Link>
//                                 <Link className="nav-link" to="/blog/usingWebhooksAbpCommercial">
//                                     <div className="card mb-3 px-3 py-2">
//                                         <div className="row g-0">
//                                             <div className="col-md-8">
//                                                 <h5 className="card-title">
//                                                     <div className="d-flex align-items-center">
//                                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                                 <div>
//                                                                     <span className="fs-6">Kirti Kulkarni</span>
//                                                                 </div>
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </h5>
//                                                 <p className="card-text details">Using Webhooks in ABP Commercial</p>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card-body">
//                                                     <img src={webhooksImg} className="img-fluid" alt="Using Webhooks in ABP Commercial" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Link>
//                                 <Link className="nav-link" to="/blog/createDatabasePerTenantSQL">
//                                     <div className="card mb-3 px-3 py-2">
//                                         <div className="row g-0">
//                                             <div className="col-md-8">
//                                                 <h5 className="card-title">
//                                                     <div className="d-flex align-items-center">
//                                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                                 <div>
//                                                                     <span className="fs-6">Kirti Kulkarni</span>
//                                                                 </div>
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </h5>
//                                                 <p className="card-text details">Create database per tenant in SQL Elastic Pool with ABP Commercial</p>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card-body">
//                                                     <img src={createDatabaseImg} className="img-fluid" alt="Create database per tenant in SQL Elastic Pool with ABP Commercial" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Link>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </section>
//         </Container>
        
//       </div>
  
        
      
//     </Layout>
    
  );
};

export default SamplePage;
