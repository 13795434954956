import React from 'react';
import './Quote.css';
import bannerfooter from "../../../static/AAA-images/banner_footer.png"
import azureLogo from "../../../static/AAA-images/AzureLogo.png"
import abpLogo from "../../../static/AAA-images/AbpLogo.png"
import anzLogo from "../../../static/AAA-images/AnzLogo.png"

const Quote = (props) => {
  
  return (
    <>
    
    <section>

            <div className="cards__section">
                <div className="container">
                    <div className="row d-flex align-items-center py-xxl-0 py-xl-0 py-lg-0 py-5">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12">
                            <img src={bannerfooter} alt="Solution Showcase that reveals implementation" width="100%" className="text-center" />
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12">
                            <p className="text-white pt-4 ad ps-xxl-4 ps-xl-4 ps-lg-4 ps-0 pe-xxl-5 pe-xl-5 pe-lg-5 pe-md-2 pe-0">A solution showcase that reveals implementation details of business requirements using cloud and industry leading web application development platforms.</p>
                            <div className="d-flex align-items-center gap-5 pt-4 px-xxl-4 px-xl-4 px-lg-4 pe-4">
                                <img src={azureLogo} alt="azure logo" className="tech-cards-img" />
                                <img src={abpLogo} alt="abpio logo" className="tech-cards-img" />
                                <img src={anzLogo} alt="Asp Net Zero" className="tech-cards-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </>
  );
};

export default Quote;
