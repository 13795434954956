import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import { withPrefix } from 'gatsby';
// import {
//   validateEmail,
//   validateStrongPassword,
//   isEmpty,
// } from '../helpers/general';
// import * as styles from './signup.module.css';
import googleLogo from "../../static/AAA-images/Google.svg"
import linkedinLogo from "../../static/AAA-images/linkedin.svg"
import msLogo from "../../static/AAA-images/login-microsoft.svg"
import loginArrow from "../../static/AAA-images/login-arrow.svg"
import './login.css';

// import AttributeGrid from '../components/AttributeGrid/AttributeGrid';
// import Layout from '../components/Layout/Layout';
// import FormInputField from '../components/FormInputField/FormInputField';
// import Button from '../components/Button';

const SignupPage = (props) => {
  // const initialState = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   password: '',
  // };

  // const errorState = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   password: '',
  // };

  // const [signupForm, setSignupForm] = useState(initialState);
  // const [errorForm, setErrorForm] = useState(errorState);

  // const handleChange = (id, e) => {
  //   const tempForm = { ...signupForm, [id]: e };
  //   setSignupForm(tempForm);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let validForm = true;
  //   const tempError = { ...errorState };

  //   if (isEmpty(signupForm.firstName) === true) {
  //     tempError.firstName = 'Field required';
  //     validForm = false;
  //   }

  //   if (isEmpty(signupForm.lastName) === true) {
  //     tempError.lastName = 'Field required';
  //     validForm = false;
  //   }

  //   if (validateEmail(signupForm.email) !== true) {
  //     tempError.email =
  //       'Please use a valid email address, such as user@example.com.';
  //     validForm = false;
  //   }

  //   if (validateStrongPassword(signupForm.password) !== true) {
  //     tempError.password =
  //       'Password must have at least 8 characters, 1 lowercase, 1 uppercase and 1 numeric character.';
  //     validForm = false;
  //   }

  //   if (validForm === true) {
  //     setErrorForm(errorState);
  //     navigate('/accountSuccess');
  //     window.localStorage.setItem('key', 'sampleToken');
  //     //create account endpoint
  //   } else {
  //     setErrorForm(tempError);
  //   }
  // };

  return (
    // <Layout disablePaddingBottom={true}>
    //   <div className={styles.root}>
    //     <div className={styles.signupFormContainer}>
    //       <h1 className={styles.title}>Create Account</h1>
    //       <span className={styles.subtitle}>
    //         Please enter your the information below:
    //       </span>
    //       <form
    //         noValidate
    //         className={styles.signupForm}
    //         onSubmit={(e) => handleSubmit(e)}
    //       >
    //         <FormInputField
    //           id={'firstName'}
    //           value={signupForm.firstName}
    //           handleChange={(id, e) => handleChange(id, e)}
    //           type={'input'}
    //           labelName={'First Name'}
    //           error={errorForm.firstName}
    //         />

    //         <FormInputField
    //           id={'lastName'}
    //           value={signupForm.lastName}
    //           handleChange={(id, e) => handleChange(id, e)}
    //           type={'input'}
    //           labelName={'Last Name'}
    //           error={errorForm.lastName}
    //         />

    //         <FormInputField
    //           id={'email'}
    //           value={signupForm.email}
    //           handleChange={(id, e) => handleChange(id, e)}
    //           type={'email'}
    //           labelName={'Email'}
    //           error={errorForm.email}
    //         />

    //         <FormInputField
    //           id={'password'}
    //           value={signupForm.password}
    //           handleChange={(id, e) => handleChange(id, e)}
    //           type={'password'}
    //           labelName={'Password'}
    //           error={errorForm.password}
    //         />

    //         <Button fullWidth type={'submit'} level={'primary'}>
    //           create account
    //         </Button>
    //         <span className={styles.reminder}>Have an account?</span>
    //         <Button
    //           type={'button'}
    //           onClick={() => navigate('/login')}
    //           fullWidth
    //           level={'secondary'}
    //         >
    //           log in
    //         </Button>
    //       </form>
    //     </div>

    //     <div className={styles.attributeGridContainer}>
    //       <AttributeGrid />
    //     </div>
    //   </div>
    // </Layout>

    <>
    
    <section className="login-background" style={{ backgroundImage: `url(${withPrefix('AAA-images/login-bg.png')})` }}>
            <div className="align-items-center container d-flex justify-content-center vh-100">
                <div className="w-32">          
                            <div className="bg-white row rounded-3  w-22">

                                <div className="col-md-12">
                                    <div className="py-5 px-3">
                                        <div className="pb-4">
                                            <div href="#" className="signup-arrow cmn-backarrow">
                                                <div >
                                                  <Link to={`/`}>
                                                    <img src={loginArrow} height="40px" width="40px" />
                                                  </Link>
                                                </div>
                                                <div>
                                                  <h6 className="fw-bolder fs-3">Sign up</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <form className='subscribe'>
                                          <div className='row'>
                                              <div className='col-lg-6 mb-3'>
                                                <label for="firstName" className="form-label">First Name</label>
                                                <input type="fname" className="form-control" id="firstName" placeholder='Natasha' />
                                              </div>
                                              <div className='col-lg-6 mb-3'>
                                                <label for="lastName" className="form-label">Last Name</label>
                                                <input type="lname" className="form-control" id="lastName" placeholder='James' />
                                              </div>
                                          </div>
                                            <div className="mb-3">
                                                <label for="exampleInputEmail1" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='natasha.james@mail.com' />
                                            </div>
                                            <div className='mb-4'>
                                                <label for="exampleInputPassword1" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;" />
                                            </div>
                                            <div className="text-center pt-3">
                                                <button type="submit" className="btn button-primary text-white">Sign Up</button>
                                            </div>
                                            <div className="row mb-4 pt-2">
                                                <div className="col-md-12 text-center">
                                                    <Link to={`/login`} className="color mx-2 text-decoration-none small">Already have an account?</Link>
                                                </div>
                                                <div className='col-md-12 text-center pt-3'>
                                                    <small>Or sign up with</small>
                                                </div>
                                            </div>
                                            <div className="text-center pt-2 pb-4 d-flex gap-3 justify-content-center">
                                                <img src={googleLogo} height="40px" width="40px" className="mx-2 p-1" />     
                                                <img src={msLogo} height="40px" width="40px" className="mx-2 p-1" />
                                                <img src={linkedinLogo} height="40px" width="40px" className="mx-2 p-1" />
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </section>
    
    </>
  );
};

export default SignupPage;
