import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import abpElsa from "../../../static/AAA-images/abpElsa.png"
import abpSuite from "../../../static/AAA-images/abpSuite.png"
import createNewSolution from "../../../static/AAA-images/createNewSolution.png"
import usingAbpCli from "../../../static/AAA-images/usingAbpCli.png"
import successfullyCreated from "../../../static/AAA-images/successfullyCreated.png"
import setAsStartupProject from "../../../static/AAA-images/setAsStartupProject.png"
import startedDatabaseMigrations from "../../../static/AAA-images/startedDatabaseMigrations.png"
import bookStoreAPI from "../../../static/AAA-images/bookStoreAPI.png"
import loginUser from "../../../static/AAA-images/loginUser.png"
import dashboard from "../../../static/AAA-images/dashboard.png"
import createWorkflow from "../../../static/AAA-images/createWorkflow.png"
import signalImage from "../../../static/AAA-images/signalImage.gif"
import signalReceived from "../../../static/AAA-images/signalReceived.gif"
import fileManagementImg from "../../../static/AAA-images/fileManagementImg.png"
import pdfTronImg from "../../../static/AAA-images/pdfTronImg.jpg"
import customLoginPageImg from "../../../static/AAA-images/customLoginPageImg.png"
import { useState, useEffect } from "react";

import relatedblog1 from "../../../static/AAA-images/Implement-Single-Sign-On-with-ABP-commercial.png"
import relatedblog2 from "../../../static/AAA-images/gradient.png"
import relatedblog3 from "../../../static/AAA-images/Integrating-and-enabling-the-Chat-module-in-ABP-Commercial.png"

const SamplePage = (props) => {
    const dotnetInstall = `dotnet tool install -g Volo.Abp.Cli`;
    const dotnetTool = `dotnet tool update -g Volo.Abp.Cli`;
    const abpLogin = `abp login `;
    const abpSuiteInstall = `abp suite install`;
    const abpSuiteUpdate = `abp suite update `;
    const connectionStrings = `"ConnectionStrings": {
        "Default": "Server=localhost;Database=elsaangular;Trusted_Connection=True"
    }`;
    const dotnetRun = `dotnet run`;
    const yarn = `yarn`;
    const yarnStart = `yarn start`;
    const ConfigureSwagger = `private static void ConfigureSwagger(ServiceConfigurationContext context, IConfiguration configuration)
    {
     context.Services.AddAbpSwaggerGenWithOAuth(
     configuration["AuthServer:Authority"],
      new Dictionary<string, string>
       {
         {"elsaangular", "elsaangular API"}
       },
         options =>
       {
         options.SwaggerDoc("v1", new OpenApiInfo { Title = "elsaangular API", Version = "v1" });
         options.DocInclusionPredicate((docName, description) => true);
         options.CustomSchemaIds(type => type.FullName);
 
     //Add the below line
 
         options.ResolveConflictingActions(apiDescriptions => apiDescriptions.First());
       });
    }`;
    const configureElsa = `private static void ConfigureElsa(ServiceConfigurationContext context, IConfiguration configuration)
    {
     context.Services
     .AddElsa(elsa => elsa
     .UseEntityFrameworkPersistence(ef =>
     DbContextOptionsBuilderExtensions.UseSqlServer(ef,
     context.Services.GetConfiguration().GetConnectionString("Default")))
      .AddHttpActivities()
      .AddQuartzTemporalActivities()
      .AddJavaScriptActivities()
      .AddEmailActivities()
      .AddWorkflowsFrom()
      .AddActivitiesFrom()
     );
 
     // Elsa API endpoints.
      context
      .Services
      //.AddWorkflowContextProvider() //use this when using custom context provide
      .AddElsaApiEndpoints();
      context.Services.Configure(options => { options.UseApiBehavior = false; }); //Add these lines 
      context.Services
      .AddCors(cors =>
      cors
      .AddDefaultPolicy(policy => policy
      .AllowAnyHeader()
      .AllowAnyMethod()
      .AllowAnyOrigin()
      .WithExposedHeaders("Content-Disposition")));//Add these lines 
    } `;
    const ConfigureServices = `public override void ConfigureServices(ServiceConfigurationContext context)
    {
     //We can disable this setting in production to avoid any potential security risks.
     Microsoft.IdentityModel.Logging.IdentityModelEventSource.ShowPII = true;
  
     var configuration = context.Services.GetConfiguration();
     var hostingEnvironment = context.Services.GetHostingEnvironment();
  
     ConfigureUrls(configuration);
     ConfigureBundles();
     ConfigureConventionalControllers();
     ConfigureAuthentication(context, configuration);
     ConfigureImpersonation(context, configuration);
     ConfigureSwagger(context, configuration);
     ConfigureElsa(context, configuration); // Add this line
     ConfigureLocalization();
     ConfigureVirtualFileSystem(context);
     ConfigureCors(context, configuration);
     ConfigureExternalProviders(context);
     ConfigureHealthChecks(context);
  
     // Add these two lines below
  
     PreConfigure(options => { options.UseHybridSerializer = false; }); 
     Configure(options => { options.AutoValidateFilter = type => type.Assembly != typeof(Elsa.Server.Api.Endpoints.WorkflowRegistry.Get).Assembly; }); line
  
    }`;
    const OnApplicationInitialization = `public override void OnApplicationInitialization(ApplicationInitializationContext context)
    {
     var app = context.GetApplicationBuilder();
     var env = context.GetEnvironment();
  
     if (env.IsDevelopment())
      {
       app.UseDeveloperExceptionPage();
      }
  
     app.UseAbpRequestLocalization();
  
     if (!env.IsDevelopment())
      {
       app.UseErrorPage();
      }
  
     app.UseStaticFiles();
  
  
     if (MultiTenancyConsts.IsEnabled)
      {
       app.UseMultiTenancy();
      }
  
     app.UseUnitOfWork();
     app.UseIdentityServer();
     app.UseAuthorization();
     app.UseSwagger();
     app.UseAbpSwaggerUI(options =>
      {
       options.SwaggerEndpoint("/swagger/v1/swagger.json", "elsaangular API");
       var configuration = context.ServiceProvider.GetRequiredService();
       options.OAuthClientId(configuration["AuthServer:SwaggerClientId"]);
       options.OAuthClientSecret(configuration["AuthServer:SwaggerClientSecret"]);
      });
       app.UseAuditing();
       app.UseAbpSerilogEnrichers();
       app.UseHttpActivities();  /// Add this line here
       app.UseConfiguredEndpoints();
      }`;
    const ServiceConfigurationContext = `public override void ConfigureServices(ServiceConfigurationContext context)
    {
     PreConfigure(options => { options.UseHybridSerializer = false; }); // Add this line
   
     Configure(options =>
       {
         options.IsEnabled = MultiTenancyConsts.IsEnabled;
       });
   
     Configure(options =>
       {
         options.Languages.Add(new LanguageInfo("ar", "ar", "العربية", "ae"));
   
         options.Languages.Add(new LanguageInfo("es", "es", "Español", "es"));
       });
   
     #if DEBUG
         context.Services.Replace(ServiceDescriptor.Singleton<IEmailSender, NullEmailSender>());
     #endif
     },`;
    const ProjectSdk = `<Project Sdk="Microsoft.NET.Sdk.Web">
    <Import Project="..\..\common.props" />
    <PropertyGroup>
   <TargetFramework>net6.0</TargetFramework>
   <AspNetCoreHostingModel>InProcess</AspNetCoreHostingModel>
   <RootNamespace>elsaangular</RootNamespace>
     <PreserveCompilationReferences>true</PreserveCompilationReferences>
    </PropertyGroup>
    <ItemGroup>
   <PackageReference Include="AspNetCore.HealthChecks.UI" Version="6.0.1-rc2.4" />
   <PackageReference Include="AspNetCore.HealthChecks.UI.Client" Version="6.0.1-rc2.4" />
   <PackageReference Include="AspNetCore.HealthChecks.UI.InMemory.Storage" Version="6.0.1-rc2.4" />
   <PackageReference Include="Elsa" Version="2.5.0" />
   <PackageReference Include="Elsa.Activities.Email" Version="2.5.0" />
   <PackageReference Include="Elsa.Activities.Http" Version="2.5.0" />
   <PackageReference Include="Elsa.Activities.Temporal.Quartz" Version="2.5.0" />
   <PackageReference Include="Elsa.Persistence.EntityFramework.SqlServer" Version="2.5.0" />
   <PackageReference Include="Elsa.Server.Api" Version="2.5.0" />
   <PackageReference Include="Elsa.Designer.Components.Web" Version="2.5.0" />
    
   <PackageReference Include="Serilog.AspNetCore" Version="4.0.0" />
   <!--Added Line-->
   <PackageReference Include="Serilog.Sinks.Async" Version="1.5.0" />
   <!--Added Line-->
   <PackageReference Include="Microsoft.AspNetCore.Authentication.Google" Version="6.0.0" />
   <PackageReference Include="Microsoft.AspNetCore.Authentication.MicrosoftAccount" Version="6.0.0" />
   <PackageReference Include="Microsoft.AspNetCore.Authentication.Twitter" Version="6.0.0" />
   <PackageReference Include="Volo.Abp.AspNetCore.MultiTenancy" Version="5.2.0" />
   <PackageReference Include="Volo.Abp.Autofac" Version="5.2.0" />
   <PackageReference Include="Volo.Abp.AspNetCore.Serilog" Version="5.2.0" />
   <PackageReference Include="Volo.Abp.Swashbuckle" Version="5.2.0" />
   <PackageReference Include="Volo.Abp.Account.Pro.Public.Web.IdentityServer" Version="5.2.0" />
   <PackageReference Include="Volo.Abp.Account.Pro.Public.Web.Impersonation" Version="5.2.0" />
   <PackageReference Include="Volo.Abp.AspNetCore.Mvc.UI.Theme.Lepton" Version="5.2.0" />
    </ItemGroup>
    <ItemGroup>
   <ProjectReference Include="..\elsaangular.Application\elsaangular.Application.csproj" />
   <ProjectReference Include="..\elsaangular.EntityFrameworkCore\elsaangular.EntityFrameworkCore.csproj" />
   <ProjectReference Include="..\elsaangular.HttpApi\elsaangular.HttpApi.csproj" />
    </ItemGroup>
    <ItemGroup>
   <Compile Remove="Logs\**" />
   <Content Remove="Logs\**" />
   <EmbeddedResource Remove="Logs\**" />
   <None Remove="Logs\**" />
    </ItemGroup>
   </Project>`;
    const DOCTYPE = `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>Elsa Workflows</title>
    <link rel="icon" type="image/png" sizes="32x32" href="{serviceurl}/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/images/favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="16x16" href="{serviceurl}/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/images/favicon-16x16.png">
    <link rel="stylesheet" href="{serviceurl}/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/fonts/inter/inter.css">
    <link rel="stylesheet" href="{serviceurl}/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/elsa-workflows-studio.css">
    <script src="{serviceurl}/_content/Elsa.Designer.Components.Web/monaco-editor/min/vs/loader.js"></script>
    <script type="module" src="{serviceurl}/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/elsa-workflows-studio.esm.js"></script></head>
    <body className="h-screen" style="background-size: 30px 30px; background-image: url({serviceurl}/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/images/tile.png); background-color: #FBFBFB;">
    <elsa-studio-root server-url="{serviceurl}" monaco-lib-path="{serviceurl}/_content/Elsa.Designer.Components.Web/monaco-editor/min">
    <elsa-studio-dashboard></elsa-studio-dashboard>
    </elsa-studio-root>
    </body>
    </html>`;

    const getDashboard = `getDashboard() {
        const url = window.location.origin + '/assets/statichtml/workflow-designer.html';
         const request = this.http.get(url, {
           headers: new HttpHeaders({
               Accept: 'text/plain'
           }), responseType: 'text'
         });
           
           return request;
       }   `;

    const workflow = `<div id="workflow-dashboard-section"></div>`;
    const importt = ` import { DOM_STRATEGY, CROSS_ORIGIN_STRATEGY, StyleLoadingStrategy, ScriptLoadingStrategy, LazyLoadService } from '@abp/ng.core';
       import { Component, OnInit } from '@angular/core';
       import { forkJoin } from 'rxjs';
       import { environment } from 'src/environments/environment';
       import { WorkflowService } from './workflow.service';
       
       const domStrategy = DOM_STRATEGY.AppendToHead();
       
       const crossOriginStrategy = CROSS_ORIGIN_STRATEGY.None(
       );
       
       const loadingStrategy1 = new StyleLoadingStrategy(
         environment.apis.default.url + '/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/images/favicon-32x32.png',
         domStrategy,
         crossOriginStrategy,
       );
       const loadingStrategy2 = new StyleLoadingStrategy(
         environment.apis.default.url + '/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/images/favicon-16x16.png',
         domStrategy,
         crossOriginStrategy,
       );
       const loadingStrategy3 = new StyleLoadingStrategy(
         environment.apis.default.url + '/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/assets/fonts/inter/inter.css',
         domStrategy,
         crossOriginStrategy,
       );
       
       const loadingStrategy5 = new ScriptLoadingStrategy(
         environment.apis.default.url + '/_content/Elsa.Designer.Components.Web/monaco-editor/min/vs/loader.js',
         domStrategy,
         crossOriginStrategy,
       );
       const loadingStrategy6 = new ScriptLoadingStrategy(
         environment.apis.default.url + '/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/elsa-workflows-studio.esm.js',
         domStrategy,
         crossOriginStrategy,
       );
       
       @Component({
         selector: 'app-workflow',
         templateUrl: './workflow.component.html',
         styleUrls: ['./workflow.component.scss']
       })
       export class WorkflowComponent implements OnInit {
       
         private stylesLoaded$ = forkJoin([
       this.lazyLoad.load(
         loadingStrategy1,
       ),
       this.lazyLoad.load(
         loadingStrategy2,
       ),
       this.lazyLoad.load(
         loadingStrategy3,
       )
         ]
         );
       
         private scriptsLoaded$ = forkJoin(
       [this.lazyLoad.load(
         loadingStrategy5
       )]
         );
       
         scriptsAndStylesLoaded$ = forkJoin([this.stylesLoaded$, this.scriptsLoaded$]);
       
         constructor(private serviceWorkflow: WorkflowService, private lazyLoad: LazyLoadService) { }
       
         ngOnInit(): void {
       this.getDashboard();
         }
       
         getDashboard() {
       this.serviceWorkflow.getDashboard().subscribe(async res => {
         if (!res) { return; }
         this.scriptsAndStylesLoaded$.subscribe(element => {
       setTimeout(() => {
         const finalElsa = res.replace(/{serviceurl}/g, environment.apis.default.url);
         (document.querySelector('#workflow-dashboard-section')).innerHTML = finalElsa;
       }, 300);
         });
       });
         }
       
       }`;
    const script = ` const script = document.createElement('script');
       script.src = environment.apis.default.url + '/_content/Elsa.Designer.Components.Web/elsa-workflows-studio/elsa-workflows-studio.esm.js';
       script.type = 'module';
       document.head.appendChild(script);`;
    const path = `{
        path: 'workflow-dashboard',
        component: WorkflowComponent
    },`;
    const post = `POST /v2/documents HTTP/1.1
       Host: localhost: 44364
       Content-Type: application/json 
       {
           "Id": "4",
           "Author": {
           "Name": "Hannah",
           "Email": "hannah@gmail.com"
           },
           "Body": "This is another sample document."
       } `;
       

  

  return (
    <Layout>
    <div className="">
        <Container>
            <div className="">
                <section className='mb-5'>
                    <div className="container mt-5">
                        <div className="row ">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                <div className="row d-flex justify-content-between py-2">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                        <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                            <p className="mb-0 small text-muted"> Jun 14, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                            <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                                <div className="rate p-0">
                                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                                </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                      
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div className="">

                                            <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                <Link to="https://www.linkedin.com/company/hire-tech-team/">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </Link>
                                                <Link to="https://twitter.com/HIRETECHTEAM">
                                                 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                    </svg></Link>
                                                <Link to="https://vimeo.com">
                                                  
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                    </svg></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between">

                                <h4 className="text-dark fw-bolder mt-5">Integrating Elsa .NET Workflows with ABP Commercial</h4>
                                 <img className="py-3 my-4" src={abpElsa} alt="ABP ELSA" />
                                 <p className="details mb-5">In this article we have demonstrated about how to integrate .NET 5 Open-Source Workflow framework Elsa with ABP Commercial application.</p>
                                 <h5 className="fw-bolder mb-2">What is Elsa .Net? </h5>
                                 <p className="details mb-2" > Elsa is an open-source .NET Standard Workflow framework. You can check it out at  <Link to="https://elsa-workflows.github.io/elsa-core/" className="text-decoration-none link-primary">https://elsa-workflows.github.io/elsa-core/</Link> </p>
                                 <p className="details mb-5" > Let us look at some of the key features of the Elsa .Net platform.</p>

                                             
                              
                                <h5 className="fw-bolder mb-2">Elsa .Net is very Simple and Fast</h5>
                                <p className="details mb-5">The library is very compact and easy to use, at the same time, it is easy to extend through custom activities. Triggering event makes the corresponding workflow start automatically based on the event / recovery.</p>
                                <h5 className="fw-bolder mb-2">Elsa Supports long-running workflows </h5>
                                <p className="details mb-5">When the workflow is executed and needs to be done, for example, after user input, the workflow will stop, Keep, and run out of memory, until it can recover. It could be a few seconds later, a few minutes, few hours, days or even years.</p>
                                <h5 className="fw-bolder mb-2">Associate workflow with application-specific data  </h5>
                                <p className="details mb-2">This is a key requirement of a long-running workflow. Store workflows in a file-based format, so we can make it a part of source control.</p>
                                <p className="details mb-5">When we don't want the workflow to be part of source control, we can store it in a database based on the Web Version of the process designer. Whether it's storing workflow in a file system or in a database, whether to host the designer online or only on the local computer, need to be able to edit workflow online.</p>
                                <h5 className="fw-bolder mb-2">Use expressions to configure workflow activities </h5>
                                <p className="details mb-2">Usually, the information processed by workflow is dynamic in nature, Activities need a way to interact with that information. Workflow expressions support such scenarios.</p>
                                <p className="details mb-2">Through application-specific activities, Support custom storage and script engine for an extension, call another workflow. This allows us to invoke reusable application logic from various workflow processes. Like from C＃ it's the same as calling general functions. View and analyze the workflow instances executed. View the path taken by the workflow, Runtime state, Where the failure occurs, and compensate for the failed workflow.</p>
                                <p className="details mb-5">The workflow designer is embedded in our own dashboard application We can choose to create a workflow host that runs all the application logic , we can also choose to host the workflow runtime in a single microservice .</p>
                                <h5 className="fw-bolder mb-2">Separation of concerns </h5>
                                <p className="details mb-2">Workflow core library, the runtime and designer are completely separated in design. That is, when the workflow host should not rely on the web the designer. For example, This allows us to implement desktop-bas   designers, or not use the designer at all, use YAML file. Last, Hosts only need workflow definitions and access to persistent storage. Support local or cloud management because Elsa It's just a set of NuGet software package.</p>
                                <p className="details mb-5">Elsa with a separate, reusable HTML5 workflow designer web component, we can embed it in our own HTML on the web page. The designer runs entirely on the client-side and has a wealth of JavaScript API, The API allows us to define custom plug-ins to extend their functionality.</p>
                                <h5 className="fw-bolder mb-2">Getting Started with ABP commercial Angular application</h5>
                                <p className="details mb-2">Before creating project in abp.io we must purchase an active ABP commercial account</p>
                                <p className="details mb-5">We are using <span className="fw-bolder">Angular</span> as the UI framework and <span className="fw-bolder">Entity Framework Core as the database provider.</span></p>
                                <h5 className="fw-bolder mb-2">Setup our development environment</h5>
                                <p className="details mb-5">First things first! Let's setup our development environment before creating the first project.</p>
                                <h5 className="fw-bolder mb-2">Pre-requirements</h5>
                                <p className="details mb-2">The following tools should be installed on our development machine:</p>
                                <ul className="ms-3">
                                    <li> <Link to="https://visualstudio.microsoft.com/vs/" className="text-decoration-none link-primary">Visual Studio 2022</Link> for Windows / <Link to="https://visualstudio.microsoft.com/vs/mac/" className="text-decoration-none link-primary">Visual Studio for Mac. 1</Link></li>
                                    <li> <Link to="https://dotnet.microsoft.com/en-us/download/dotnet" className="text-decoration-none link-primary">.NET Core 5.0+</Link></li>
                                    <li><Link to="https://nodejs.org/en" className="text-decoration-none link-primary">Node v12 or v14</Link></li>
                                    <li><Link to="https://classic.yarnpkg.com/en/docs/install#windows-stable" className="text-decoration-none link-primary">Yarn v1.20+ (not v2)2 or npm v6+ (already installed with Node)</Link></li>
                                </ul>
                                <p className="details mb-2"><Link to="https://redis.io/" className="text-decoration-none link-primary">Redis </Link> (as the <Link to="https://docs.abp.io/en/commercial/latest" className="text-decoration-none link-primary">distributed cache</Link>) is required if we select the Public website option.</p>
                                <p className="details text-danger mb-2">We can use another editor instead of Visual Studio if it supports .NET Core and ASP.NET Core. </p>
                                <p className="details text-danger mb-5">Yarn v2 works differently and is not supported. </p>
                                <h5 className="fw-bolder mb-2">Install the ABP CLI</h5>
                                <p className="details mb-2">ABP CLI is a free & open-source tool for <Link to="https://abp.io/" className="text-decoration-none link-primary"> the ABP framework.</Link>  It is also used for ABP Commercial application development.</p>
                                <p className="details mb-5">First, we need to install the ABP CLI using the following command:</p>
                                <CodeSnippet codeString={dotnetInstall} />
                                <p className="details py-4">If we’ve already installed, we can update it using the following command:</p>
                                <CodeSnippet codeString={dotnetTool} />
                                <h5 className="fw-bolder mb-2 pt-5">Login to account</h5>
                                <p className="details mb-5 pb-3">In order to use ABP Commercial features, we need to login our account using the ABP CLI</p>
                                <CodeSnippet codeString={abpLogin} />
                                <h5 className="fw-bolder mb-2 pt-5">Install the ABP Suite</h5>
                                <p className="details mb-2">ABP Suite is an application aims to assist us on our development.</p>
                                <p className="details mb-5">First, we need to install the ABP Suite:</p>
                                <CodeSnippet codeString={abpSuiteInstall} />
                                <p className="details py-4">If we've already installed, we can update it:</p>
                                <CodeSnippet codeString={abpSuiteUpdate} />
                                <img className="py-3 mt-5" src={abpSuite} alt="Abp Suite" />
                                <p className="details py-4">Click to the  <span className="fw-bolder">Create a new solution</span> button and fill the modal form:</p>
                                <img className="py-3" src={createNewSolution} alt="Create New Solution" />
                                <h5 className="fw-bolder mb-2 pt-5">Using the ABP CLI to create a new project</h5>
                                <p className="details mb-5">Use the new command of the ABP CLI to create a new project:</p>
                                <img className="py-3 mb-5" src={usingAbpCli} alt="Using Abp Cli" />
                                <img className="py-3" src={successfullyCreated} alt="Your project successfully Created" />
                                <h5 className="fw-bolder mb-2 pt-5">The solution structures</h5>
                                <p className="details mb-5">The solution has a layered structure (based on the <Link to="https://docs.abp.io/en/abp/Domain-Driven-Design" className="text-decoration-none">Domain Driven Design</Link> ) and contains unit & integration test projects. See the <Link to="https://docs.abp.io/en/commercial/latest/startup-templates/application/solution-structure" className="text-decoration-none">solution structure document</Link> to understand the solution structure in detail. <Link to="https://docs.abp.io/en/commercial/latest/startup-templates/application/solution-structure" className="text-decoration-none">https://docs.abp.io/en/commercial/latest/startup-templates/application/solution-structure</Link></p>
                                <h5 className="fw-bolder mb-2">Create the Database</h5>
                                <h5 className="fw-bolder mb-2 pt-2 fs-6">Connection String</h5>
                                <p className="details mb-5">Check the <span className="fw-bolder">connection string</span>  in the appsettings.json file under the .HttpApi.Host project.</p>
                                <CodeSnippet codeString={connectionStrings} />
                                <h5 className="fw-bolder mb-2 pt-5">Database Migrations</h5>
                                <p className="details mb-5">The solution uses the <Link to="https://learn.microsoft.com/en-us/ef/core/managing-schemas/migrations/?tabs=dotnet-core-cli" className="text-decoration-none">Entity Framework Core Code First Migrations.</Link>  It comes with a .DbMigrator console application which applies the migrations and also seeds the initial data. It is useful on development as well as on production environment..DbMigrator project has its own appsettings.json. So, if we have changed the connection string above, we should also change this one.</p>
                                <h5 className="fw-bolder mb-2">The Initial Migration</h5>
                                <p className="details mb-2">DbMigrator application automatically <span className="fw-bolder">creates the Initial migration</span> on first run.</p>
                                <p className="details mb-5"><span className="fw-bolder mb-3">If we are using Visual Studio, we can skip to the</span> Running the DbMigrator <span className="fw-bolder">section.</span>  However, other IDEs (e.g. Rider) may have problems for the first run since it adds the initial migration and compiles the project. In this case, open a command line terminal in the folder of the .DbMigrator project and run the following command:</p>
                                <CodeSnippet codeString={dotnetRun} />
                                <p className="details pt-5 mb-2">Running the DbMigrator</p>
                                <p className="details mb-5"><span className="fw-bolder mb-3"> Right click to the .DbMigrator project and select</span> Set as StartUp Project</p>
                                <img className="py-3" src={setAsStartupProject} alt="Set As Startup Project" />
                                <p className="details py-4"><span className="fw-bolder mb-3">Hit F5 (or Ctrl+F5) to run the application. It will have an output like shown below:</span></p>
                                <img className="py-4" src={startedDatabaseMigrations} alt="Started Database Migrations" />
                                <p className="details py-4"><span className="fw-bolder mb-3">Initial <Link to="https://docs.abp.io/en/abp/latest/Data-Seeding" className="text-decoration-none link-primary">seed</Link> data creates the admin user in the database (with the password is 1q2w3E*) which is then used to login to the application. So, we need to use .DbMigrator at least once for a new database.</span></p>
                                <h5 className="fw-bolder mb-2 pt-5">Run the Application</h5>
                                <h5 className="fw-bolder mb-2 pt-2">Running the HTTP API Host (Server Side)</h5>
                                <p className="details mb-5">Ensure that the HttpApi.Host project is the start-up project and run the application which will open a Swagger UI:Use Ctrl+F5 in Visual Studio (instead of F5) to run the application without debugging. If we don't have a debug purpose, this will be faster.</p>
                                <img className="py-3" src={bookStoreAPI} alt="Book Store API" />
                                <h5 className="fw-bolder mb-2 pt-5">Running the Angular Application (Client Side)</h5>
                                <p className="details mb-5">Go to the angular folder, open a command line terminal, type the yarn command (we suggest to the <Link to="https://yarnpkg.com/" className="text-decoration-none">yarn</Link> package manager while npm install will also work)Go to the angular folder, open a command line terminal, type the yarn command (we suggest to the yarn package manager while npm install will also work)</p>
                                <CodeSnippet codeString={yarn} />
                                <p className="details py-4"> Once all node modules are loaded, execute yarn start (or npm start) command:</p>
                                <CodeSnippet codeString={yarnStart} />
                                <p className="details py-4">It may take a longer time for the first build. Once it finishes, it opens the Angular UI in our default browser with the localhost:4200 address.</p>
                                <img className="py-3" src={loginUser} alt="Login User" />
                                <h5 className="fw-bolder mb-2 pt-5">Elsa Integration</h5>
                                <h5 className="fw-bolder mb-2 pt-2">Backend</h5>
                                <h5 className="fw-bolder pt-2 mb-2">Installing Elsa Dashboard</h5>
                                <p className="details mb-2">The UI portion of Elsa Workflows is provided as a set of HTML web components in the form of the <Link to="https://www.npmjs.com/package/@elsa-workflows/elsa-workflow-designer" className="text-decoration-none">@elsa-workflows/elsa-workflow-designer</Link> NPM package.</p>
                                <p className="details mb-2">The web components can be used as normal HTML tags in our own HTML documents, which means that we can re-host the designer and even the dashboard UI in our  own web application.</p>
                                <p className="details mb-5">To make it easy for ASP.NET Core developers to integrate the visual designer into their own project, the components are made available via the <Link to="https://www.nuget.org/packages/Elsa.Designer.Components.Web" className="text-decoration-none">Elsa.Designer.Components.Web</Link> NuGet package, which embeds the necessary client asset files.</p>
                                <h5 className="fw-bolder mb-2">Add Package</h5>
                                <p className="details mb-5">To add the package to our ASP.NET Core project, we have to run the following command:</p>
                                <h5 className="fw-bolder mb-2">1. Host Project</h5>
                                <div className="ms-4">
                                    <p className="details mb-2">dotnet add package Elsa</p>
                                    <p className="details mb-2">dotnet add package Elsa.Activities.Email</p>
                                    <p className="details mb-2">dotnet add package Elsa.Activities.Http</p>
                                    <p className="details mb-2">dotnet add package Elsa.Activities.Temporal.Quartz</p>
                                    <p className="details mb-2">dotnet add package Elsa.Persistence.EntityFramework.Sqlserver</p>
                                    <p className="details mb-2">dotnet add package Elsa.Server.Api</p>
                                    <p className="details mb-4">dotnet add package Elsa.Designer.Components.Web</p>
                                </div>
                                <h5 className="fw-bolder mb-2">2. pt-3 Domain Project</h5>
                                <div className="ms-4">
                                    <p className="details mb-2">dotnet add package Elsa</p>
                                    <p className="details mb-2">dotnet add package Elsa.Activities.Email</p>
                                    <p className="details mb-2">dotnet add package Elsa.Activities.Http</p>
                                    <p className="details mb-2">dotnet add package Elsa.Activities.Temporal.Quartz</p>
                                    <p className="details mb-2">dotnet add package Elsa.Persistence.EntityFramework.Sqlserver</p>
                                    <p className="details mb-3">dotnet add package Elsa.Server.Api</p>
                                </div>
                                <p className="details mb-2">After these installations we must do some changes in host projectOpen HttpApiHostModule.cs file We need to do some changes in the swagger method</p>
                                <p className="details mb-5">Add the following line</p>
                                <CodeSnippet codeString={ConfigureSwagger} />
                                <h5 className="fw-bolder mb-4 pt-5">Add a new function ConfigureElsa</h5>
                                <CodeSnippet codeString={configureElsa} />
                                <p className="details py-4">Add these following lines inside ConfigureServices</p>
                                <CodeSnippet codeString={ConfigureServices} />
                                <p className="details py-4">Add the following lines in OnApplicationInitialization function</p>
                                <CodeSnippet codeString={OnApplicationInitialization} />
                                <p className="details fw-bolder pt-4 mb-2">Run the Backend</p>
                                <p className="details mb-2">Use the command: dotnet run –launch-profile elsaangular.HttpApi.Host</p>
                                <p className="details mb-5">Domain module</p>
                                <CodeSnippet codeString={ServiceConfigurationContext} />
                                <p className="details py-4">In the HttpApi.Host Module add the following</p>
                                <CodeSnippet codeString={ProjectSdk} />
                                <h5 className="fw-bolder mb-2 pt-5">Angular Application</h5>
                                <p className="details mb-2">Create a folder named statichtml inside the assets folder then add an html file named as workflow-designer.html</p>
                                <p className="details mb-5">Copy and paste the below contents inside this file.</p>
                                <CodeSnippet codeString={DOCTYPE} />
                                <p className="details pt-4 mb-2">Create a component workflow</p>
                                <p className="details mb-2">ng g c workflow</p>
                                <p className="details mb-2">cd workflow</p>
                                <p className="details mb-3">ng g s workflow</p>
                                <p className="details mb-2">Inside the workflow.service.ts</p>
                                <p className="details mb-5">Add the function getDashboard()</p>
                                <CodeSnippet codeString={getDashboard} />
                                <p className="details py-4">Replace the contents in workflow.component.html by the following lines</p>
                                <CodeSnippet codeString={workflow} />
                                <p className="details py-4">Replace the contents in workflow.component.ts by the following contents</p>
                                <CodeSnippet codeString={importt} />
                                <p className="details py-4">Add these lines to main.ts file</p>
                                <CodeSnippet codeString={script} />
                                <p className="details pt-4 mb-2">elsa-workflows-studio.esm.js is called form the backend</p>
                                <p className="details mb-5">Add the route to workflow dashboard in app-routing.module.ts</p>
                                <CodeSnippet codeString={path} />
                                <p className="details pt-4">Open angular root folder. Run the Angular application</p>
                                <p className="details mb-5">D:\elsaangular\angular - yarn start</p>
                                <img className="py-3 mb-4" src={dashboard} alt="Dashboard" />
                                <img className="py-3 mb-5" src={createWorkflow} alt="Create Workflow" />
                                <h5 className="fw-bolder mb-2">A Simple Document Approval Workflow</h5>
                                <p className="details mb-2">Before we try out the workflow, let's setup an SMTP host. The easiest way to do so is by running <Link to="https://github.com/rnwood/smtp4dev" className="text-decoration-none">Smtp4Dev</Link> using Docker:</p>
                                <p className="details mb-2">docker run -p 3000:80 -p 2525:25 rnwood/smtp4dev:linux-amd64-3.1.0-ci0856 </p>
                                <p className="details mb-5">When Smtp4Dev has started, we'll be able to navigate to its dashboard at <Link to="http://localhost:3000/" className="text-decoration-none">http://localhost:3000/</Link>  and inspect the emails the workflow will send.</p>
                                <h5 className="fw-bolder mb-2">Document Approval Workflow: Visual Designer</h5>
                                <p className="details mb-2">Creating the workflow visually is quite simple. Just add the activities one by one and configure their settings. However, there's one important difference between coded workflows and designed workflows: when designing visual workflows, there's (currently) no way to specify expressions using C# code as we did in the form of lambda statements in the DocumentApprovalWorkflow class. Instead, we must use JavaScript and Liquid expressions. The latter is specifically useful when we need to generate a body of text using dynamic data.</p>
                                <p className="details mb-5">Without further ado, let's create a new workflow called using the dashboard!</p>
                                <h5 className="fw-bolder mb-2">Create Workflow</h5>
                                <p className="details mb-2">With the Elsa Dashboard in front of we, navigate to the Workflow Definitions page and click the Create button. We should now see an empty canvas with just a Start button and a cog wheel to configure workflow settings.</p>
                                <p className="details mb-2">Let's do that first: click the cog wheel and specify the following:</p>
                                <ul className="ms-4 mb-2">
                                    <li className='mb-2'>Name: VisualDocumentApprovalWorkflow</li>
                                    <li className='mb-2'>Display Name: Visual Document Approval Workflow</li>
                                </ul>
                                <p className="details mb-5">Click<span className="fw-bolder mb-3"> Save.</span></p>
                                <h5 className="fw-bolder mb-2">HTTP Endpoint</h5>
                                <p className="details mb-2">Now click the Start button and look for the HTTP Endpoint activity and select it. Configure it with the following settings:</p>
                                <ul className="ms-4 mb-5">
                                    <li>Path: /v2/documents</li>
                                    <li>Methods: POST</li>
                                    <li>Read Content: true (checked)</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Set Document Variable</h5>
                                <p className="details mb-2">Click the Done outcome button on the previous activity and look for the Set Variable activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li>Variable Name: Document</li>
                                    <li>Value: input.Body (JavaScript)</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Send Email to Josh the Approver</h5>
                                <p className="details mb-2">Click the Done outcome button on the previous activity and look for the Send Email activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>From: workflow@acme.com</li>
                                    <li className='mb-2'><span className="fw-bolder">To: </span>josh@acme.com</li>
                                    <li className='mb-2'><span className="fw-bolder">Subject:</span> Document received from Variables.Document.Author.Name (Liquid)</li>
                                    {/* <li><span className="fw-bolder">Body:</span>""</li>//Code is remaining */}
                                </ul>
                                <h5 className="fw-bolder mb-2">HTTP Response: Document Received</h5>
                                <p className="details mb-2">Click the Done outcome button on the previous activity and look for the HTTP Response activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>Status Code: OK</li>
                                    <li className='mb-2'>Content: Request for Approval Sent Our document has been received and will be reviewed shortly.</li>
                                    <li className='mb-2'>Content Type: text/html</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Fork</h5>
                                <p className="details mb-2">Click the Done outcome button on the previous activity and look for the Fork activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>Branches: Approve, Reject, Remind</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Approve: Signal Received</h5>
                                <p className="details mb-2">Click the Approve outcome button on the previous activity and look for the Signal Received activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>Signal: Approve</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Send Approved Email to Author</h5>
                                <p className="details mb-2">Click the Done outcome button on the previous activity and look for the Send Email activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>From: workflow@acme.com</li>
                                    <li className='mb-2'>To: [Document.Author.Email] (JavaScript)</li>
                                    <li className='mb-2'>Subject: Document  Variables.Document.Id  Approved! (Liquid)</li>
                                    <li className='mb-2'>Body: Great job  Variables.Document.Author.Name , that document is perfect. (Liquid)</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Reject: Signal Received</h5>
                                <p className="details mb-2">Click the Reject outcome button on the Fork activity and look for the Signal Received activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li>Signal: Reject</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Send Rejected Email to Author</h5>
                                <p className="details mb-2">Click the Done outcome button on the previous activity and look for the Send Email activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>From: workflow@acme.com</li>
                                    <li className='mb-2'>To: [Document.Author.Email] (JavaScript)</li>
                                    <li className='mb-2'>Subject: Document  Variables.Document.Id  Rejected (Liquid)</li>
                                    <li className='mb-2'>Body: Nice try  Variables.Document.Author.Name , but that document needs work. (Liquid)</li>

                                </ul>
                                <h5 className="fw-bolder mb-2">Remind: Timer</h5>
                                <p className="details mb-2">Click the Remind outcome button on the Fork activity and look for the Timer activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li>Timeout: Duration.FromSeconds(10) (JavaScript)</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Send Reminder Email to Josh the Approver</h5>
                                <p className="details mb-2">Click the Remind outcome button on the Fork activity and look for the Timer activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>From: workflow@acme.com</li>
                                    <li className='mb-2'>To: josh@acme.com</li>
                                    <li className='mb-2'>Subject:  Variables.Document.Author.Name is waiting for your review! (Liquid)</li>
                                    {/* <li></li>code is remaining */}
                                </ul>
                                <p className="details mb-5">Now connect the Send Email activity we just added back to the Timer activity so that the workflow will keep sending reminders for as long as we haven't received a signal. To connect an outcome to an existing activity,f simply press and hold the SHIFT button while clicking the outcome. Release the SHIFT button and left click on the target activity:</p>
                                <img className="py-3" src={signalImage} alt="signalImage" />
                                <h5 className="fw-bolder mb-2 pt-5">Join</h5>
                                <p className="details mb-2">Click the <strong> Done </strong> outcome button of the <strong> Send Email</strong> activity in the<strong> Approve</strong>  branch and look for the <strong>Join</strong> activity and configure it as follows: </p>
                                <p className="details mb-2">Mode: WaitAny</p>
                                <p className="details mb-5">We also need to connect the <strong> Send Email</strong> Send Email activity from the<strong> Reject</strong>  branch using the same technique used to connect the reminder <strong> Send Email</strong> activity back to the<strong> Timer</strong>  activity:</p>
                                <img className="py-3" src={signalReceived} alt="Signal Received" />
                                <h5 className="fw-bolder mb-2 pt-5">HTTP Response: All set!</h5>
                                <p className="details mb-2">Finally, we just write a simple HTTP response, completing the workflow.</p>
                                <p className="details mb-2">Click the <strong>Done</strong> outcome button of the Join activity and look for the HTTP Response activity and configure it as follows:</p>
                                <ul className="ms-4 mb-5">
                                    <li className='mb-2'>Status Code: OK</li>
                                    <li className='mb-2'>Content: Thanks for the hard work!</li>
                                    <li className='mb-2'>Content Type: text/html</li>
                                </ul>
                                <h5 className="fw-bolder mb-2">Second Run</h5>
                                <p className="details mb-5">Make sure to publish our changes and then issue the following HTTP request:</p>
                                <CodeSnippet codeString={post} />
                                {/* */}
                                <h5 className="fw-bolder mb-2 pt-5">Conclusion</h5>
                                <p className="details mb-2">Workflows are a powerful tool for implementing long-running process. Elsa aims to make this easy by providing an easy-to-use set of APIs and tools to design and execute workflows. Although we combined the Dashboard with workflow execution into the same application, this is not mandatory. We can see that the workflow is working as expected. Either we can call the URL from postman or any other REST API tools. It’s just as easy to create two separate applications, where one represents the dashboard, while the other represents your real-world application that executes the workflows designed with the Dashboard.</p>
                                <p className="details mb-5">For more information, please check out <Link to="https://elsa-workflows.github.io/elsa-core/" className="text-decoration-none">https://elsa-workflows.github.io/elsa-core/.</Link>
                                </p>

                                  
                              
                                </div>
                               

                                {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                                <div className="py-3 mt-3">
                                    <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                    <div className="d-flex align-items-center">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mx-5">
                                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                            <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                <div>
                                                    <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                    <span className="featured-date ms-4">Today at 5:42PM</span>
                                                    <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                    <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row aarw">
                                    <div className="col-12">
                                        <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                    </div>
                                </div>
                                <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/sample">
                                                        <img className="card-img-top" src={relatedblog1} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Implement Single Sign-On with ABP commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Learn how to implement SSO between an ASP.Net core MVC application and ABP Commercial...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/xamarinOfflineDataSync">
                                                        <img className="card-img-top" src={relatedblog2} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Xamarin Offline data sync with Mongo Realm and RabbitMQ</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12 min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article you will learn how to integrate Realm in the Xamarin Forms project and sync the offline...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/integratingAndEnablingChat">
                                                        <img className="card-img-top" src={relatedblog3} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">12/10/2023</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Integrating and enabling the Chat module in ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner - 12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">This module implements real time messaging between users for an application.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                <div className="row d-flex justify-content-between py-3 align-items-center">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span>Kirti Kulkarni</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="align-items-end d-flex justify-content-end">
                                            <div className="footer-social d-flex gap-0">
                                                <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                    {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                <div>
                                    <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                    <Link className="nav-link" to="/blog/fileManagementModule">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">File Management Module</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={fileManagementImg} className="img-fluid" alt="File Management Module" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Work with PDF's in ABP Commercial Project using PDFTron</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={pdfTronImg} className="img-fluid" alt="Work with PDF's in ABP Commercial Project using PDFTron" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="nav-link" to="/blog/createCustomLoginPageAbp">
                                        <div className="card mb-3 px-3 py-2">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                    <h5 className="card-title">
                                                        <div className="d-flex align-items-center">
                                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                    <div>
                                                                        <span className="fs-6">Kirti Kulkarni</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h5>
                                                    <p className="card-text details fw-bold lh-sm">Create a custom login page in ABP Commercial Angular app</p>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                    <div className="card-body p-0">
                                                    <img src={customLoginPageImg} className="img-fluid" alt="Create a custom login page in ABP Commercial Angular app" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </Container>

    </div>



</Layout>
//     <Layout>
//       <div className="">
//         <Container>
//         <section className='mb-5'>
//                 <div className="container mt-5">
//                     <div className="row ">
//                         <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
//                             <div className="row d-flex justify-content-between py-2">
//                                 <div className="col-md-6">
//                                     <div className="d-flex align-items-center">
//                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                 <div>
//                                                     <span className="fs-3">Kirti Kulkarni</span>
//                                                     <p className="mb-0 text-muted"> Jun 14, 2022 <span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
//                                                     </p>
//                                                 </div>
//                                             </span>
//                                         </div>
//                                         <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
//                                             <div className="rate p-0 d-flex align-items-end mt-4">
//                                                 <span className="font-normal">5</span>
//                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                 <label htmlFor="star1" title="text">1 star</label>
//                                             </div>
//                                             <span className="font-normal">12</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="align-items-end d-flex justify-content-end">
//                                         <div className="footer-social d-flex gap-0">
//                                             <Link to="https://www.linkedin.com/company/hire-tech-team/">
//                                                 {/* <a href="https://www.linkedin.com/company/hire-tech-team/"> */}
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
//                                                 </svg>
//                                             </Link>
//                                             <Link to="https://twitter.com/HIRETECHTEAM">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
//                                                 </svg></Link>
//                                             <Link to="https://vimeo.com">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
//                                                 </svg></Link>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>


//                             <div className="row d-flex justify-content-between">
//                                 <h4 className="text-dark fw-bolder mt-4">Integrating Elsa .NET Workflows with ABP Commercial</h4>
//                                 <img className="py-3" src={abpElsa} alt="ABP ELSA" />
//                                 <p className="details">In this article we have demonstrated about how to integrate .NET 5 Open-Source Workflow framework Elsa with ABP Commercial application.</p>
//                                 <h5 className="fw-bolder">What is Elsa .Net? </h5>
//                                 <p className={`details ${!isLoggedIn && 'opacity-25'}`} > Elsa is an open-source .NET Standard Workflow framework. You can check it out at  <Link to="https://elsa-workflows.github.io/elsa-core/" className="text-decoration-none">https://elsa-workflows.github.io/elsa-core/</Link> </p>
//  <p className={`details ${!isLoggedIn && 'opacity-25'}`} > Let us look at some of the key features of the Elsa .Net platform.</p>

//                                             {!isLoggedIn &&
//                                                 <div className="row bg-light mb-5">
//                                                     <div className="col-lg-4">
//                                                         <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
//                                                     </div>
//                                                     <div className="col-lg-8">
//                                                         <h6 className="mt-3">To read the full post,</h6>
//                                                         <h4 className="fw-bolder">Sign up to AAA Academy</h4>
//                                                         <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
//                                                         <Link to={`/signUp`}>
//                                                             <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
//                                                         </Link>
//                                                         <p className="mt-2">Already have an account?
//                                                             <Link to="/login" className="text-decoration-none color"> Sign in
//                                                             </Link> </p>
//                                                     </div>
//                                                 </div>}
//                                             {isLoggedIn && <>
                              
//                                 <h5 className="fw-bolder">Elsa .Net is very Simple and Fast</h5>
//                                 <p className="details">The library is very compact and easy to use, at the same time, it is easy to extend through custom activities. Triggering event makes the corresponding workflow start automatically based on the event / recovery.</p>
//                                 <h5 className="fw-bolder">Elsa Supports long-running workflows </h5>
//                                 <p className="details">When the workflow is executed and needs to be done, for example, after user input, the workflow will stop, Keep, and run out of memory, until it can recover. It could be a few seconds later, a few minutes, few hours, days or even years.</p>
//                                 <h5 className="fw-bolder">Associate workflow with application-specific data  </h5>
//                                 <p className="details">This is a key requirement of a long-running workflow. Store workflows in a file-based format, so we can make it a part of source control.</p>
//                                 <p className="details">When we don't want the workflow to be part of source control, we can store it in a database based on the Web Version of the process designer. Whether it's storing workflow in a file system or in a database, whether to host the designer online or only on the local computer, need to be able to edit workflow online.</p>
//                                 <h5 className="fw-bolder">Use expressions to configure workflow activities </h5>
//                                 <p className="details">Usually, the information processed by workflow is dynamic in nature, Activities need a way to interact with that information. Workflow expressions support such scenarios.</p>
//                                 <p className="details">Through application-specific activities, Support custom storage and script engine for an extension, call another workflow. This allows us to invoke reusable application logic from various workflow processes. Like from C＃ it's the same as calling general functions. View and analyze the workflow instances executed. View the path taken by the workflow, Runtime state, Where the failure occurs, and compensate for the failed workflow.</p>
//                                 <p className="details">The workflow designer is embedded in our own dashboard application We can choose to create a workflow host that runs all the application logic , we can also choose to host the workflow runtime in a single microservice .</p>
//                                 <h5 className="fw-bolder">Separation of concerns </h5>
//                                 <p className="details">Workflow core library, the runtime and designer are completely separated in design. That is, when the workflow host should not rely on the web the designer. For example, This allows us to implement desktop-bas   designers, or not use the designer at all, use YAML file. Last, Hosts only need workflow definitions and access to persistent storage. Support local or cloud management because Elsa It's just a set of NuGet software package.</p>
//                                 <p className="details">Elsa with a separate, reusable HTML5 workflow designer web component, we can embed it in our own HTML on the web page. The designer runs entirely on the client-side and has a wealth of JavaScript API, The API allows us to define custom plug-ins to extend their functionality.</p>
//                                 <h5 className="fw-bolder">Getting Started with ABP commercial Angular application</h5>
//                                 <p className="details">Before creating project in abp.io we must purchase an active ABP commercial account</p>
//                                 <p className="details">We are using <span className="fw-bolder">Angular</span> as the UI framework and <span className="fw-bolder">Entity Framework Core as the database provider.</span></p>
//                                 <h5 className="fw-bolder">Setup our development environment</h5>
//                                 <p className="details">First things first! Let's setup our development environment before creating the first project.</p>
//                                 <h5 className="fw-bolder">Pre-requirements</h5>
//                                 <p className="details">The following tools should be installed on our development machine:</p>
//                                 <ul className="ms-4">

//                                     <li> <Link to="https://visualstudio.microsoft.com/vs/" className="text-decoration-none">Visual Studio 20</Link>22 for Windows / <Link to="https://visualstudio.microsoft.com/vs/mac/" className="text-decoration-none">Visual Studio for Mac. 1</Link></li>
//                                     <li> <Link to="https://dotnet.microsoft.com/en-us/download/dotnet" className="text-decoration-none">.NET Core 5.0+</Link></li>
//                                     <li><Link to="https://nodejs.org/en" className="text-decoration-none">Node v12 or v14</Link></li>
//                                     <li><Link to="https://classic.yarnpkg.com/en/docs/install#windows-stable" className="text-decoration-none">Yarn v1.20+ (not v2)2 or npm v6+ (already installed with Node)</Link></li>
//                                 </ul>
//                                 <p className="details"><Link to="https://redis.io/" className="text-decoration-none">Redis </Link> (as the <Link to="https://docs.abp.io/en/commercial/latest" className="text-decoration-none">distributed cache</Link>) is required if we select the Public website option.</p>
//                                 <p className="details text-danger">We can use another editor instead of Visual Studio if it supports .NET Core and ASP.NET Core. </p>
//                                 <p className="details text-danger">Yarn v2 works differently and is not supported. </p>
//                                 <h5 className="fw-bolder">Install the ABP CLI</h5>
//                                 <p className="details">ABP CLI is a free & open-source tool for <Link to="https://abp.io/" className="text-decoration-none"> the ABP framework.</Link>  It is also used for ABP Commercial application development.</p>
//                                 <p className="details">First, we need to install the ABP CLI using the following command:</p>
//                                 <CodeSnippet codeString={dotnetInstall} />
//                                 <p className="details">If we’ve already installed, we can update it using the following command:</p>
//                                 <CodeSnippet codeString={dotnetTool} />
//                                 <h5 className="fw-bolder">Login to account</h5>
//                                 <p className="details">In order to use ABP Commercial features, we need to login our account using the ABP CLI</p>
//                                 <CodeSnippet codeString={abpLogin} />
//                                 <h5 className="fw-bolder">Install the ABP Suite</h5>
//                                 <p className="details">ABP Suite is an application aims to assist us on our development.</p>
//                                 <p className="details">First, we need to install the ABP Suite:</p>
//                                 <CodeSnippet codeString={abpSuiteInstall} />
//                                 <p className="details">If we've already installed, we can update it:</p>
//                                 <CodeSnippet codeString={abpSuiteUpdate} />
//                                 <img className="py-3" src={abpSuite} alt="Abp Suite" />
//                                 <p className="details">Click to the  <span className="fw-bolder">Create a new solution</span> button and fill the modal form:</p>
//                                 <img className="py-3" src={createNewSolution} alt="Create New Solution" />
//                                 <h5 className="fw-bolder">Using the ABP CLI to create a new project</h5>
//                                 <p className="details">Use the new command of the ABP CLI to create a new project:</p>
//                                 <img className="py-3" src={usingAbpCli} alt="Using Abp Cli" />
//                                 <img className="py-3" src={successfullyCreated} alt="Your project successfully Created" />
//                                 <h5 className="fw-bolder">The solution structures</h5>
//                                 <p className="details">The solution has a layered structure (based on the <Link to="https://docs.abp.io/en/abp/Domain-Driven-Design" className="text-decoration-none">Domain Driven Design</Link> ) and contains unit & integration test projects. See the <Link to="https://docs.abp.io/en/commercial/latest/startup-templates/application/solution-structure" className="text-decoration-none">solution structure document</Link> to understand the solution structure in detail. <Link to="https://docs.abp.io/en/commercial/latest/startup-templates/application/solution-structure" className="text-decoration-none">https://docs.abp.io/en/commercial/latest/startup-templates/application/solution-structure</Link></p>
//                                 <h5 className="fw-bolder">Create the Database</h5>
//                                 <h5 className="fw-bolder">Connection String</h5>
//                                 <p className="details">Check the <span className="fw-bolder">connection string</span>  in the appsettings.json file under the .HttpApi.Host project.</p>
//                                 <CodeSnippet codeString={connectionStrings} />
//                                 <h5 className="fw-bolder">Database Migrations</h5>
//                                 <p className="details">The solution uses the <Link to="https://learn.microsoft.com/en-us/ef/core/managing-schemas/migrations/?tabs=dotnet-core-cli" className="text-decoration-none">Entity Framework Core Code First Migrations.</Link>  It comes with a .DbMigrator console application which applies the migrations and also seeds the initial data. It is useful on development as well as on production environment..DbMigrator project has its own appsettings.json. So, if we have changed the connection string above, we should also change this one.</p>
//                                 <h5 className="fw-bolder">The Initial Migration</h5>
//                                 <p className="details">DbMigrator application automatically <span className="fw-bolder">creates the Initial migration</span> on first run.</p>
//                                 <p className="details"><span className="fw-bolder">If we are using Visual Studio, we can skip to the</span> Running the DbMigrator <span className="fw-bolder">section.</span>  However, other IDEs (e.g. Rider) may have problems for the first run since it adds the initial migration and compiles the project. In this case, open a command line terminal in the folder of the .DbMigrator project and run the following command:</p>
//                                 <CodeSnippet codeString={dotnetRun} />
//                                 <p className="details">Running the DbMigrator</p>
//                                 <p className="details"><span className="fw-bolder"> Right click to the .DbMigrator project and select</span> Set as StartUp Project</p>
//                                 <img className="py-3" src={setAsStartupProject} alt="Set As Startup Project" />
//                                 <p className="details"><span className="fw-bolder">Hit F5 (or Ctrl+F5) to run the application. It will have an output like shown below:</span></p>
//                                 <img className="py-3" src={startedDatabaseMigrations} alt="Started Database Migrations" />
//                                 <p className="details"><span className="fw-bolder">Initial <Link to="https://docs.abp.io/en/abp/latest/Data-Seeding" className="text-decoration-none">seed</Link> data creates the admin user in the database (with the password is 1q2w3E*) which is then used to login to the application. So, we need to use .DbMigrator at least once for a new database.</span></p>
//                                 <h5 className="fw-bolder">Run the Application</h5>
//                                 <h5 className="fw-bolder">Running the HTTP API Host (Server Side)</h5>
//                                 <p className="details">Ensure that the HttpApi.Host project is the start-up project and run the application which will open a Swagger UI:Use Ctrl+F5 in Visual Studio (instead of F5) to run the application without debugging. If we don't have a debug purpose, this will be faster.</p>
//                                 <img className="py-3" src={bookStoreAPI} alt="Book Store API" />
//                                 <h5 className="fw-bolder">Running the Angular Application (Client Side)</h5>
//                                 <p className="details">Go to the angular folder, open a command line terminal, type the yarn command (we suggest to the <Link to="https://yarnpkg.com/" className="text-decoration-none">yarn</Link> package manager while npm install will also work)Go to the angular folder, open a command line terminal, type the yarn command (we suggest to the yarn package manager while npm install will also work)</p>
//                                 <CodeSnippet codeString={yarn} />
//                                 <p className="details"> Once all node modules are loaded, execute yarn start (or npm start) command:</p>
//                                 <CodeSnippet codeString={yarnStart} />
//                                 <p className="details">It may take a longer time for the first build. Once it finishes, it opens the Angular UI in our default browser with the localhost:4200 address.</p>
//                                 <img className="py-3" src={loginUser} alt="Login User" />
//                                 <h5 className="fw-bolder">Elsa Integration</h5>
//                                 <h5 className="fw-bolder">Backend</h5>
//                                 <h5 className="fw-bolder mt-4">Installing Elsa Dashboard</h5>
//                                 <p className="details">The UI portion of Elsa Workflows is provided as a set of HTML web components in the form of the <Link to="https://www.npmjs.com/package/@elsa-workflows/elsa-workflow-designer" className="text-decoration-none">@elsa-workflows/elsa-workflow-designer</Link> NPM package.</p>
//                                 <p className="details">The web components can be used as normal HTML tags in our own HTML documents, which means that we can re-host the designer and even the dashboard UI in our  own web application.</p>
//                                 <p className="details">To make it easy for ASP.NET Core developers to integrate the visual designer into their own project, the components are made available via the <Link to="https://www.nuget.org/packages/Elsa.Designer.Components.Web" className="text-decoration-none">Elsa.Designer.Components.Web</Link> NuGet package, which embeds the necessary client asset files.</p>
//                                 <h5 className="fw-bolder">Add Package</h5>
//                                 <p className="details">To add the package to our ASP.NET Core project, we have to run the following command:</p>
//                                 <h5 className="fw-bolder">1. Host Project</h5>
//                                 <div className="ms-4">
//                                     <p className="details">dotnet add package Elsa</p>
//                                     <p className="details">dotnet add package Elsa.Activities.Email</p>
//                                     <p className="details">dotnet add package Elsa.Activities.Http</p>
//                                     <p className="details">dotnet add package Elsa.Activities.Temporal.Quartz</p>
//                                     <p className="details">dotnet add package Elsa.Persistence.EntityFramework.Sqlserver</p>
//                                     <p className="details">dotnet add package Elsa.Server.Api</p>
//                                     <p className="details">dotnet add package Elsa.Designer.Components.Web</p>
//                                 </div>
//                                 <h5 className="fw-bolder">2.  Domain Project</h5>
//                                 <div className="ms-4">
//                                     <p className="details">dotnet add package Elsa</p>
//                                     <p className="details">dotnet add package Elsa.Activities.Email</p>
//                                     <p className="details">dotnet add package Elsa.Activities.Http</p>
//                                     <p className="details">dotnet add package Elsa.Activities.Temporal.Quartz</p>
//                                     <p className="details">dotnet add package Elsa.Persistence.EntityFramework.Sqlserver</p>
//                                     <p className="details">dotnet add package Elsa.Server.Api</p>
//                                 </div>
//                                 <p className="details">After these installations we must do some changes in host projectOpen HttpApiHostModule.cs file We need to do some changes in the swagger method</p>
//                                 <p className="details">Add the following line</p>
//                                 <CodeSnippet codeString={ConfigureSwagger} />
//                                 <h5 className="fw-bolder">Add a new function ConfigureElsa</h5>
//                                 <CodeSnippet codeString={configureElsa} />
//                                 <p className="details">Add these following lines inside ConfigureServices</p>
//                                 <CodeSnippet codeString={ConfigureServices} />
//                                 <p className="details">Add the following lines in OnApplicationInitialization function</p>
//                                 <CodeSnippet codeString={OnApplicationInitialization} />
//                                 <p className="details fw-bolder">Run the Backend</p>
//                                 <p className="details">Use the command: dotnet run –launch-profile elsaangular.HttpApi.Host</p>
//                                 <p className="details">Domain module</p>
//                                 <CodeSnippet codeString={ServiceConfigurationContext} />
//                                 <p className="details">In the HttpApi.Host Module add the following</p>
//                                 <CodeSnippet codeString={ProjectSdk} />
//                                 <h5 className="fw-bolder">Angular Application</h5>
//                                 <p className="details">Create a folder named statichtml inside the assets folder then add an html file named as workflow-designer.html</p>
//                                 <p className="details">Copy and paste the below contents inside this file.</p>
//                                 <CodeSnippet codeString={DOCTYPE} />
//                                 <p className="details">Create a component workflow</p>
//                                 <p className="details">ng g c workflow</p>
//                                 <p className="details">cd workflow</p>
//                                 <p className="details">ng g s workflow</p>
//                                 <p className="details mt-4">Inside the workflow.service.ts</p>
//                                 <p className="details">Add the function getDashboard()</p>
//                                 <CodeSnippet codeString={getDashboard} />
//                                 <p className="details">Replace the contents in workflow.component.html by the following lines</p>
//                                 <CodeSnippet codeString={workflow} />
//                                 <p className="details">Replace the contents in workflow.component.ts by the following contents</p>
//                                 <CodeSnippet codeString={importt} />
//                                 <p className="details">Add these lines to main.ts file</p>
//                                 <CodeSnippet codeString={script} />
//                                 <p className="details">elsa-workflows-studio.esm.js is called form the backend</p>
//                                 <p className="details">Add the route to workflow dashboard in app-routing.module.ts</p>
//                                 <CodeSnippet codeString={path} />
//                                 <p className="details">Open angular root folder. Run the Angular application</p>
//                                 <p className="details">D:\elsaangular\angular - yarn start</p>
//                                 <img className="py-3" src={dashboard} alt="Dashboard" />
//                                 <img className="py-3" src={createWorkflow} alt="Create Workflow" />
//                                 <h5 className="fw-bolder">A Simple Document Approval Workflow</h5>
//                                 <p className="details">Before we try out the workflow, let's setup an SMTP host. The easiest way to do so is by running <Link to="https://github.com/rnwood/smtp4dev" className="text-decoration-none">Smtp4Dev</Link> using Docker:</p>
//                                 <p className="details">docker run -p 3000:80 -p 2525:25 rnwood/smtp4dev:linux-amd64-3.1.0-ci0856 </p>
//                                 <p className="details">When Smtp4Dev has started, we'll be able to navigate to its dashboard at <Link to="http://localhost:3000/" className="text-decoration-none">http://localhost:3000/</Link>  and inspect the emails the workflow will send.</p>
//                                 <h5 className="fw-bolder">Document Approval Workflow: Visual Designer</h5>
//                                 <p className="details">Creating the workflow visually is quite simple. Just add the activities one by one and configure their settings. However, there's one important difference between coded workflows and designed workflows: when designing visual workflows, there's (currently) no way to specify expressions using C# code as we did in the form of lambda statements in the DocumentApprovalWorkflow class. Instead, we must use JavaScript and Liquid expressions. The latter is specifically useful when we need to generate a body of text using dynamic data.</p>
//                                 <p className="details">Without further ado, let's create a new workflow called using the dashboard!</p>
//                                 <h5 className="fw-bolder">Create Workflow</h5>
//                                 <p className="details">With the Elsa Dashboard in front of we, navigate to the Workflow Definitions page and click the Create button. We should now see an empty canvas with just a Start button and a cog wheel to configure workflow settings.</p>
//                                 <p className="details">Let's do that first: click the cog wheel and specify the following:</p>
//                                 <ul className="ms-4">
//                                     <li>Name: VisualDocumentApprovalWorkflow</li>
//                                     <li>Display Name: Visual Document Approval Workflow</li>
//                                 </ul>
//                                 <p className="details">Click<span className="fw-bolder"> Save.</span></p>
//                                 <h5 className="fw-bolder">HTTP Endpoint</h5>
//                                 <p className="details">Now click the Start button and look for the HTTP Endpoint activity and select it. Configure it with the following settings:</p>
//                                 <ul className="ms-4">
//                                     <li>Path: /v2/documents</li>
//                                     <li>Methods: POST</li>
//                                     <li>Read Content: true (checked)</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Set Document Variable</h5>
//                                 <p className="details">Click the Done outcome button on the previous activity and look for the Set Variable activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Variable Name: Document</li>
//                                     <li>Value: input.Body (JavaScript)</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Send Email to Josh the Approver</h5>
//                                 <p className="details">Click the Done outcome button on the previous activity and look for the Send Email activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>From: workflow@acme.com</li>
//                                     <li><span className="fw-bolder">To: </span>josh@acme.com</li>
//                                     <li><span className="fw-bolder">Subject:</span> Document received from Variables.Document.Author.Name (Liquid)</li>
//                                     {/* <li><span className="fw-bolder">Body:</span>""</li>//Code is remaining */}
//                                 </ul>
//                                 <h5 className="fw-bolder">HTTP Response: Document Received</h5>
//                                 <p className="details">Click the Done outcome button on the previous activity and look for the HTTP Response activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Status Code: OK</li>
//                                     <li>Content: Request for Approval Sent Our document has been received and will be reviewed shortly.</li>
//                                     <li>Content Type: text/html</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Fork</h5>
//                                 <p className="details">Click the Done outcome button on the previous activity and look for the Fork activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Branches: Approve, Reject, Remind</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Approve: Signal Received</h5>
//                                 <p className="details">Click the Approve outcome button on the previous activity and look for the Signal Received activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Signal: Approve</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Send Approved Email to Author</h5>
//                                 <p className="details">Click the Done outcome button on the previous activity and look for the Send Email activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>From: workflow@acme.com</li>
//                                     <li>To: [Document.Author.Email] (JavaScript)</li>
//                                     <li>Subject: Document  Variables.Document.Id  Approved! (Liquid)</li>
//                                     <li>Body: Great job  Variables.Document.Author.Name , that document is perfect. (Liquid)</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Reject: Signal Received</h5>
//                                 <p className="details">Click the Reject outcome button on the Fork activity and look for the Signal Received activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Signal: Reject</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Send Rejected Email to Author</h5>
//                                 <p className="details">Click the Done outcome button on the previous activity and look for the Send Email activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>From: workflow@acme.com</li>
//                                     <li>To: [Document.Author.Email] (JavaScript)</li>
//                                     <li>Subject: Document  Variables.Document.Id  Rejected (Liquid)</li>
//                                     <li>Body: Nice try  Variables.Document.Author.Name , but that document needs work. (Liquid)</li>

//                                 </ul>
//                                 <h5 className="fw-bolder">Remind: Timer</h5>
//                                 <p className="details">Click the Remind outcome button on the Fork activity and look for the Timer activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Timeout: Duration.FromSeconds(10) (JavaScript)</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Send Reminder Email to Josh the Approver</h5>
//                                 <p className="details">Click the Remind outcome button on the Fork activity and look for the Timer activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>From: workflow@acme.com</li>
//                                     <li>To: josh@acme.com</li>
//                                     <li>Subject:  Variables.Document.Author.Name is waiting for your review! (Liquid)</li>
//                                     {/* <li></li>code is remaining */}
//                                 </ul>
//                                 <p className="details">Now connect the Send Email activity we just added back to the Timer activity so that the workflow will keep sending reminders for as long as we haven't received a signal. To connect an outcome to an existing activity,f simply press and hold the SHIFT button while clicking the outcome. Release the SHIFT button and left click on the target activity:</p>
//                                 <img className="py-3" src={signalImage} alt="signalImage" />
//                                 <h5 className="fw-bolder">Join</h5>
//                                 <p className="details">Click the <strong> Done </strong> outcome button of the <strong> Send Email</strong> activity in the<strong> Approve</strong>  branch and look for the <strong>Join</strong> activity and configure it as follows: </p>
//                                 <p className="details">Mode: WaitAny</p>
//                                 <p className="details">We also need to connect the <strong> Send Email</strong> Send Email activity from the<strong> Reject</strong>  branch using the same technique used to connect the reminder <strong> Send Email</strong> activity back to the<strong> Timer</strong>  activity:</p>
//                                 <img className="py-3" src={signalReceived} alt="Signal Received" />
//                                 <h5 className="fw-bolder">HTTP Response: All set!</h5>
//                                 <p className="details">Finally, we just write a simple HTTP response, completing the workflow.</p>
//                                 <p className="details">Click the <strong>Done</strong> outcome button of the Join activity and look for the HTTP Response activity and configure it as follows:</p>
//                                 <ul className="ms-4">
//                                     <li>Status Code: OK</li>
//                                     <li>Content: Thanks for the hard work!</li>
//                                     <li>Content Type: text/html</li>
//                                 </ul>
//                                 <h5 className="fw-bolder">Second Run</h5>
//                                 <p className="details">Make sure to publish our changes and then issue the following HTTP request:</p>
//                                 <CodeSnippet codeString={post} />
//                                 {/* */}
//                                 <h5 className="fw-bolder">Conclusion</h5>
//                                 <p className="details">Workflows are a powerful tool for implementing long-running process. Elsa aims to make this easy by providing an easy-to-use set of APIs and tools to design and execute workflows. Although we combined the Dashboard with workflow execution into the same application, this is not mandatory. We can see that the workflow is working as expected. Either we can call the URL from postman or any other REST API tools. It’s just as easy to create two separate applications, where one represents the dashboard, while the other represents your real-world application that executes the workflows designed with the Dashboard.</p>
//                                 <p className="details">For more information, please check out <Link to="https://elsa-workflows.github.io/elsa-core/" className="text-decoration-none">https://elsa-workflows.github.io/elsa-core/.</Link>
//                                 </p></>}
//                             </div>
//                             <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
//                             <div className="py-3">
//                                 <h4 className="text-dark fw-bolder">Featured Comments</h4>
//                                 <div className="d-flex align-items-center">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>

//                                 <div className="d-flex align-items-center">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>

//                                 <div className="d-flex align-items-center mx-5">
//                                     <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                         <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                         <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                             <div>
//                                                 <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
//                                                 <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
//                                             </div>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <p className="text-muted mx-5">Reply</p>
//                             </div>
//                             <div className="row aarw">
//                                 <div className="col-12">
//                                     <h5 className="related-title">More Articles from Joe Thomson</h5>
//                                 </div>
//                                 <div className="col-12">
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">

//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>
//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">
//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>

//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>
//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-md-6 col-lg-4 mb-4">
//                                         <div className="card-aa ca1 border-0 h-100">
//                                             <Link className="nav-link" to="/footer">
//                                                 <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
//                                                 <div className="card-body-aa px-3">
//                                                     <div className="row mb-2">
//                                                         <div className="col-md-8">
//                                                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
//                                                         </div>
//                                                         <div className="col-md-4 card-date mt-1">19/02/2023</div>
//                                                     </div>
//                                                     <div className="position mb-2">Software Development</div>
//                                                     <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
//                                                     <div className="d-flex justify-content-between">
//                                                         <p className="font-colored">Beginner -5 min</p>
//                                                         <div>
//                                                             <div className="rate p-0">
//                                                                 <span className="font-normal">4.5</span>
//                                                                 <input type="radio" id="star5" name="rate" value="5" />
//                                                                 <label htmlFor="star5" title="text">5 stars</label>
//                                                                 <input type="radio" id="star4" name="rate" value="4" />
//                                                                 <label htmlFor="star4" title="text">4 stars</label>
//                                                                 <input type="radio" id="star3" name="rate" value="3" />
//                                                                 <label htmlFor="star3" title="text">3 stars</label>
//                                                                 <input type="radio" id="star2" name="rate" value="2" />
//                                                                 <label htmlFor="star2" title="text">2 stars</label>
//                                                                 <input type="radio" id="star1" name="rate" value="1" />
//                                                                 <label htmlFor="star1" title="text">1 star</label>
//                                                             </div>
//                                                             <span className="font-normal">520</span>
//                                                         </div>

//                                                     </div>
//                                                     <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
//                             <div className="row d-flex justify-content-between py-3">
//                                 <div className="col-md-6">
//                                     <div className="d-flex align-items-center">
//                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                 <div>
//                                                     <span>Kirti Kulkarni</span>
//                                                 </div>
//                                             </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="align-items-end d-flex justify-content-end">

//                                         <div className="footer-social d-flex gap-0">
//                                             <Link to="https://www.linkedin.com/company/hire-tech-team/">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
//                                                     <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
//                                                 </svg>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <h6 className="about-author">ABOUT THE AUTHOR</h6>
//                             <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
//                             <div>
//                                 <h5 className="fw-medium mb-3">Latest Articles</h5>
//                                 <Link className="nav-link" to="/blog/fileManagementModule">
//                                     <div className="card mb-3 px-3 py-2">
//                                         <div className="row g-0">
//                                             <div className="col-md-8">
//                                                 <h5 className="card-title">
//                                                     <div className="d-flex align-items-center">
//                                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                                 <div>
//                                                                     <span className="fs-6">Kirti Kulkarni</span>
//                                                                 </div>
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </h5>
//                                                 <p className="card-text details">File Management Module</p>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card-body">
//                                                     <img src={fileManagementImg} className="img-fluid" alt="File Management Module" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Link>
//                                 <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
//                                     <div className="card mb-3 px-3 py-2">
//                                         <div className="row g-0">
//                                             <div className="col-md-8">
//                                                 <h5 className="card-title">
//                                                     <div className="d-flex align-items-center">
//                                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                                 <div>
//                                                                     <span className="fs-6">Kirti Kulkarni</span>
//                                                                 </div>
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </h5>
//                                                 <p className="card-text details">Work with PDF's in ABP Commercial Project using PDFTron</p>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card-body">
//                                                     <img src={pdfTronImg} className="img-fluid" alt="Work with PDF's in ABP Commercial Project using PDFTron" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Link>
//                                 <Link className="nav-link" to="/blog/createCustomLoginPageAbp">
//                                     <div className="card mb-3 px-3 py-2">
//                                         <div className="row g-0">
//                                             <div className="col-md-8">
//                                                 <h5 className="card-title">
//                                                     <div className="d-flex align-items-center">
//                                                         <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
//                                                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
//                                                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
//                                                                 <div>
//                                                                     <span className="fs-6">Kirti Kulkarni</span>
//                                                                 </div>
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </h5>
//                                                 <p className="card-text details">Create a custom login page in ABP Commercial Angular app</p>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card-body">
//                                                     <img src={customLoginPageImg} className="img-fluid" alt="Create a custom login page in ABP Commercial Angular app" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Link>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </section>
//         </Container>
        
//       </div>
  
        
      
//     </Layout>
    
  );
};

export default SamplePage;
