import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import fileManagement from "../../../static/AAA-images/fileManagement.png"
import uploadFiles from "../../../static/AAA-images/uploadFiles.png"
import pdfTronImg from "../../../static/AAA-images/pdfTronImg.jpg"
import customLoginPageImg from "../../../static/AAA-images/customLoginPageImg.png"
import deployingAbpImg from "../../../static/AAA-images/deployingAbpImg.png"
import { useState, useEffect } from "react";

import relatedblog12 from "../../../static/AAA-images/work-with-pdf.jpg"
import relatedblog13 from "../../../static/AAA-images/customLoginPageImg.png"
import relatedblog14 from "../../../static/AAA-images/Deploying-ABP-angular-application-to-Azure-and-App-Insights-integration.png"



const SamplePage = (props) => {
    const packageReference = `<PackageReference Include="Volo.FileManagement.Application" Version="x.x.x" />`;
    const DependsOn = ` [DependsOn(  //...  typeof(FileManagementApplicationModule))] `;
    const ConfigureFileManagement = `builder.ConfigureFileManagement(); `;
    const DbSet = ` public DbSet<DirectoryDescriptor>DirectoryDescriptions { get; set; } 
    public DbSet<FileDescriptor>FileDescriptions { get; set; }  `;
    const AbpBlobStoringOptions = ` Configure<AbpBlobStoringOptions>(options 
        =>{ options.Containers.Configure<FileManagementContainer>(c 
        => { c.UseDatabase(); // You can use FileSystem or Azure providers 
        also. });});`;
    const DownstreamPathTemplate = `{ 
        "DownstreamPathTemplate": "/api/file-management/{everything}", 
        "DownstreamScheme": "https", 
        "DownstreamHostAndPorts": [ 
         { 
          "Host": "localhost", 
          "Port": 44367 
         } 
        ], 
        "UpstreamPathTemplate": "/api/file-management/{everything}", 
        "UpstreamHttpMethod": [ "Put", "Delete", "Get", "Post" ] 
       } `;
    const requiredPolicy = `{ 
        path: '/file-management', 
        name: 'FileManagement::Menu:FileManagement' , 
        layout: eLayoutType.application , 
        parentName: 'AbpUiNavigation::Menu:Administration' , 
        order: 6, 
        requiredPolicy: 'FileManagement.DirectoryDescriptor' , 
      },`;
    const loadChildren = `{ 
        path: 'file-management', 
        loadChildren: () => 
        import('@volo/abp.ng.file-management').then(m => 
        m.FileManagementModule.forLazy() 
        ), 
      },`;
    const FileManagementConfigModule = `FileManagementConfigModule.forRoot(),`;

   


    return (
        <Layout >
        <div className="">
            <Container>
                <div className="">
                    <section className='mb-5'>
                        <div className="container mt-5">
                            <div className="row ">
                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                    <div className="row d-flex justify-content-between py-2">
                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                            <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                                <p className="mb-0 small text-muted">Jun 23, 2022 <span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                                <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                                <div className="rate p-0">
                                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                                </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </span>
                                                </div>
    
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                            <div className="">
    
                                                <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                    <Link to="https://www.linkedin.com/company/hire-tech-team/">
    
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                        </svg>
                                                    </Link>
                                                    <Link to="https://twitter.com/HIRETECHTEAM">
                                                     
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                        </svg></Link>
                                                    <Link to="https://vimeo.com">
                                                      
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                        </svg></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-between">
    
                                    <h4 className="text-dark fw-bolder mt-5 mb-4 pb-3">File Management Module</h4>
                                        
                                         <iframe title='File Management Module' width="500" height="450" src="https://player.vimeo.com/video/980470118?h=6c993b172d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="allowFullScreen"> </iframe>
                                         <h5 className="fw-bolder py-3 mt-4"> Introduction </h5>
                                        <p className="details">ABP Commercial provides the file management module that can be used to upload, download, and organize files in a folder structure. The framework provides an abstraction to work with BLOBs and provides some pre-built storage providers that you can easily integrate to.</p>
                                         <p className="details">It also supports multi-tenancy, and for each tenant, you can set the total size limit.</p>
                                         <p className="details">The file management module uses the  BLOB Storing system, so it can use different storage providers to store the file contents. You can easily change your BLOB storage without changing application code.</p>
                                         <p className="details">If you want to create reusable application modules, you don't need to make assumption about how the BLOBs are stored.</p>
                                         <p className="details">You can easily integrate to your favorite BLOB storage provides with a few lines of configuration.</p>
                                         <p className="details">In this article HireTechTeam ABP developer shows how to integrate the module into an ABP commercial Angular application.</p>
                                         <h4 className="text-dark fw-bolder mt-5 mb-4">Getting started </h4>
                                         <p className="details mb-2" >Installing the ABP File management module </p>
                                         <p className= "details mb-1"> First need to install file management module .</p>

                                        
                                            
                                            <p className="details mb-1">Go to your <strong>Application.csproj</strong> to install this module </p>
                                            <p className="details mb-1">abp add-module Volo.FileManagement by using this command in command prompt .</p>
                                            <p className="details mb-5">Then below code will automatically get added in the  .Application.csproj  file</p>
                                            <CodeSnippet codeString={packageReference} className="mb-5" />
                                            <p className="details py-4"> Then in the ApplicationModule below code is automatically added </p>
                                            <CodeSnippet codeString={DependsOn} />
                                            <p className="details py-4">Next step is to go to DbContext file of EntityFrameworkCore.cs file and add the below code</p>
                                            <CodeSnippet codeString={ConfigureFileManagement} />
                                            <p className="details py-4">Then, Add IFileManagementDbContext to DbContext and add DbSet</p>
                                            <CodeSnippet codeString={DbSet} />
                                            <p className="details py-4">Lastly, Add-Migration for file management </p>
                                            <h5 className="fw-bolder pt-2">Preparing the angular project to use and store File management</h5>
                                            <p className="details pt-3 mb-2">After that install @volo/abp.ng.file-management in angular i.e </p>
                                            <p className="details mb-2">Yarn add @volo/abp.ng.file-management </p>
                                            <p className="details mb-4">Add setting for blob container in module </p>
                                            <CodeSnippet codeString={AbpBlobStoringOptions} />
                                            <p className="details py-4">Add API reference in appsettings in webgateway and internal gateway</p>
                                            <CodeSnippet codeString={DownstreamPathTemplate} />
                                            <p className="details py-4">Next you need to  Add path inroute.provider.ts file in angular</p>
                                            <CodeSnippet codeString={requiredPolicy} />
                                            <p className="details py-4">Add path reference in app-routing.module.ts</p>
                                            <CodeSnippet codeString={loadChildren} />
                                            <p className="details py-4">Next step is to import module in app.module.ts file in app </p>
                                            <CodeSnippet codeString={FileManagementConfigModule} />
                                            <p className="details py-4">This results in adding the File management view as shown in the figure below. This view provides the features for  List, view all folder structure and files.</p>
                                            <img className="py-3" src={fileManagement} alt="File Management" />
                                            <p className="details py-4">It enables uploading any type of files and offers a thumbnail preview of the files. </p>
                                            <img className="py-3" src={uploadFiles} alt="Upload Files" />
                                            <h5 className="fw-bolder pt-4">Conclusion</h5>
                                            <p className="details mb-4">ABP commercials File management module is easy to integrate into the application and provides useful features for managing files on the BLOB storage.</p>
     
                                      
                                  
                                    </div>
                                   
    
                                    {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                                    <div className="py-3 mt-3">
                                        <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                        <span className="featured-date ms-4">Today at 5:42PM</span>
                                                        <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                        <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex align-items-center">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                        <span className="featured-date ms-4">Today at 5:42PM</span>
                                                        <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                        <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mx-5">
                                            <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                    <div>
                                                        <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                        <span className="featured-date ms-4">Today at 5:42PM</span>
                                                        <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                        <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row aarw">
                                        <div className="col-12">
                                            <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                        </div>                                      
                                    </div>
                                    <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
                                                        <img className="card-img-top" src={relatedblog12} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">28/06/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Work with PDF's in ABP Commercial Project using PDFTron</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this short article we will integrate PDFTron in an ABP commercial Angular application. PDFTron...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/footer">
                                                        <img className="card-img-top" src={relatedblog13} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">08/07/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Create a custom Login page in ABP Commercial Angular App</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article and video, we will demonstrate how to work with the default login page of ABP...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/footer">
                                                        <img className="card-img-top" src={relatedblog14} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">02/09/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Deploying ABP angular application to Azure and App Insights integration</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Learn how to implement SSO between an ASP.Net core MVC application and ABP Commercial...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                    <div className="row d-flex justify-content-between py-3 align-items-center">
                                        <div className="col-md-6">
                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span>Kirti Kulkarni</span>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="align-items-end d-flex justify-content-end">
                                                <div className="footer-social d-flex gap-0">
                                                    <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                        {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                    <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                    <div>
                                        <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                        <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
                                            <div className="card mb-3 px-3 py-2">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                        <h5 className="card-title">
                                                            <div className="d-flex align-items-center">
                                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                        <div>
                                                                            <span className="fs-6">Kirti Kulkarni</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <p className="card-text details fw-bold lh-sm">Work with PDF's in ABP Commercial Project using PDFTron</p>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                        <div className="card-body p-0">
                                                        <img src={pdfTronImg} className="img-fluid" alt="Work with PDF's in ABP Commercial Project using PDFTron" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link className="nav-link" to="/blog/createCustomLoginPageAbp">
                                            <div className="card mb-3 px-3 py-2">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                        <h5 className="card-title">
                                                            <div className="d-flex align-items-center">
                                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                        <div>
                                                                            <span className="fs-6">Kirti Kulkarni</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <p className="card-text details fw-bold lh-sm">Create a custom login page in ABP Commercial Angular app</p>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                        <div className="card-body p-0">
                                                        <img src={customLoginPageImg} className="img-fluid" alt="Create a custom login page in ABP Commercial Angular app" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
                                            <div className="card mb-3 px-3 py-2">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                        <h5 className="card-title">
                                                            <div className="d-flex align-items-center">
                                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                        <div>
                                                                            <span className="fs-6">Kirti Kulkarni</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <p className="card-text details fw-bold lh-sm">Deploying ABP angular application to Azure and App Insights integration</p>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                        <div className="card-body p-0">
                                                        <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </section>
    
                </div>
            </Container>
    
        </div>
    
    
    
    </Layout>
    //     <Layout>
    //         <div className="">
    //             <Container>
    //                 <section className='mb-5'>
    //                     <div className="container mt-5">
    //                         <div className="row ">
    //                             <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
    //                                 <div className="row d-flex justify-content-between py-2">
    //                                     <div className="col-md-6">
    //                                         <div className="d-flex align-items-center">
    //                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                 <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                     <div>
    //                                                         <span className="fs-3">Kirti Kulkarni</span>
    //                                                         <p className="mb-0 text-muted">Jun 23, 2022 <span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
    //                                                         </p>
    //                                                     </div>
    //                                                 </span>
    //                                             </div>
    //                                             <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
    //                                                 <div className="rate p-0 d-flex align-items-end mt-4">
    //                                                     <span className="font-normal">5</span>
    //                                                     <input type="radio" id="star5" name="rate" value="5" />
    //                                                     <label htmlFor="star5" title="text">5 stars</label>
    //                                                     <input type="radio" id="star4" name="rate" value="4" />
    //                                                     <label htmlFor="star4" title="text">4 stars</label>
    //                                                     <input type="radio" id="star3" name="rate" value="3" />
    //                                                     <label htmlFor="star3" title="text">3 stars</label>
    //                                                     <input type="radio" id="star2" name="rate" value="2" />
    //                                                     <label htmlFor="star2" title="text">2 stars</label>
    //                                                     <input type="radio" id="star1" name="rate" value="1" />
    //                                                     <label htmlFor="star1" title="text">1 star</label>
    //                                                 </div>
    //                                                 <span className="font-normal">12</span>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <div className="col-md-6">
    //                                         <div className="align-items-end d-flex justify-content-end">
    //                                             <div className="footer-social d-flex gap-0">
    //                                                 <Link to="https://www.linkedin.com/company/hire-tech-team/">
    //                                                     {/* <a href="https://www.linkedin.com/company/hire-tech-team/"> */}
    //                                                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                                                         <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
    //                                                     </svg>
    //                                                 </Link>
    //                                                 <Link to="https://twitter.com/HIRETECHTEAM">
    //                                                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                                                         <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
    //                                                     </svg></Link>
    //                                                 <Link to="https://vimeo.com">
    //                                                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                                                         <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
    //                                                     </svg></Link>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="row d-flex justify-content-between">
    //                                     <h4 className="text-dark fw-bolder mt-4">File Management Module</h4>
    //                                     {/* video */}
    //                                     {/* <Video autoPlay loop muted controls>
    //     <source src={videoFile} type="video/mp4" />
    //   </Video> */}
    //                                     <iframe width="500" height="700" src="https://player.vimeo.com/video/2147559936/video/lkk1MSgR5S6RccBwt7dd_file-management.mp4" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="allowFullScreen"> </iframe>
    //                                     <h5 className="fw-bolder"> Introduction </h5>
    //                                     <p className="details">ABP Commercial provides the file management module that can be used to upload, download, and organize files in a folder structure. The framework provides an abstraction to work with BLOBs and provides some pre-built storage providers that you can easily integrate to.</p>
    //                                     <p className="details">It also supports multi-tenancy, and for each tenant, you can set the total size limit.</p>
    //                                     <p className="details">The file management module uses the  BLOB Storing system, so it can use different storage providers to store the file contents. You can easily change your BLOB storage without changing application code.</p>
    //                                     <p className="details">If you want to create reusable application modules, you don't need to make assumption about how the BLOBs are stored.</p>
    //                                     <p className="details">You can easily integrate to your favorite BLOB storage provides with a few lines of configuration.</p>
    //                                     <p className="details">In this article HireTechTeam ABP developer shows how to integrate the module into an ABP commercial Angular application.</p>
    //                                     <h4 className="text-dark fw-bolder mt-4">Getting started </h4>
    //                                     <p className={`fw-bolder ${!isLoggedIn && 'opacity-25'}`} >Installing the ABP File management module </p>
    //                                     <p className={`details ${!isLoggedIn && 'opacity-25'}`} > First need to install file management module .</p>

    //                                     {!isLoggedIn &&
    //                                         <div className="row bg-light mb-5">
    //                                             <div className="col-lg-4">
    //                                                 <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
    //                                             </div>
    //                                             <div className="col-lg-8">
    //                                                 <h6 className="mt-3">To read the full post,</h6>
    //                                                 <h4 className="fw-bolder">Sign up to AAA Academy</h4>
    //                                                 <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
    //                                                 <Link to={`/signUp`}>
    //                                                     <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
    //                                                 </Link>
    //                                                 <p className="mt-2">Already have an account?
    //                                                     <Link to="/login" className="text-decoration-none color"> Sign in
    //                                                     </Link> </p>
    //                                             </div>
    //                                         </div>}
    //                                     {isLoggedIn && <>
                                            
    //                                         <p className="details">Go to your <strong>Application.csproj</strong> to install this module </p>
    //                                         <p className="details">abp add-module Volo.FileManagement by using this command in command prompt .</p>
    //                                         <p className="details">Then below code will automatically get added in the  .Application.csproj  file</p>
    //                                         <CodeSnippet codeString={packageReference} />
    //                                         <p className="details"> Then in the ApplicationModule below code is automatically added </p>
    //                                         <CodeSnippet codeString={DependsOn} />
    //                                         <p className="details">Next step is to go to DbContext file of EntityFrameworkCore.cs file and add the below code</p>
    //                                         <CodeSnippet codeString={ConfigureFileManagement} />
    //                                         <p className="details">Then, Add IFileManagementDbContext to DbContext and add DbSet</p>
    //                                         <CodeSnippet codeString={DbSet} />
    //                                         <p className="details">Lastly, Add-Migration for file management </p>
    //                                         <h5 className="fw-bolder">Preparing the angular project to use and store File management</h5>
    //                                         <p className="details">After that install @volo/abp.ng.file-management in angular i.e </p>
    //                                         <p className="details">Yarn add @volo/abp.ng.file-management </p>
    //                                         <p className="details">Add setting for blob container in module </p>
    //                                         <CodeSnippet codeString={AbpBlobStoringOptions} />
    //                                         <p className="details">Add API reference in appsettings in webgateway and internal gateway</p>
    //                                         <CodeSnippet codeString={DownstreamPathTemplate} />
    //                                         <p className="details">Next you need to  Add path inroute.provider.ts file in angular</p>
    //                                         <CodeSnippet codeString={requiredPolicy} />
    //                                         <p className="details">Add path reference in app-routing.module.ts</p>
    //                                         <CodeSnippet codeString={loadChildren} />
    //                                         <p className="details">Next step is to import module in app.module.ts file in app </p>
    //                                         <CodeSnippet codeString={FileManagementConfigModule} />
    //                                         <p className="details">This results in adding the File management view as shown in the figure below. This view provides the features for  List, view all folder structure and files.</p>
    //                                         <img className="py-3" src={fileManagement} alt="File Management" />
    //                                         <p className="details">It enables uploading any type of files and offers a thumbnail preview of the files. </p>
    //                                         <img className="py-3" src={uploadFiles} alt="Upload Files" />
    //                                         <h5 className="fw-bolder">Conclusion</h5>
    //                                         <p className="details">ABP commercials File management module is easy to integrate into the application and provides useful features for managing files on the BLOB storage.</p></>}
    //                                     </div>
    //                                     <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
    //                                     <div className="py-3">
    //                                         <h4 className="text-dark fw-bolder">Featured Comments</h4>
    //                                         <div className="d-flex align-items-center">
    //                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                 <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                     <div>
    //                                                         <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
    //                                                         <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
    //                                                     </div>
    //                                                 </span>
    //                                             </div>
    //                                         </div>
    //                                         <p className="text-muted mx-5">Reply</p>

    //                                         <div className="d-flex align-items-center">
    //                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                 <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                     <div>
    //                                                         <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
    //                                                         <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
    //                                                     </div>
    //                                                 </span>
    //                                             </div>
    //                                         </div>
    //                                         <p className="text-muted mx-5">Reply</p>

    //                                         <div className="d-flex align-items-center mx-5">
    //                                             <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                 <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                 <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                     <div>
    //                                                         <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
    //                                                         <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
    //                                                     </div>
    //                                                 </span>
    //                                             </div>
    //                                         </div>
    //                                         <p className="text-muted mx-5">Reply</p>
    //                                     </div>
    //                                     <div className="row aarw">
    //                                         <div className="col-12">
    //                                             <h5 className="related-title">More Articles from Joe Thomson</h5>
    //                                         </div>
    //                                         <div className="col-12">
    //                                             <div className="col-12 col-md-6 col-lg-4 mb-4">

    //                                                 <div className="card-aa ca1 border-0 h-100">
    //                                                     <Link className="nav-link" to="/footer">
    //                                                         <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
    //                                                         <div className="card-body-aa px-3">
    //                                                             <div className="row mb-2">
    //                                                                 <div className="col-md-8">
    //                                                                     <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
    //                                                                 </div>
    //                                                                 <div className="col-md-4 card-date mt-1">19/02/2023</div>
    //                                                             </div>
    //                                                             <div className="position mb-2">Software Development</div>

    //                                                             <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
    //                                                             <div className="d-flex justify-content-between">
    //                                                                 <p className="font-colored">Beginner -5 min</p>
    //                                                                 <div>
    //                                                                     <div className="rate p-0">
    //                                                                         <span className="font-normal">4.5</span>
    //                                                                         <input type="radio" id="star5" name="rate" value="5" />
    //                                                                         <label htmlFor="star5" title="text">5 stars</label>
    //                                                                         <input type="radio" id="star4" name="rate" value="4" />
    //                                                                         <label htmlFor="star4" title="text">4 stars</label>
    //                                                                         <input type="radio" id="star3" name="rate" value="3" />
    //                                                                         <label htmlFor="star3" title="text">3 stars</label>
    //                                                                         <input type="radio" id="star2" name="rate" value="2" />
    //                                                                         <label htmlFor="star2" title="text">2 stars</label>
    //                                                                         <input type="radio" id="star1" name="rate" value="1" />
    //                                                                         <label htmlFor="star1" title="text">1 star</label>
    //                                                                     </div>
    //                                                                     <span className="font-normal">520</span>
    //                                                                 </div>

    //                                                             </div>
    //                                                             <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
    //                                                         </div>
    //                                                     </Link>
    //                                                 </div>

    //                                             </div>
    //                                             <div className="col-12 col-md-6 col-lg-4 mb-4">

    //                                                 <div className="card-aa ca1 border-0 h-100">
    //                                                     <Link className="nav-link" to="/footer">
    //                                                         <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
    //                                                         <div className="card-body-aa px-3">
    //                                                             <div className="row mb-2">
    //                                                                 <div className="col-md-8">
    //                                                                     <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
    //                                                                 </div>
    //                                                                 <div className="col-md-4 card-date mt-1">19/02/2023</div>
    //                                                             </div>
    //                                                             <div className="position mb-2">Software Development</div>

    //                                                             <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
    //                                                             <div className="d-flex justify-content-between">
    //                                                                 <p className="font-colored">Beginner -5 min</p>
    //                                                                 <div>
    //                                                                     <div className="rate p-0">
    //                                                                         <span className="font-normal">4.5</span>
    //                                                                         <input type="radio" id="star5" name="rate" value="5" />
    //                                                                         <label htmlFor="star5" title="text">5 stars</label>
    //                                                                         <input type="radio" id="star4" name="rate" value="4" />
    //                                                                         <label htmlFor="star4" title="text">4 stars</label>
    //                                                                         <input type="radio" id="star3" name="rate" value="3" />
    //                                                                         <label htmlFor="star3" title="text">3 stars</label>
    //                                                                         <input type="radio" id="star2" name="rate" value="2" />
    //                                                                         <label htmlFor="star2" title="text">2 stars</label>
    //                                                                         <input type="radio" id="star1" name="rate" value="1" />
    //                                                                         <label htmlFor="star1" title="text">1 star</label>
    //                                                                     </div>
    //                                                                     <span className="font-normal">520</span>
    //                                                                 </div>

    //                                                             </div>
    //                                                             <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
    //                                                         </div>
    //                                                     </Link>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="col-12 col-md-6 col-lg-4 mb-4">

    //                                                 <div className="card-aa ca1 border-0 h-100">
    //                                                     <Link className="nav-link" to="/footer">
    //                                                         <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
    //                                                         <div className="card-body-aa px-3">
    //                                                             <div className="row mb-2">
    //                                                                 <div className="col-md-8">
    //                                                                     <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
    //                                                                 </div>
    //                                                                 <div className="col-md-4 card-date mt-1">19/02/2023</div>
    //                                                             </div>
    //                                                             <div className="position mb-2">Software Development</div>

    //                                                             <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
    //                                                             <div className="d-flex justify-content-between">
    //                                                                 <p className="font-colored">Beginner -5 min</p>
    //                                                                 <div>
    //                                                                     <div className="rate p-0">
    //                                                                         <span className="font-normal">4.5</span>
    //                                                                         <input type="radio" id="star5" name="rate" value="5" />
    //                                                                         <label htmlFor="star5" title="text">5 stars</label>
    //                                                                         <input type="radio" id="star4" name="rate" value="4" />
    //                                                                         <label htmlFor="star4" title="text">4 stars</label>
    //                                                                         <input type="radio" id="star3" name="rate" value="3" />
    //                                                                         <label htmlFor="star3" title="text">3 stars</label>
    //                                                                         <input type="radio" id="star2" name="rate" value="2" />
    //                                                                         <label htmlFor="star2" title="text">2 stars</label>
    //                                                                         <input type="radio" id="star1" name="rate" value="1" />
    //                                                                         <label htmlFor="star1" title="text">1 star</label>
    //                                                                     </div>
    //                                                                     <span className="font-normal">520</span>
    //                                                                 </div>

    //                                                             </div>
    //                                                             <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
    //                                                         </div>
    //                                                     </Link>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
    //                                     <div className="row d-flex justify-content-between py-3">
    //                                         <div className="col-md-6">
    //                                             <div className="d-flex align-items-center">
    //                                                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                     <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                     <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                         <div>
    //                                                             <span>Kirti Kulkarni</span>
    //                                                         </div>
    //                                                     </span>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className="col-md-6">
    //                                             <div className="align-items-end d-flex justify-content-end">

    //                                                 <div className="footer-social d-flex gap-0">
    //                                                     <Link to="https://www.linkedin.com/company/hire-tech-team/">
    //                                                         <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                                                             <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
    //                                                         </svg>
    //                                                     </Link>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <h6 className="about-author">ABOUT THE AUTHOR</h6>
    //                                     <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
    //                                     <div>
    //                                         <h5 className="fw-medium mb-3">Latest Articles</h5>
    //                                         <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
    //                                             <div className="card mb-3 px-3 py-2">
    //                                                 <div className="row g-0">
    //                                                     <div className="col-md-8">
    //                                                         <h5 className="card-title">
    //                                                             <div className="d-flex align-items-center">
    //                                                                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                                     <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                                     <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                                         <div>
    //                                                                             <span className="fs-6">Kirti Kulkarni</span>
    //                                                                         </div>
    //                                                                     </span>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </h5>
    //                                                         <p className="card-text details">Work with PDF's in ABP Commercial Project using PDFTron</p>
    //                                                     </div>
    //                                                     <div className="col-md-4">
    //                                                         <div className="card-body">
    //                                                             <img src={pdfTronImg} className="img-fluid" alt="Work with PDF's in ABP Commercial Project using PDFTron" />
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </Link>
    //                                         <Link className="nav-link" to="/blog/createCustomLoginPageAbp">
    //                                             <div className="card mb-3 px-3 py-2">
    //                                                 <div className="row g-0">
    //                                                     <div className="col-md-8">
    //                                                         <h5 className="card-title">
    //                                                             <div className="d-flex align-items-center">
    //                                                                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                                     <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                                     <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                                         <div>
    //                                                                             <span className="fs-6">Kirti Kulkarni</span>
    //                                                                         </div>
    //                                                                     </span>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </h5>
    //                                                         <p className="card-text details">Create a custom login page in ABP Commercial Angular app</p>
    //                                                     </div>
    //                                                     <div className="col-md-4">
    //                                                         <div className="card-body">
    //                                                             <img src={customLoginPageImg} className="img-fluid" alt="Create a custom login page in ABP Commercial Angular app" />
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </Link>
    //                                         <Link className="nav-link" to="/blog/deployingAbpAngularAppAzure">
    //                                             <div className="card mb-3 px-3 py-2">
    //                                                 <div className="row g-0">
    //                                                     <div className="col-md-8">
    //                                                         <h5 className="card-title">
    //                                                             <div className="d-flex align-items-center">
    //                                                                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                                                                     <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                                                                     <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                                                                         <div>
    //                                                                             <span className="fs-6">Kirti Kulkarni</span>
    //                                                                         </div>
    //                                                                     </span>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </h5>
    //                                                         <p className="card-text details">Deploying ABP angular application to Azure and App Insights integration</p>
    //                                                     </div>
    //                                                     <div className="col-md-4">
    //                                                         <div className="card-body">
    //                                                             <img src={deployingAbpImg} className="img-fluid" alt="Deploying ABP angular application to Azure and App Insights integration" />
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </Link>
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                         </div>
    //                 </section>
    //             </Container>

    //         </div>



    //     </Layout>

    );
};

export default SamplePage;
