import React from 'react';
import { navigate } from 'gatsby';
// import * as styles from './BlogPreview.module.css';
import './blogPreviewNEW.css'

const BlogPreview = (props) => {
  const { avatarImage, cardTitle, cardDescription, cardImage, date, role, updatedTime, link, showExcerpt, excerpt, userName } =
    props;

  const handleClick = () => {
    navigate(link);
  };

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    <div className="" onClick={handleClick}>
      {/* <Link to={link}></Link> */}
      <div className="col card-aa h-100 " >
        <img className="border-2 border-bottom border-dark border-opacity-10 card-img-top rounded-top" src={cardImage} alt="Implement Single Sign-On with ABP commercial" />
        <div className="card-body-aa px-3 pt-4 pb-4">
          <div className="row">
            <div className="col-md-8">
              <img src={avatarImage} alt="username Avatar" className="avatar w-10" /><span className="user-name mt-2 ms-2">{userName}</span>
            </div>
            <div className="col-md-4 card-date ps-lg-4">{date}</div>
          </div>
          <div className="position py-1 ">{role}</div>
          <h6 className="aa-card-title mb-2 lh-sm">{cardTitle}</h6>
          <div className="d-flex justify-content-between">
            <p className="font-colored pt-1">{updatedTime}</p>
            <div>
              <div className="rate pt-0 pe-0">
                <span className="font-normal pe-1">4.5</span>
                <input type="radio" id="star5" name="rate" value="5" />
                <label htmlFor="star5" title="text">5 stars</label>
                <input type="radio" id="star4" name="rate" value="4" />
                <label htmlFor="star4" title="text">4 stars</label>
                <input type="radio" id="star3" name="rate" value="3" />
                <label htmlFor="star3" title="text">3 stars</label>
                <input type="radio" id="star2" name="rate" value="2" />
                <label htmlFor="star2" title="text">2 stars</label>
                <input type="radio" id="star1" name="rate" value="1" />
                <label htmlFor="star1" title="text">1 star</label>
              </div>
              <span className="font-normal">(520)</span>
            </div>
          </div>
          <p className="cuisinesText pt-0 small lh-base">{cardDescription}<span className="visibility-hidden"> Commercial modular</span></p>
        </div>
      </div>


      {/* <img
        className={styles.blogPreviewImage}
        alt={altImage}
        src={cardImage}
        role={'figure'}
      />
      <span className="user-name">{userName}</span>
      <div className="card-date">{date}</div>
      <div className="position">{role}</div>
     
      <h4 className="aa-card-title">
        <Link to={link}>{cardTitle}</Link>
      </h4>
      <div className="font-colored">{updatedTime}</div>
      
      <p className="card-desc">{cardDescription}</p> */}


      {/* <span className={styles.category}>{category}</span> */}





      {showExcerpt && <p className="">{excerpt}</p>}

    </div>

  );
};

export default BlogPreview;
