import React from 'react';
// import * as styles from './sample.module.css';
// import imageLogo from "../AAA-images/Enter-Password.png"
import relatedblog1 from "../../../static/AAA-images/gradient.png"
import relatedblog2 from "../../../static/AAA-images/Integrating-Elsa-.NET-Workflows-with-ABP-Commercial.png"
import relatedblog3 from "../../../static/AAA-images/Integrating-and-enabling-the-Chat-module-in-ABP-Commercial.png"
import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import createProject from "../../../static/AAA-images/create-new-project.jpg"
import configureProject from "../../../static/AAA-images/configure-new-project.jpg"
import additionalinformation from "../../../static/AAA-images/additional-information.jpg"
import requiredcomponents from "../../../static/AAA-images/required-components.jpg"
import serviceDependencies from "../../../static/AAA-images/service-dependencies.jpg"
import microsoftIdentityPlatform from "../../../static/AAA-images/microsoft-identity-platform.jpg"
import appsettings from "../../../static/AAA-images/app-settings.jpg"
import AADSidebar from "../../../static/AAA-images/AAD-sidebar.jpg"
import waiTechnologies from "../../../static/AAA-images/wai-technologies.jpg"
import registerApplication from "../../../static/AAA-images/register-application.jpg"
import appRegistrations from "../../../static/AAA-images/app-registrations.jpg"
import secretKey from "../../../static/AAA-images/secret-key.jpg"
import newClientSecret from "../../../static/AAA-images/secret-key.jpg"
import platformConfigurations from "../../../static/AAA-images/platform-configurations.jpg"
import configurePlatform from "../../../static/AAA-images/configure-platforms.jpg"
import configureWeb from "../../../static/AAA-images/Configure-Web.jpg"
import advanceSetting from "../../../static/AAA-images/advance-setting.jpg"
import solutionExplorer from "../../../static/AAA-images/solution-explorer.jpg"
import xamarinOffData from "../../../static/AAA-images/xamarinOffData.png"
import integratingElsaNetRelatedArticles from "../../../static/AAA-images/integratingElsaNetRelatedArticles.png"
import fileManagementRelatedArticle from "../../../static/AAA-images/fileManagementRelatedArticle.png"
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
// import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
import { useState, useEffect } from "react";
// import AttributeGrid from '../components/AttributeGrid';

const SamplePage = (props) => {
    const codeString = `
    @{ 
       ViewData["Title"] = "Home Page";
       
    }

    <div className="text-center">
    <h3 className="display-6">
        Welcome to Authentication Module
    <h3>       
    <h2> 
        <a  href="http://localhost:4200/dashboard" target="_blank">
        Login ABP</a>    
    </h2>   
    </div>`;
    const OnGetAsync = `
    using Microsoft.AspNetCore.Authentication;
    using Microsoft.AspNetCore.Authentication.MicrosoftAccount;
    using Microsoft.AspNetCore.Identity;
    using Microsoft.AspNetCore.Mvc;
    using Microsoft.AspNetCore.Mvc.RazorPages;
    using Microsoft.Extensions.Options;
    using Owl.reCAPTCHA;
    using System.Threading.Tasks;
    using Volo.Abp.Account.ExternalProviders;
    using Volo.Abp.Account.Public.Web;
    using Volo.Abp.Account.Public.Web.Pages.Account;
    using Volo.Abp.Account.Security.Recaptcha;
    using Volo.Abp.DependencyInjection;
    using Volo.Abp.Security.Claims;      
       namespace AcmeBookStore.Pages.Account
         {
            [Dependency(ReplaceServices = true)]
            [ExposeServices(typeof(LoginModel))]
            public class AppLoginModel : LoginModel
         {
            public AppLoginModel(IAuthenticationSchemeProvider schemeProvider, 
            IOptions accountOptions, IAbpRecaptchaValidatorFactory 
            recaptchaValidatorFactory, IAccountExternalProviderAppService 
            accountExternalProviderAppService, ICurrentPrincipalAccessor 
            currentPrincipalAccessor, IOptions identityOptions, 
            IOptionsSnapshot reCaptchaOptions) : base(schemeProvider, 
            accountOptions, recaptchaValidatorFactory, accountExternalProviderAppService, 
            currentPrincipalAccessor, identityOptions, reCaptchaOptions)
         {
         }
            public override async Task OnGetAsync()
         {
            await base.OnGetAsync();
            return await OnPostExternalLogin(MicrosoftAccountDefaults.AuthenticationScheme);
            }
          }
        }
          `;
    const addMicrosoft = `
          .AddMicrosoftAccount(MicrosoftAccountDefaults.AuthenticationScheme, "Connect with 
           microsoft", options =>
            {
              options.AuthorizationEndpoint = 
              "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
              options.TokenEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/token";
              options.ClientId = "7bca7c20-2007-4001-b988-fdd1ff4872ad";
               options.ClientSecret = "JvE7Q~OqJedaFkRI5XuQqo3dMOEUaC5VD.vy1";
             })
           `;
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    // useEffect(() => {
    //     const loggedValue = localStorage.getItem('isLoggedIn');
    //     loggedValue === 'true' ? setIsLoggedIn(true) : setIsLoggedIn(false);
    //     console.log('isLoggedIn', isLoggedIn);
    // }, []);

    // function onLogout() {
    //     localStorage.setItem('isLoggedIn', 'false');
    //     setIsLoggedIn(false);
    // }
    return (


        <Layout >
             {/* <Layout isLoggedIn={isLoggedIn} isLoggedOutFn={onLogout}></Layout> */}
            <div className="">
                <Container>
                    <div className="">
                        <section className='mb-5'>
                            <div className="container mt-5">
                                <div className="row ">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                                        <div className="row d-flex justify-content-between py-2">
                                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                                <div className="d-flex align-items-center">
                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                            <div>
                                                                <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                                                <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                                                    <p className="mb-0 small text-muted"> April 22, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                                                    <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                                                        <div className='d-flex align-items-center gap-1'>
                                                                            <span className="font-normal pe-1">5</span>
                                                                            <div className="rate p-0">
                                                                                <input type="radio" id="star5" name="rate" value="5" />
                                                                                <label htmlFor="star5" title="text">5 stars</label>
                                                                                <input type="radio" id="star4" name="rate" value="4" />
                                                                                <label htmlFor="star4" title="text">4 stars</label>
                                                                                <input type="radio" id="star3" name="rate" value="3" />
                                                                                <label htmlFor="star3" title="text">3 stars</label>
                                                                                <input type="radio" id="star2" name="rate" value="2" />
                                                                                <label htmlFor="star2" title="text">2 stars</label>
                                                                                <input type="radio" id="star1" name="rate" value="1" />
                                                                                <label htmlFor="star1" title="text">1 star</label>
                                                                            </div>
                                                                            <span className="font-normal ps-1">12</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                                <div className="">

                                                    <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                                                        <Link to="https://www.linkedin.com/company/hire-tech-team/">

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                            </svg>
                                                        </Link>
                                                        <Link to="https://twitter.com/HIRETECHTEAM">
                                                            {/* <a href="https://twitter.com/HIRETECHTEAM"> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                            </svg></Link>
                                                        <Link to="https://vimeo.com">
                                                            {/* <a href="https://vimeo.com"> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                                                            </svg></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <h4 className="text-dark fw-bolder mt-5 mb-4">How to implement Single Sign-On with ABP commercial application</h4>

                                            <iframe width="100%" height="500" src="https://player.vimeo.com/video/709007960?h=5307bdb198&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="allowFullScreen" title="UDcAvjSgRgagJCRlzeaK_SSO_POC_TK03_2_" data-ready="true" className="p-0"> </iframe>

                                            <h4 className="text-dark fw-bolder mt-5 mb-2">Introduction</h4>
                                            {/* <p className={`details ${!isLoggedIn && 'opacity-25'} mb-5`} >There are lots of reasons for using SSO for custom applications owned by the same enterprise organization.  SSO establishes better user experience, less development time and improved security.

SSO also enables to upgrade a large codebase a piece at a time instead of all at once, you will be able to effectively link them together as if they were one.

In this article, we’ll simulate such a scenario by implementing SSO for an .Net core MVC application and an ABP Commercial modular application.

Through this article you will also learn how the two platforms implement authentication.</p> */}
                                            <p className={`details mb-5`} >There are lots of reasons for using SSO for custom applications owned by the same enterprise organization.  SSO establishes better user experience, less development time and improved security.

                                                SSO also enables to upgrade a large codebase a piece at a time instead of all at once, you will be able to effectively link them together as if they were one.

                                                In this article, we’ll simulate such a scenario by implementing SSO for an .Net core MVC application and an ABP Commercial modular application.

                                                Through this article you will also learn how the two platforms implement authentication.</p>

                                            {/* {!isLoggedIn &&
                                                <div className="row signup-banner py-4 mx-0 mb-5 align-items-center">
                                                    <div className="col-lg-4 text-center mb-4">
                                                        <img className="w-75" src={readPostImg} alt="signup banner Logo" />
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <h6 className="fs-5 fw-light">To read the full post,</h6>
                                                        <h4 className="fw-semibold">Sign up to AAA Academy</h4>
                                                        <p className="py-3 pe-xxl-5 pe-xl-5 pe-lg-5 pe-0">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
                                                        <Link to={`/signup`}>
                                                            <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
                                                        </Link>
                                                        <p className="mt-2 small">Already have an account?
                                                            <Link to="/login" className="text-decoration-none link-primary"> Sign In
                                                            </Link> 
                                                        </p>
                                                    </div>
                                                </div>} */}


                                            {/* {isLoggedIn && <> */}

                                                <img className="py-3 mb-5" src={imageLogo} alt="Logo" />





                                                <img className="py-3 mb-5" src={createProject} alt="createProject" />
                                                <img className="py-3" src={configureProject} alt="configureProject" />

                                                <p className="details py-4 mb-0">This application will be with Authentication Type Microsoft Identity platform.</p>
                                                <img className="py-3" src={additionalinformation} alt="additionalinformation" />
                                                <p className="details py-4 mb-0">Install required components like dotnet msidentity tool.</p>
                                                <img className="py-3 mb-5" src={requiredcomponents} alt="required Components" />
                                                <img className="py-3" src={serviceDependencies} alt="Service Dependencies" />
                                                <p className="details mb-0 py-4">Update dependencies in Visual Studio. After updating, entries are generated in the appsettings.json file for Domain, ClientID etc.</p>
                                                <img className="py-3 mb-5" src={microsoftIdentityPlatform} alt="Microsoft Identity Platform" />
                                                <img className="py-3" src={appsettings} alt="App Settings" />
                                                <p className="details py-4 mb-4">Add link on the Index page of the application that will redirect the user to the ABP application  </p>
                                                <CodeSnippet className="mb-5" codeString={codeString} />
                                                <h4 className="fw-bolder mb-2 pt-5"> Creating an application in Azure Active Directory</h4>
                                                <ol className="ms-3">
                                                    <li>
                                                        <Link to="https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?redirect_uri=https%3A%2F%2Fportal.azure.com%2Fsignin%2Findex%2F&response_type=code%20id_token&scope=https%3A%2F%2Fmanagement.core.windows.net%2F%2Fuser_impersonation%20openid%20email%20profile&state=OpenIdConnect.AuthenticationProperties%3DgSk8RD7T2IxO5kxCwzhqofRQShrb3xr101PKW84CHfuCWuqfGSz6XJNmhzdvL1Of-SZTZNzkoCdEEOAddmDzxtMeX5jZ8hRbG8nzdoQdzCzPINi_W6zvyNBZlmFPVcmjGjyMJnVy7-BH0ZXEsa-yAFNXnrrIPplD9taZe_sJLYxUpkeO3uZ4Y1AsEYV1fC1_0h5vPPKECXkHiAN_vYHtUrDrEIeamvLFP_jR1UxE7RUSEtXnypfDnPta1TjGSBOxZzm4EO1X2xi7zuy3WeGQuPgAzpMNK6-sAgj-HeBC1ExTk3M4pXOt5Lxi7DItOeNvLqmC2BJXd-Al4wJ5m-r2IqkjwZs7qliwB7qxAArnKYW87sC1FVV40NbmJRBFdQr_7J8momRNGPYbIgkf_BrELsAalC5s6BJucTR-OorhDX5la0Mlp62deEoS1QOlfvMJBFekbHr6JqdgCHVIBabe5STBaEn9SN9TglLQH9_AZ2u-It4qHMtZxsxvDAbtjrmI&response_mode=form_post&nonce=638169749830587416.NmRkZDJkZmYtZDY5Yi00OGEyLTk0YjAtMjQ2NDA4NzA1YjMyOTQwMTQyODMtODc4Mi00NGQ0LTkzYjgtOWY0MDFiYzIzYmE4&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&site_id=501430&client-request-id=01469e75-5556-4dec-877b-c2b3d9eaf0fa&x-client-SKU=ID_NET472&x-client-ver=6.27.0.0" target="_blank" rel="noopener noreferrer" className="text-decoration-none link-primary">
                                                            Login into your Microsoft Azure account
                                                        </Link>
                                                    </li>
                                                    <li>Select Azure Active directory in the left sidebar</li>
                                                </ol>

                                                <img className="pt-5" src={AADSidebar} alt="AAD Sidebar" />
                                                <p className="details mb-2 pt-5">Click + Add.</p>
                                                <p className="details mb-5">Click on App registration</p>
                                                <img className="pb-3" src={waiTechnologies} alt="Wai Technologies" />
                                                <p className="details py-4">Enter the application name</p>
                                                <img className="py-3" src={registerApplication} alt="Register Application" />
                                                <p className="details pt-4 mb-5">Click on Register button.</p>
                                                <h4 className="fw-bolder mb-2">Getting the Client secret </h4>
                                                <p className="details mb-3">Select the application which you have created.</p>
                                                <img className="pt-3 mb-4" src={appRegistrations} alt="App Registrations" />
                                                <p className="details mb-3">Click on Add a certificate or Secret</p>
                                                <img className="pt-3 mb-4" src={secretKey} alt="Secret Key" />
                                                <p className="details mb-3">Click on +New client secret</p>
                                                <img className="pt-3 mb-4" src={newClientSecret} alt="New Client Secret" />
                                                <p className="details mb-3">Click on Add button</p>
                                                <img className="pt-3 mb-4" src={platformConfigurations} alt="Platform Configurations" />
                                                <p className="details mb-3">Click On Web</p>
                                                <img className="pt-3 mb-4" src={configurePlatform} alt="Congigure Platform" />
                                                <p className="details mb-3">Configure Redirect URL</p>
                                                <img className="pt-3 mb-4" src={configureWeb} alt="Configure Web" />
                                                <p className="details mb-3">Set Advance Setting </p>
                                                <img className="pt-3 mb-5" src={advanceSetting} alt="Advance Setting" />
                                                <h4 className="fw-bolder mb-2">Performing Changes in the ABP Web Application</h4>
                                                <p className="details mb-2">Create a new ABP Commercial application either using the ABP CLI or through ABP Suite by following the steps outlined here</p>
                                                <ul className="ms-3 mb-5">
                                                    <Link to="https://docs.abp.io/en/commercial/latest/abp-suite/create-solution" className="text-decoration-none"><li>abp suite/create solution | Documentation Center | ABP.IO</li></Link>
                                                    <li>Open the solution in visual studio 2022 and expand the Http.Api.Host project.</li>
                                                    <li>Create new Razor Page as shown below under Pages - Account   folder</li>
                                                </ul>
                                                <img className="pt-3 mb-3" src={solutionExplorer} alt="Solution Explorer" />
                                                <p className="details py-5">Overwrite the method OnGetAsync()</p>
                                                <CodeSnippet codeString={OnGetAsync} />
                                                <p className="details mb-5 pt-4">Add changes in class AcmeBookStoreHttpApiHostModule </p>
                                                <CodeSnippet codeString={addMicrosoft} />
                                                <p className="details mb-4 pt-5">Now run and test the application and SSO feature.</p>
                                                <h4 className="fw-bolder mb-2">How it all works together</h4>
                                                <p className="details mb-2">Ok. Now that you’ve seen how to enable it is to enable SSO for the two apps but let us now see what is really happening behind the scenes to make it all work together.</p>
                                                <p className="pt-0 details mb-2">Let’s consider that first you log into the ASP.Net core client application, it will redirect you to the Microsoft sign page (identity provider) where you sign in. </p>
                                                <p className="pt-0 details mb-2">After you sign in, a cookie will be set in your browser for the Microsoft domain.</p>
                                                <p className="details mb-5">This cookie keeps you signed into Microsoft identity platform. Then Microsoft will redirect you back to the ASP.Net application with a token which it uses to complete the sign-in process. At this point, a cookie is also set for the applications domain. </p>
                                                {/* </>} */}
                                            
                                        </div>
                                      
                                        <div className="py-3 mt-3">
                                            <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                    <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                            <span className="featured-date ms-4">Today at 5:42PM</span>
                                                            <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                            <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                    <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                            <span className="featured-date ms-4">Today at 5:42PM</span>
                                                            <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                            <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mx-5">
                                                <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                                                    <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                        <div>
                                                            <span className='fs-6 fw-bolder'>Joe Thomson</span>
                                                            <span className="featured-date ms-4">Today at 5:42PM</span>
                                                            <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                                                            <input type='text' className='subscribe border-0' placeholder='Reply' />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row aarw">
                                            <div className="col-12">
                                                <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/xamarinOfflineDataSync">
                                                        <img className="card-img-top" src={relatedblog1} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Xamarin Offline data sync with Mongo Realm and RabbitMQ</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12 min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article you will learn how to integrate Realm in the Xamarin Forms project and sync the offline...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/integratingElsaNet">
                                                        <img className="card-img-top" src={relatedblog2} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">14/06/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Integrating Elsa .NET Workflows with ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner - 12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article we have demonstrated about how to integrate .NET 5 Open-Source Workflow...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/integratingAndEnablingChat">
                                                        <img className="card-img-top" src={relatedblog3} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">12/10/2023</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Integrating and enabling the Chat module in ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner - 12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">This module implements real time messaging between users for an application.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                                        <div className="row d-flex justify-content-between py-3 align-items-center">
                                            <div className="col-md-6">
                                                <div className="d-flex align-items-center">
                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                            <div>
                                                                <span>Kirti Kulkarni</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="align-items-end d-flex justify-content-end">
                                                    <div className="footer-social d-flex gap-0">
                                                        <Link to="https://www.linkedin.com/company/hire-tech-team/">
                                                            {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                                        <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the ‘Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                                        <div>
                                            <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                                            <Link className="nav-link" to="/blog/xamarinOfflineDataSync">
                                                <div className="card mb-3 px-3 py-2">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                            <h5 className="card-title">
                                                                <div className="d-flex align-items-center">
                                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                            <div>
                                                                                <span className="fs-6">Kirti Kulkarni</span>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </h5>
                                                            <p className="card-text details fw-bold lh-sm">Xamarin Offline data sync with Mongo Realm and RabbitMQ</p>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                            <div className="card-body p-0">
                                                                <img src={xamarinOffData} className="img-fluid" alt="xamarin Offline data" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link className="nav-link" to="/blog/integratingElsaNet">
                                                <div className="card mb-3 px-3 py-2">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                            <h5 className="card-title">
                                                                <div className="d-flex align-items-center">
                                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                            <div>
                                                                                <span className="fs-6">Kirti Kulkarni</span>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </h5>
                                                            <p className="card-text details fw-bold lh-sm">Integrating Elsa .NET Workflows with ABP Commercial</p>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                            <div className="card-body p-0">
                                                                <img src={integratingElsaNetRelatedArticles} className="img-fluid " alt="Integrating Elsa Net Related Articles" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link className="nav-link" to="/blog/fileManagementModule">
                                                <div className="card mb-3 px-3 py-2">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                                                            <h5 className="card-title">
                                                                <div className="d-flex align-items-center">
                                                                    <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                                                        <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                                                        <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                                                            <div>
                                                                                <span className="fs-6">Kirti Kulkarni</span>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </h5>
                                                            <p className="card-text details fw-bold lh-sm">File Management Module</p>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                                                            <div className="card-body p-0">
                                                                <img src={fileManagementRelatedArticle} className="img-fluid" alt="File Management Related Article" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
               
                    </div>
                </Container>

            </div>

       

        </Layout>

    );
};

export default SamplePage;
