import React, { useState } from 'react'
import { Link } from 'gatsby'
import Fuse from 'fuse.js'
import blogJson from '../../helpers/blog.json';
// import data from '../blog.json';
// C:\Users\TusharMadane\Desktop\AAA-Revamp\Gatsby-AAA-Revamp\src\helpers\blog.json

const SearchPage = () => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])

  const search = () => {
    const options = {
      includeScore: true,
      keys: ['cardTitle'],
    }
    const fuse = new Fuse(blogJson, options)
    const result = fuse.search(query)
    setResults(result)
  }
  const handleKeyUp = (event) => {
    search()
  }


  return (
    <div>
         <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={handleKeyUp} 
      />
      {/* <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} /> */}
      {/* <button onClick={search}>Search</button> */}
      {results.map((result) => (
        <div key={result.item.id}>
          <Link to={`${result.item.link}`}>{result.item.cardTitle}</Link>
        </div>
      ))}
    </div>
  )
}

export default SearchPage
