import React from 'react';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import { Link } from "gatsby";
import './sample.css'
import CodeSnippet from "./CodeSnippet";
// import imageLogo from "../../../static/AAA-images/Enter-Password.png"
import DataSync from "../../../static/AAA-images/dataSync.png"
import getStartedFree from "../../../static/AAA-images/getStartedFree.png"
import organizations from "../../../static/AAA-images/organizations.png"
import selectOrganizations from "../../../static/AAA-images/selectOrganizations.png"
import createNewProject from "../../../static/AAA-images/createNewProject.png"
import CreateNewDatabase from "../../../static/AAA-images/CreateNewDatabase.png"
import newDatabase from "../../../static/AAA-images/newDatabase.png"
import createAppInMongoDB from "../../../static/AAA-images/createAppInMongoDB.png"
import addNuGetPackage from "../../../static/AAA-images/addNuGetPackage.png"
import xamarin from "../../../static/AAA-images/xamarin.png"
import addData from "../../../static/AAA-images/addData.png"
import createEntriesForm from "../../../static/AAA-images/createEntriesForm.png"
import createEntriesJS from "../../../static/AAA-images/createEntriesJS.png"
import createEntriesUsing from "../../../static/AAA-images/createEntriesUsing.png"
import appXAML from "../../../static/AAA-images/appXAML.png"
import addObjectsUserLogin from "../../../static/AAA-images/addObjectsUserLogin.png"
import databaseDeployments from "../../../static/AAA-images/databaseDeployments.png"
import databaseCollections from "../../../static/AAA-images/databaseCollections.png"
import addModels from "../../../static/AAA-images/addModels.png"
import classDataFlow from "../../../static/AAA-images/classDataFlow.png"
import appSettingsJson from "../../../static/AAA-images/appSettingsJson.png"
import createNewTenant from "../../../static/AAA-images/createNewTenant.png"
import mongoDBCluster from "../../../static/AAA-images/mongoDBCluster.png"
import createNewProjDbSyncWorker from "../../../static/AAA-images/createNewProjDbSyncWorker.jpg"
import packageReference from "../../../static/AAA-images/packageReference.jpg"
import createGenericRepoClass from "../../../static/AAA-images/createGenericRepoClass.jpg"
import implementIRepoInterface from "../../../static/AAA-images/implementIRepoInterface.jpg"
import createModalClass from "../../../static/AAA-images/createModalClass.jpg"
import integrationEventLogs from "../../../static/AAA-images/integrationEventLogs.jpg"
import waiDbSyncRepo from "../../../static/AAA-images/waiDbSyncRepo.jpg"
import createContectClass from "../../../static/AAA-images/createContectClass.jpg"
import UOMImplementation from "../../../static/AAA-images/UOMImplementation.jpg"
import configureAppsettings from "../../../static/AAA-images/configureAppsettings.jpg"
import configureAppsettingClassWorker from "../../../static/AAA-images/configureAppsettingClassWorker.jpg"
import createWorkerClass from "../../../static/AAA-images/createWorkerClass.jpg"
import addASyncMethod from "../../../static/AAA-images/addASyncMethod.jpg"
import configureService from "../../../static/AAA-images/configureService.jpg"
import addPackage from "../../../static/AAA-images/addPackage.jpg"
import addClassMessageHandler from "../../../static/AAA-images/addClassMessageHandler.jpg"
import doWorkMethod from "../../../static/AAA-images/doWorkMethod.jpg"
import postInitializeMethod from "../../../static/AAA-images/postInitializeMethod.jpg"
import changesInMyTraining from "../../../static/AAA-images/changesInMyTraining.jpg"
import integratingElsaImg from "../../../static/AAA-images/integratingElsaImg.png"
import fileManagementImg from "../../../static/AAA-images/fileManagementImg.png"
import pdfTronImg from "../../../static/AAA-images/pdfTronImg.jpg"
import readPostImg from "../../../static/AAA-images/Group 147650.svg"
import { useState, useEffect } from "react";
import relatedblog1 from "../../../static/AAA-images/Implement-Single-Sign-On-with-ABP-commercial.png"
import relatedblog2 from "../../../static/AAA-images/Integrating-Elsa-.NET-Workflows-with-ABP-Commercial.png"
import relatedblog3 from "../../../static/AAA-images/Integrating-and-enabling-the-Chat-module-in-ABP-Commercial.png"

const SamplePage = (props) => {
  const privateString = `private const string appId = "xamarin-xgdfn";
    public static Realms.Sync.App RealmApp;`;

  const appConfig = `var appConfig = new AppConfiguration(appId)
    {
        //LogLevel = LogLevel.Debug,
        DefaultRequestTimeout = TimeSpan.FromMilliseconds(1500)
    };
    RealmApp = Realms.Sync.App.Create(appConfig);
    if (App.RealmApp.CurrentUser == null)
    {
        MainPage = new NavigationPage(new LoginPage());
    }
    else
    {
        DependencyService.Register();
    MainPage = new AppShell();
    }`;

  const usingAsyncTask = `//On Top of the Page
    using AsyncTask = System.Threading.Tasks.Task; 
    // Inside class
   
   async void LoginButton_Clicked(object sender, EventArgs e)
   {
       await DoLogin();
   }
   
       private async AsyncTask DoLogin()
   {
       try
      {
   
       var user = await App.RealmApp.LogInAsync
   (Credentials.EmailPassword(email, password));
          if (user != null)
      {
          var projectPage = new AboutPage();
          await Navigation.PushAsync(projectPage);
      }
          else throw new Exception();
      }
          catch (Exception ex)
      {
          await DisplayAlert("Login Failed", ex.Message, "OK");
      }
   }`;

  const usingSystem = `using System.Linq.Expressions;
  
   namespace Wai.DbSync.Interfaces
    {
       public interface IRepository : IDisposable where TEntity : class
      {
       void Add(TEntity obj);
       Task GetById(Guid id);
       Task<IEnumerable> GetAll();
       void Update(TEntity obj, MongoDB.Bson.ObjectId Id);
             
       void Remove(Guid id);
       Task<IEnumerable> Find(Expression<Func<TEntity, bool>> filter);
      }
    }   `;
  const usingMongo = ` using MongoDB.Driver;
    using ServiceStack;
    using System.Linq.Expressions;
    using Wai.DbSync.Interfaces; 
            
    namespace Wai.DbSync.Repository
      
    {
        public abstract class BaseRepository 
    : IRepository where TEntity : class
      {
        protected readonly IMongoContext Context;
        protected IMongoCollection DbSet;
        
        protected BaseRepository(IMongoContext context)
      {
        Context = context;
        DbSet = Context.GetCollection(typeof(TEntity).Name);
      }
        
        public virtual void Add(TEntity obj)
      {
        Context.AddCommand(() => DbSet.InsertOneAsync(obj));
      }
        
        public virtual async Task GetById(Guid id)
      {
        var data = await DbSet.FindAsync(Builders.Filter.Eq("_id", id));
        return data.SingleOrDefault();
      }
        
        public virtual async Task<IEnumerable> GetAll()
      {
        var all = await DbSet.FindAsync(Builders.Filter.Empty);
        return all.ToList();
      }
        
        public virtual async Task<IEnumerable> 
    Find(Expression<Func<TEntity, bool>> filter)
      {
        var all = await DbSet.FindAsync(filter);
        return all.ToList();
      }
        
        public virtual void Update(TEntity obj, MongoDB.Bson.ObjectId Id)
      {         
        Context.AddCommand(() => DbSet.ReplaceOneAsync
    (Builders.Filter.Eq("_id", Id),obj));
        Context.SaveChanges();
        //Context.AddCommand(() => DbSet.ReplaceOneAsync
    (Builders.Filter.Eq("_id", obj.GetId()), obj));
      }
              
        
        public virtual void Remove(Guid id)
      {
        Context.AddCommand(() => DbSet.DeleteOneAsync
    (Builders.Filter.Eq("_id", id)));
      }
        
        public void Dispose()
      {
        Context?.Dispose();
      }
    
     }
     
    }`;
  const usingMongoDb = ` using MongoDB.Bson;
     using MongoDB.Bson.Serialization.Attributes;
     using System.Text.Json.Serialization;
         
     namespace Wai.DbSync.Model
     {
         public record IntegrationEvent
       {        
         public IntegrationEvent()
       {
         Id = Guid.NewGuid();
         CreationDate = DateTime.UtcNow;
       }
         [JsonConstructor]
         public IntegrationEvent(Guid id, DateTime createDate)
       {
         Id = id;
         CreationDate = createDate;
       }
         [JsonInclude]
         public Guid Id { get; private init; 
       }
         [JsonInclude]
         public DateTime CreationDate { get; private init; }
       }
     }  `;
  const mongoDBBson = `using MongoDB.Bson;
    using MongoDB.Bson.Serialization.Attributes;
    using System.ComponentModel.DataAnnotations.Schema;
    using System.Text.Json;
        
    namespace Wai.DbSync.Model
    {
        public class IntegrationEventLogs
      {
        private IntegrationEventLogs() { }
        public IntegrationEventLogs(IntegrationEvent 
    @event, Guid transactionId)
      {    
        EventId = @event.Id;
        CreationTime = @event.CreationDate.ToLongDateString();
        EventTypeName = @event.GetType().FullName;
        Content = JsonSerializer.Serialize
    (@event, @event.GetType(), new JsonSerializerOptions
      {
        WriteIndented = true
      });
        State = EventStateEnum.NotPublished;
        TimesSent = 0;
        TransactionId = transactionId.ToString();               
      }       
        public byte[] Message { get; set; }
        public dynamic EventId { get; private set; }
        public string EventTypeName { get; private set; }
        [NotMapped]
        public string EventTypeShortName => EventTypeName.Split('.')?.Last();
        [NotMapped]
        public IntegrationEvent IntegrationEvent { get; private set; }
        public EventStateEnum State { get; set; }
        public int TimesSent { get; set; }
        public string CreationTime { get; private set; }
        public string Content { get; private set; }
        public string TransactionId { get; private set; }
        public IntegrationEventLogs DeserializeJsonContent(Type type)
      {
        IntegrationEvent = JsonSerializer.Deserialize
    (Content, type, new JsonSerializerOptions() 
    { PropertyNameCaseInsensitive = true }) as IntegrationEvent;
        return this;
      }
      }
        public enum EventStateEnum
      {
        NotPublished = 0,
        InProgress = 1,
        Published = 2,
        PublishedFailed = 3
      }
    }  `;
  const usingWai = `using Wai.DbSync.Model;
     namespace Wai.DbSync.Interfaces
      {
         public interface IIntegrationEventLogRepository : IRepository
       {
       
       }
     } `;
  const mongoDriver = `using MongoDB.Driver;
    using Wai.DbSync.Interfaces;
        
    namespace Wai.DbSync.Context
    {
        public class MongoContext : IMongoContext
      {
        private IMongoDatabase Database { get; set; }
        public IClientSessionHandle Session { get; set; }
        public MongoClient MongoClient { get; set; }
        private readonly List<Func> _commands;
        private readonly IConfiguration _configuration;
        
        public MongoContext(IConfiguration configuration)
      {
        _configuration = configuration;
        
        // Every command will be stored and it'll be processed at SaveChanges
        _commands = new List<Func>();
      }   
        public async Task SaveChanges()
      {
        ConfigureMongo();
        try
      {
        using (Session = await MongoClient.StartSessionAsync())
      {    
        Session.StartTransaction();
        var commandTasks = _commands.Select(c => c());
        await Task.WhenAll(commandTasks);   
        await Session.CommitTransactionAsync();    
      }
      }
        catch (Exception e)
      {
      }
        return _commands.Count;
      }
        private void ConfigureMongo()
      {
        if (MongoClient != null)
      {
        return; 
      }
        // Configure mongo (You can inject the config, just to simplify)
        MongoClient = new MongoClient
    (_configuration["MongoSettings:Connection"]);
        Database = MongoClient.GetDatabase
    (_configuration["MongoSettings:DatabaseName"]);
      }
        public IMongoCollection GetCollection(string name)
      {
        ConfigureMongo();
        return Database.GetCollection(name);
      }
        public void Dispose()
      {
        Session?.Dispose();
        GC.SuppressFinalize(this);
      }
        public void AddCommand(Func func)
      {
        _commands.Add(func);
      }
      }
    }`;
  const interfacaces = `using Wai.DbSync.Interfaces;
     namespace Wai.DbSync.UoW
     {
         public class UnitOfWork : IUnitOfWork
       {
         private readonly IMongoContext _context;
         public UnitOfWork(IMongoContext context)
       {
         _context = context;
       }  
         public async Task Commit()
       {
         var changeAmount = await _context.SaveChanges();
         return changeAmount > 0;
       } 
         public void Dispose()
       {
         _context.Dispose();
       }
       }
     } `;
  const logging = `{
      "Logging": 
    {
      "LogLevel": 
    {
      "Default": "Information",
      "Microsoft.Hosting.Lifetime": "Information"
    }
    },
      "MongoSettings": 
    {
      "Connection": "mongodb+srv://admin:123qwe@waicluster
  .rtj4h.mongodb.net/admin?retryWrites=true&w=majority?connect=replicaSet",
      "DatabaseName": "IntegrationEventLogs"	
    }
  } `;
  const usingSystemCode = `using System.Text;
     using System.Text.Json;
     using MongoDB.Bson;
     using MongoDB.Driver;
     using RabbitMQ.Client;
     using Wai.DbSync.Interfaces;
         
     namespace DbSyncWorker;
         
     public class Worker : BackgroundService
     {
     private readonly ILogger _logger;
     private readonly IIntegrationEventLogRepository 
     _integrationEventLogRepository;
     private readonly IUnitOfWork _unitOfWork;
     public Worker(ILogger logger, 
     IIntegrationEventLogRepository 
     integrationEventLogRepository, IUnitOfWork unitOfWork)
     {
     _logger = logger;
     _integrationEventLogRepository = integrationEventLogRepository;
     _unitOfWork = unitOfWork;
     }
         
     protected override async Task ExecuteAsync
     (CancellationToken stoppingToken)
     {
     while (!stoppingToken.IsCancellationRequested)
     {      
         
     var docs = await _integrationEventLogRepository
     .Find(x => x.State == 
     Wai.DbSync.Model.EventStateEnum.NotPublished);      
                 
     var factory = new ConnectionFactory() { HostName = "localhost" };
         
     using (var connection = factory.CreateConnection())
     using (var channel = connection.CreateModel())
     {
     channel.QueueDeclare(queue: "hello",
     durable: false,
     exclusive: false,
     autoDelete: false,
     arguments: null);
     var properties = channel.CreateBasicProperties();
     properties.Persistent = false;
     foreach (var doc in docs)
     {
     if (doc.Message is null)
     {
         continue;
     }
     channel.BasicPublish(exchange: string.Empty,
     routingKey: "hello",
     basicProperties: null,
     body: doc.Message);
     doc.State = Wai.DbSync.Model.EventStateEnum.InProgress;
     _integrationEventLogRepository.Update(doc, doc.EventId);
                       
     Console.WriteLine(" [x] Sent {0}", docs.FirstOrDefault()?.Message);
     } 
     }
     await Task.Delay(5000, stoppingToken);
     }
     }
     }`;
  const DbSyncWorker = `using DbSyncWorker;
     using Wai.DbSync.Context;
     using Wai.DbSync.Interfaces;
     using Wai.DbSync.Persistence;
     using Wai.DbSync.Repository;
     using Wai.DbSync.UoW;
         
     IHost host = Host.CreateDefaultBuilder(args)
     .ConfigureServices(services =>
     {
     MongoDbPersistence.Configure();
     services.AddTransient<IMongoContext, MongoContext>();
     services.AddTransient<IUnitOfWork, UnitOfWork>();
     services.AddTransient<IIntegrationEventLogRepository, 
     IntegrationEventLogRepository>();
     services.AddHostedService();
     })
     .Build();
     await host.RunAsync();`;


  const classMessageHandler = `using System;
     using System.Collections.Generic;
     using System.Linq;
     using System.Text;
     using System.Threading.Tasks;
     using Abp.Dependency;
     using Abp.Domain.Repositories;
     using Abp.Domain.Uow;
     using Abp.Threading.BackgroundWorkers;
     using Abp.Threading.Timers;
     using MyTraining1110Demo.Guitars;
     using Newtonsoft.Json;
     using RabbitMQ.Client;
     using RabbitMQ.Client.Events;
     namespace MyTraining1110Demo.RabbitMq
     {
     public class MessageHandler : 
     PeriodicBackgroundWorkerBase, ISingletonDependency
     {
     private readonly IRepository _repository;
     private readonly IUnitOfWorkManager _unitOfWorkManager;
     public bool flg = false;
     public MessageHandler
     (AbpTimer timer, IRepository 
     repository, IUnitOfWorkManager unitOfWorkManager) : base(timer)
     {
     Timer.Period = 10000; //5 seconds 
     (good for tests, but normally will be more)
     _repository = repository;
     _unitOfWorkManager = unitOfWorkManager;
     }
     [UnitOfWork]
     protected override void DoWork()
     {
     var factory = new ConnectionFactory() 
     { HostName = "localhost", DispatchConsumersAsync = true };
     using (var connection = factory.CreateConnection())
     using (var channel = connection.CreateModel())
     {
     channel.QueueDeclare(queue: "hello",
     durable: true,
     exclusive: false,
     autoDelete: false,
     arguments: null);
     var consumer = new AsyncEventingBasicConsumer(channel);
     consumer.Received += async (model, ea) =>
     {
     using (var uow = _unitOfWorkManager.Begin
     (System.Transactions.TransactionScopeOption.RequiresNew))
     {
     try
     {
     var body = ea.Body.ToArray();
     var message = Encoding.UTF8.GetString(body);
     var guitar = JsonConvert.DeserializeObject(message);
     var record = await _repository.InsertAsync(guitar);
     }
     catch (Exception ex)
     {
     }
     await uow.CompleteAsync();
     }
     };
     channel.BasicConsume(queue: "hello",
     autoAck: true,
     consumer: consumer);	
     connection.Close();
     }
     }
     }} `;
  const workManager = `var workManager = IocManager.Resolve();
     workManager.Add(IocManager.Resolve());`;
  const workManagerAdd = ` workManager.Add(IocManager.Resolve()); `;


  
  return (
    <Layout >
      <div className="">
        <Container>
          <div className="">
            <section className='mb-5'>
              <div className="container mt-5">
                <div className="row ">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-lg-5 keep-scrolling">
                    <div className="row d-flex justify-content-between py-2">
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                        <div className="d-flex align-items-center">
                          <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block">
                            <img alt='username avatar' className="avatar bg-light rounded-circle white-box pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 pb-4" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                              <div>
                                <span className="fs-5 fw-bold">Kirti Kulkarni</span>
                                <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-block align-items-center'>
                                  <p className="mb-0 small text-muted"> April 22, 2022<span className="font-colored mb-0 text-muted ms-3">Beginner<span className="ps-2 ms-2">-12 min</span></span></p>
                                  <div className="avatar-initials flex-grow-1 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start d-flex ms-xxl-3 ms-xl-3 ms-lg-3 ms-md-3 ms-0">
                                    <div className='d-flex align-items-center gap-1'>
                                      <span className="font-normal pe-1">5</span>
                                      <div className="rate p-0">
                                        <input type="radio" id="star5" name="rate" value="5" />
                                        <label htmlFor="star5" title="text">5 stars</label>
                                        <input type="radio" id="star4" name="rate" value="4" />
                                        <label htmlFor="star4" title="text">4 stars</label>
                                        <input type="radio" id="star3" name="rate" value="3" />
                                        <label htmlFor="star3" title="text">3 stars</label>
                                        <input type="radio" id="star2" name="rate" value="2" />
                                        <label htmlFor="star2" title="text">2 stars</label>
                                        <input type="radio" id="star1" name="rate" value="1" />
                                        <label htmlFor="star1" title="text">1 star</label>
                                      </div>
                                      <span className="font-normal ps-1">12</span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </span>
                          </div>

                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="">

                          <div className="footer-social d-flex gap-0 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-5 ms-0 align-items-xxl-end align-items-xl-end align-items-lg-end align-items-md-end align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start">
                            <Link to="https://www.linkedin.com/company/hire-tech-team/">

                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                            <Link to="https://twitter.com/HIRETECHTEAM">

                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg></Link>
                            <Link to="https://vimeo.com">

                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
                              </svg></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">

                      {/* Card details */}
                      <h4 className="text-dark fw-bolder mt-5 mb-4">Xamarin Offline data sync with Mongo Realm and RabbitMQ</h4>
                      <iframe title='Xamarin Offline data sync with Mongo Realm and RabbitMQ' width="100%" height="500" src="https:player.vimeo.com/video/709034693?h=2bae0c446e" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="allowFullScreen"> </iframe>
                      <p className="details mb-2 pt-4">In this article we are implementing the following scenario which involves :</p>
                      <ol className="ms-4 mb-5">
                        <li>Net zero Xamarin mobile app</li>
                        <li>Mongo Realm DB on the mobile device</li>
                        <li>Realm Cloud DB</li>
                        <li>RabbitMQ integration</li>
                      </ol>
                      <h5 className="fw-bolder mb-3">Problem Statement</h5>
                      <img className="py-3 mb-4" src={DataSync} alt="Xamarin based data Sync" />
                      <p className="details mb-2">We need the Xamarin based mobile app to work offline and for saving the data offline when there is no internet connection. The app then needs to sync data with the web when the connectivity is established.</p>
                      <p className="details mb-2"> We are using the Realm DB which offers an out of the box data sync feature that will help us to sync the offline data with the web.</p>
                      <p className="details mb-2">The data also needs to be synced with the aspnetzero SQL database.</p>
                      <p className="details mb-5">For this purpose, we are integrating RabbitMQ which triggers a data sync with the SQL database whenever any changes are pushed to Realm cloud.</p>
                      <h5 className="fw-bolder mb-2">Implementation</h5>
                      <p className="details mb-2" >Now let us see how this scenario is implemented.</p>
                      <p className="details mb-5" >1. Create MongoDB account.</p>

                   

                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={getStartedFree} alt="Get Started Free" />
                        <p className="details py-4 px-0">2. Create New Organization or Select Existing Organization</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={organizations} alt="Create New Organization or Select Existing Organization" />
                        <p className="details py-4 px-0">3. Select Organization or Search for an Organization</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={selectOrganizations} alt="Select Organization or Search for an Organization" />
                        <p className="details py-4 px-0">4. Create New Project or Select Existing Project</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={createNewProject} alt="Create New Project" />
                        <p className="details py-4 px-0">5. Create New Database Deployments</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={CreateNewDatabase} alt="Create New Database" />
                        <p className="details py-4 px-0">6. New Database Deployments Created</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={newDatabase} alt="New Database" />
                        <p className="details py-4 px-0">7. Create Application in MongoDB Realm.</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={createAppInMongoDB} alt="Create App In MongoDB" />
                        <p className="details py-4 px-0">8. Add NuGet package ‘Realm’ to all Project Solutions.</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4" src={addNuGetPackage} alt="Add NuGet Package" />
                        <p className="details py-4 px-0">9. Add ‘appId’ in Xamarin File app.xaml.cs so it could interact with Application on MongoDB.</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4 mb-5" src={xamarin} alt="xamarin" />
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4 mb-5" src={appXAML} alt="appXAML Code" />
                        <CodeSnippet codeString={privateString} />
                        <p className="details py-4 px-0">10. Add Data in app.xaml.cs à ‘RealmApp = Realms.Sync.App.Create(appId);’.</p>
                        <img className="border border-1 border-dark border-opacity-25 pt-3 pb-4 mb-5" src={addData} alt="Add Data" />
                        <CodeSnippet codeString={appConfig} />
                        <p className="details py-4 px-0">11.Create Entries and Sync in xaml.cs files and added Realm Package file in them.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={createEntriesForm} alt="Create Entries Form" />
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={createEntriesJS} alt="create Entries JS" />
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={createEntriesUsing} alt="Create Entries Using" />
                        <p className="details py-4 px-0">12. Add Objects and Users in Login and AddItems Page.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5" src={addObjectsUserLogin} alt="Add Objects and users in login and add items page" />
                        <CodeSnippet codeString={usingAsyncTask} />
                        <p className="details py-4 px-0">13. Create new Cluster and Integrated it with Application.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={databaseDeployments} alt="Database Deployments" />
                        <p className="details py-4 px-0">14. Add Database and Collection in MongoDB Atlas à Cluster.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={databaseCollections} alt="Add Database Collections" />
                        <p className="details py-4 px-0">15. Add Models with ‘RealmObject’ and integrated them in xaml.cs files so the data could be passed through them.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5" src={addModels} alt="Add Models" />
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-3" src={classDataFlow} alt="class DataFlow" />
                        <p className="details py-4 px-0">16. Change Port ‘Server’ according to your device in Migrator and Host appsettings.json files</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={appSettingsJson} alt="App Settings Json File" />
                        <p className="details pt-4">17. Run ‘dotnet run’ in Command Prompt of Migrator to migrate data.</p>
                        <p className="details pb-4">18. After running the simulator, enter data in Entries and Sent data to Realm offline database.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3 w-50" src={createNewTenant} alt="Create New Tenant" />
                        <p className="details py-4 px-0">19. After syncing the data is displayed on MongoDB Cluster à Database à Collection after it syncs online.</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={mongoDBCluster} alt="MongoDB Cluster" />
                        <p className="details px-0 mb-2">20. Create Worker Services(background services) project (Producer), which read data from MongoDB and send/publish the messages(data) to Asp.net Zero app (Consumers) using RabbitMQ</p>
                        <p className="details px-0 mb-2">21. net Zero App(Consumer) received the message from producer and synch data into SQL server</p>
                        <p className="details px-0 mb-5">22. RabbitMQ act a communicator middleware between both producer and consumers</p>
                        <h5 className="fw-bolder my-4">Create Services (background services) project (Producer), which read data from MongoDB and send/ Worker publish the messages(data) to Asp.net Zero app (Consumers) using RabbitMQ</h5>
                        <p className="details mb-4">1. Create new Worker Service Project DbSyncWorker(background service)</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={createNewProjDbSyncWorker} alt="Create New Project Db Sync Worker" />
                        <p className="details py-4 px-0">2.  Install Packages View - Other Windows - Package Manager Console</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={packageReference} alt="package Reference" />
                        <p className="details py-4 px-0">3. Create Generic Repository class IRepository.cs class</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={createGenericRepoClass} alt="Create Generic Repo Class" />
                        <CodeSnippet codeString={usingSystem} />
                        <p className="details py-4 px-0">4. Implement IRepository Interface</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={implementIRepoInterface} alt="Implement IRepository Interface" />
                        <CodeSnippet codeString={usingMongo} />
                        <p className="details py-4">5. Create Model class</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={createModalClass} alt="Create Modal Class" />
                        <CodeSnippet codeString={usingMongoDb} />
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={integrationEventLogs} alt="Integration Event Logs" />
                        <CodeSnippet codeString={mongoDBBson} />
                        <p className="details py-4">6. Implement Model</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={waiDbSyncRepo} alt="Wai Db Sync Repo" />
                        <CodeSnippet codeString={usingWai} />
                        <p className="details py-4">7. Create Context class</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={createContectClass} alt="Create Contect Class" />
                        <CodeSnippet codeString={mongoDriver} />
                        <p className="details py-4">8. UOM Implementation</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={UOMImplementation} alt="UOM Implementation" />
                        <CodeSnippet codeString={interfacaces} />
                        <p className="details py-4">9. Configure appsetting.json</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={configureAppsettings} alt="Configure Appsettings" />
                        <CodeSnippet codeString={logging} />
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-2 mt-3" src={configureAppsettingClassWorker} alt="Configure Appsetting Class Worker" />
                        <p className="details py-4"> 10. Create Worker.Cs class</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={createWorkerClass} alt="Create Worker Class" />
                        <CodeSnippet codeString={usingSystemCode} />
                        <p className="details py-4"> 11. Add a synch method ExecuteAsync which is call after every 5 second</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={addASyncMethod} alt="Add ASync Method" />
                        <p className="details py-4 px-0"> 12. Configure Service in Program.cs</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={configureService} alt="Configure Service" />
                        <CodeSnippet codeString={DbSyncWorker} />
                        <h5 className="fw-bolder mb-2 pt-5">Performing Changes in the ASP.Net zero Application. This application received message from producer and synch data into SQL server</h5>
                        <p className="details mb-2">First create a new ASP.Net Zero application either using following the steps outlined here</p>
                        <Link to="https://docs.aspnetzero.com/en/aspnet-core-angular/latest/Getting-Started-Angular" className="text-decoration-none link-primary mb-2">Getting Started Angular | Documentation Center (aspnetzero.com)</Link>
                        <p className="details mb-0">Do changes as per below steps</p>
                        <p className="details mb-5">1. Add package RabbitMQ in asp.net project</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={addPackage} alt="Add Package" />
                        <p className="details py-4">2. Add New Class MessageHandler.cs in Core project</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-4 mt-3" src={addClassMessageHandler} alt="Add Class Message Handler" />
                        <p className="details py-4">3.Add method DoWork in MessageHandler.cs class: Periodic works should be done by implementing this method</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={doWorkMethod} alt="Do Work Method" />
                        <CodeSnippet codeString={classMessageHandler} />
                        <p className="details py-4">4. Add Entry in (ProjectName)Module.cs class of Core project</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={postInitializeMethod} alt="Post Initialize Method" />
                        <CodeSnippet codeString={workManager} />
                        <p className="details py-4">5.Changes in MyTraining1101DemoWebHostModule.cs of WebHost Project as</p>
                        <img className="border border-1 border-dark border-opacity-25 py-3 mb-5 mt-3" src={changesInMyTraining} alt="Changes In MyTraining" />
                        <CodeSnippet codeString={workManagerAdd} />


                    </div>


                    {/* <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div> */}
                    <div className="py-3 mt-3">
                      <h6 className="text-dark fw-bold pb-3">Featured Comments</h6>
                      <div className="d-flex align-items-center">
                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                          <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                          <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                            <div>
                              <span className='fs-6 fw-bolder'>Joe Thomson</span>
                              <span className="featured-date ms-4">Today at 5:42PM</span>
                              <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                              <input type='text' className='subscribe border-0' placeholder='Reply' />
                            </div>
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                          <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                          <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                            <div>
                              <span className='fs-6 fw-bolder'>Joe Thomson</span>
                              <span className="featured-date ms-4">Today at 5:42PM</span>
                              <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                              <input type='text' className='subscribe border-0' placeholder='Reply' />
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mx-5">
                        <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex pb-4">
                          <img alt='username avatar' className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                          <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                            <div>
                              <span className='fs-6 fw-bolder'>Joe Thomson</span>
                              <span className="featured-date ms-4">Today at 5:42PM</span>
                              <p className="fw-normal mb-0 text-black-50"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                              <input type='text' className='subscribe border-0' placeholder='Reply' />
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row aarw">
                      <div className="col-12">
                        <h5 className="fs-4 pb-3 pt-4 related-title">More Articles from Kirti Kulkarni</h5>
                      </div>
                    </div>
                    <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/sample">
                                                        <img className="card-img-top" src={relatedblog1} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt='username avatar' className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">22/04/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
    
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Implement Single Sign-On with ABP commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner -12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">Learn how to implement SSO between an ASP.Net core MVC application and ABP Commercial...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/integratingElsaNet">
                                                        <img className="card-img-top" src={relatedblog2} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt='username avatar' className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">14/06/2022</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Integrating Elsa .NET Workflows with ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner - 12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">In this article we have demonstrated about how to integrate .NET 5 Open-Source Workflow...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                                                <div className="shadow-sm ca1 border-0 h-100">
                                                    <Link className="nav-link" to="/blog/integratingAndEnablingChat">
                                                        <img className="card-img-top" src={relatedblog3} alt="Implement Single Sign-On with ABP commercial" />
                                                        <div className="card-body-aa px-3 pb-3">
                                                            <div className="align-items-center mb-2 pt-2 row">
                                                                <div className="col-md-8">
                                                                    <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt='username avatar' className="avatar rounded-circle w-10" /><span className="user-name m-2">Kirti Kulkarni</span>
                                                                </div>
                                                                <div className="col-md-4 card-date mt-1">12/10/2023</div>
                                                            </div>
                                                            <div className="position mb-2">Software Developer</div>
                                                            <h6 className="aa-card-title mb-2 fs-6 lh-sm">Integrating and enabling the Chat module in ABP Commercial</h6>
                                                            <div className="d-flex justify-content-between align-items-center home-related-card">
                                                                <p className="font-colored">Beginner - 12min</p>
                                                                <div>
                                                                    <div className="rate p-0">
                                                                        <span className="font-normal">4.5</span>
                                                                        <input type="radio" id="star5" name="rate" value="5" />
                                                                        <label htmlFor="star5" title="text">5 stars</label>
                                                                        <input type="radio" id="star4" name="rate" value="4" />
                                                                        <label htmlFor="star4" title="text">4 stars</label>
                                                                        <input type="radio" id="star3" name="rate" value="3" />
                                                                        <label htmlFor="star3" title="text">3 stars</label>
                                                                        <input type="radio" id="star2" name="rate" value="2" />
                                                                        <label htmlFor="star2" title="text">2 stars</label>
                                                                        <input type="radio" id="star1" name="rate" value="1" />
                                                                        <label htmlFor="star1" title="text">1 star</label>
                                                                    </div>
                                                                    <span className="font-normal">520</span>
                                                                </div>
                                                            </div>
                                                            <p className="aa-description lh-sm">This module implements real time messaging between users for an application.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    </div> 
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-12 for-responsive">
                    <div className="row d-flex justify-content-between py-3 align-items-center">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center">
                          <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                            <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                            <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                              <div>
                                <span>Kirti Kulkarni</span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="align-items-end d-flex justify-content-end">
                          <div className="footer-social d-flex gap-0">
                            <Link to="https://www.linkedin.com/company/hire-tech-team/">
                              {/* <a href="https://www.linkedin.com/company/hire-tech-team/" data-toggle="tooltip" data-placement="bottom" title="Subscribe to get an email when Joe Thomson Publishes"> */}
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="about-author fw-semibold">ABOUT THE AUTHOR</p>
                    <p className="details lh-sm">With over 20 years of experience in software development, Kirti heads Product R&D and Competency Management at WAi Technologies, leading the training and skills upgradation program at WAi. Kirti introduced the 'Women Back To Work' Initiative that encourages women to return back to mainstream software development after a career break or sabbatical. </p>
                    <div>
                      <h6 className="fw-medium mb-3 fw-bold pt-4">Latest Articles</h6>
                      <Link className="nav-link" to="/blog/integratingElsaNet">
                        <div className="card mb-3 px-3 py-2">
                          <div className="row g-0 align-items-center">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                              <h5 className="card-title">
                                <div className="d-flex align-items-center">
                                  <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                      <div>
                                        <span className="fs-6">Kirti Kulkarni</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </h5>
                              <p className="card-text details fw-bold lh-sm">Integrating Elsa .NET Workflows with ABP Commercial</p>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                              <div className="card-body p-0">
                                <img src={integratingElsaImg} className="img-fluid" alt="Integrating Elsa Img" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link className="nav-link" to="/blog/fileManagementModule">
                        <div className="card mb-3 px-3 py-2">
                          <div className="row g-0 align-items-center">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                              <h5 className="card-title">
                                <div className="d-flex align-items-center">
                                  <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box " src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                      <div>
                                        <span className="fs-6">Kirti Kulkarni</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </h5>
                              <p className="card-text details fw-bold lh-sm">File Management Module</p>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                              <div className="card-body p-0">
                                <img src={fileManagementImg} className="img-fluid" alt="File Management Module" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
                        <div className="card mb-3 px-3 py-2">
                          <div className="row g-0 align-items-center">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
                              <h5 className="card-title">
                                <div className="d-flex align-items-center">
                                  <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
                                    <img alt='username avatar' className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
                                    <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
                                      <div>
                                        <span className="fs-6">Kirti Kulkarni</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </h5>
                              <p className="card-text details fw-bold lh-sm">Work with PDF's in ABP Commercial Project using PDFTron</p>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
                              <div className="card-body p-0">
                                <img src={pdfTronImg} className="img-fluid" alt="Work with PDF's in ABP Commercial Project using PDFTron" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </section>

          </div>
        </Container>

      </div>



    </Layout>
























    // <Layout isLoggedIn={isLoggedIn} isLoggedOutFn={onLogout}>
    //   <div className="">
    //     <Container>
    //     <section className='mb-5'>
    //       <div className="container mt-5">
    //         <div className="row ">
    //           <div className="col-xxl-8 col-xl-8 col-lg-8 col-12 pe-5 keep-scrolling">
    //             <div className="row d-flex justify-content-between py-2">
    //               <div className="col-md-6">
    //                 <div className="d-flex align-items-center">
    //                   <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                     <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                     <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                       <div>
    //                         <span className="fs-3">Kirti Kulkarni</span>
    //                         <p className="mb-0 text-muted"> April 22, 2022 <span className="font-colored px-2">Beginner<span className="ps-2">-12 min</span> </span>
    //                         </p>
    //                       </div>
    //                     </span>
    //                   </div>
    //                   <div className="avatar-initials flex-grow-1 align-items-end d-flex ms-2">
    //                     <div className="rate p-0 d-flex align-items-end mt-4">
    //                       <span className="font-normal">5</span>
    //                       <input type="radio" id="star5" name="rate" value="5" />
    //                       <label htmlFor="star5" title="text">5 stars</label>
    //                       <input type="radio" id="star4" name="rate" value="4" />
    //                       <label htmlFor="star4" title="text">4 stars</label>
    //                       <input type="radio" id="star3" name="rate" value="3" />
    //                       <label htmlFor="star3" title="text">3 stars</label>
    //                       <input type="radio" id="star2" name="rate" value="2" />
    //                       <label htmlFor="star2" title="text">2 stars</label>
    //                       <input type="radio" id="star1" name="rate" value="1" />
    //                       <label htmlFor="star1" title="text">1 star</label>
    //                     </div>
    //                     <span className="font-normal">12</span>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="align-items-end d-flex justify-content-end">
    //                   <div className="footer-social d-flex gap-0">
    //                     <Link to="https://www.linkedin.com/company/hire-tech-team/">
    //                       {/* <a href="https://www.linkedin.com/company/hire-tech-team/"> */}
    //                       <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                         <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
    //                       </svg>
    //                     </Link>
    //                     <Link to="https://twitter.com/HIRETECHTEAM">
    //                       <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-twitter fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                         <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
    //                       </svg></Link>
    //                     <Link to="https://vimeo.com">
    //                       <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-vimeo fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                         <path d="M15.992 4.204c-.071 1.556-1.158 3.687-3.262 6.393-2.175 2.829-4.016 4.243-5.522 4.243-.933 0-1.722-.861-2.367-2.583L3.55 7.523C3.07 5.8 2.556 4.94 2.007 4.94c-.118 0-.537.253-1.254.754L0 4.724a209.56 209.56 0 0 0 2.334-2.081c1.054-.91 1.845-1.388 2.373-1.437 1.243-.123 2.01.728 2.298 2.553.31 1.968.526 3.19.646 3.666.36 1.631.756 2.446 1.186 2.445.334 0 .836-.53 1.508-1.587.671-1.058 1.03-1.863 1.077-2.415.096-.913-.263-1.37-1.077-1.37a3.022 3.022 0 0 0-1.185.261c.789-2.573 2.291-3.825 4.508-3.756 1.644.05 2.419 1.117 2.324 3.2z" />
    //                       </svg></Link>



    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="row d-flex justify-content-between">
    //               <h4 className="text-dark fw-bolder mt-4">Xamarin Offline data sync with Mongo Realm and RabbitMQ</h4>
    //               <iframe width="500" height="700" src="https://player.vimeo.com/video/709034693?h=2bae0c446e" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="allowFullScreen"> </iframe>
    //               <p className="details">In this article we are implementing the following scenario which involves :</p>
    //               <ol className="ms-4">
    //                 <li>Net zero Xamarin mobile app</li>
    //                 <li>Mongo Realm DB on the mobile device</li>
    //                 <li>Realm Cloud DB</li>
    //                 <li>RabbitMQ integration</li>
    //               </ol>
    //               <h5 className="fw-bolder">Problem Statement</h5>
    //               <img className="py-3" src={DataSync} alt="dataSync" />
    //               <p className="details">We need the Xamarin based mobile app to work offline and for saving the data offline when there is no internet connection. The app then needs to sync data with the web when the connectivity is established.</p>
    //               <p className="details"> We are using the Realm DB which offers an out of the box data sync feature that will help us to sync the offline data with the web.</p>
    //               <p className="details">The data also needs to be synced with the aspnetzero SQL database.</p>
    //               <p className="details">For this purpose, we are integrating RabbitMQ which triggers a data sync with the SQL database whenever any changes are pushed to Realm cloud.</p>
    //               <h5 className="fw-bolder">Implementation</h5>
    //               <p className={`details ${!isLoggedIn && 'opacity-25'}`} >Now let us see how this scenario is implemented.</p>
    //               <p className={`details ${!isLoggedIn && 'opacity-25'}`} >1. Create MongoDB account.</p>

    //               {!isLoggedIn &&
    //                                             <div className="row bg-light mb-5">
    //                                                 <div className="col-lg-4">
    //                                                     <img className="w-75 pt-5 ms-4" src={readPostImg} alt="Logo" />
    //                                                 </div>
    //                                                 <div className="col-lg-8">
    //                                                     <h6 className="mt-3">To read the full post,</h6>
    //                                                     <h4 className="fw-bolder">Sign up to AAA Academy</h4>
    //                                                     <p className="py-2">A guide on how to integrate Syncfusion component library with the ABP framework and Blazor UI</p>
    //                                                     <Link to={`/signUp`}>
    //                                                         <button type="button" className="btn btn-danger fw-semibold  px-3 signup-button-cards rounded-1 sm-w-100">Sign Up</button>
    //                                                     </Link>
    //                                                     <p className="mt-2">Already have an account?
    //                                                         <Link to="/login" className="text-decoration-none color"> Sign in
    //                                                         </Link> </p>
    //                                                 </div>
    //                                             </div>}
    //                                         {isLoggedIn && <>

    //               <img className="py-3" src={getStartedFree} alt="Get Started Free" />
    //               <p className="details">2. Create New Organization or Select Existing Organization</p>
    //               <img className="py-3" src={organizations} alt="Organizations" />
    //               <p className="details">3. Select Organization or Search for an Organization</p>
    //               <img className="py-3" src={selectOrganizations} alt="Select Organizations" />
    //               <p className="details">4. Create New Project or Select Existing Project</p>
    //               <img className="py-3" src={createNewProject} alt="Create New Project" />
    //               <p className="details">5. Create New Database Deployments</p>
    //               <img className="py-3" src={CreateNewDatabase} alt="Create New Database" />
    //               <p className="details">6. New Database Deployments Created</p>
    //               <img className="py-3" src={newDatabase} alt="New Database" />
    //               <p className="details">7. Create Application in MongoDB Realm.</p>
    //               <img className="py-3" src={createAppInMongoDB} alt="Create App In MongoDB" />
    //               <p className="details">8. Add NuGet package ‘Realm’ to all Project Solutions.</p>
    //               <img className="py-3" src={addNuGetPackage} alt="Add NuGet Package" />
    //               <p className="details">9. Add ‘appId’ in Xamarin File app.xaml.cs so it could interact with Application on MongoDB.</p>
    //               <img className="py-3" src={xamarin} alt="xamarin" />
    //               <img className="py-3" src={appXAML} alt="appXAML Code" />
    //               <CodeSnippet codeString={privateString} />
    //               <p className="details">10. Add Data in app.xaml.cs à ‘RealmApp = Realms.Sync.App.Create(appId);’.</p>
    //               <img className="py-3" src={addData} alt="Add Data" />
    //               <CodeSnippet codeString={appConfig} />
    //               <p className="details">11.Create Entries and Sync in xaml.cs files and added Realm Package file in them.</p>
    //               <img className="py-3" src={createEntriesForm} alt="Create Entries Form" />
    //               <img className="py-3" src={createEntriesJS} alt="create Entries JS" />
    //               <img className="py-3" src={createEntriesUsing} alt="Create Entries Using" />
    //               <p className="details">12. Add Objects and Users in Login and AddItems Page.</p>
    //               <img className="py-3" src={addObjectsUserLogin} alt="Add Objects and users in login and add items page" />
    //               <CodeSnippet codeString={usingAsyncTask} />
    //               <p className="details">13. Create new Cluster and Integrated it with Application.</p>
    //               <img className="py-3" src={databaseDeployments} alt="Database Deployments" />
    //               <p className="details">14. Add Database and Collection in MongoDB Atlas à Cluster.</p>
    //               <img className="py-3" src={databaseCollections} alt="Add Database Collections" />
    //               <p className="details">15. Add Models with ‘RealmObject’ and integrated them in xaml.cs files so the data could be passed through them.</p>
    //               <img className="py-3" src={addModels} alt="Add Models" />
    //               <img className="py-3" src={classDataFlow} alt="class DataFlow" />
    //               <p className="details">16. Change Port ‘Server’ according to your device in Migrator and Host appsettings.json files</p>
    //               <img className="py-3" src={appSettingsJson} alt="App Settings Json File" />
    //               <p className="details">17. Run ‘dotnet run’ in Command Prompt of Migrator to migrate data.</p>
    //               <p className="details">18. After running the simulator, enter data in Entries and Sent data to Realm offline database.</p>
    //               <img className="py-3 w-50" src={createNewTenant} alt="Create New Tenant" />
    //               <p className="details">19. After syncing the data is displayed on MongoDB Cluster à Database à Collection after it syncs online.</p>
    //               <img className="py-3" src={mongoDBCluster} alt="MongoDB Cluster" />
    //               <p className="details">20. Create Worker Services(background services) project (Producer), which read data from MongoDB and send/publish the messages(data) to Asp.net Zero app (Consumers) using RabbitMQ</p>
    //               <p className="details">21. net Zero App(Consumer) received the message from producer and synch data into SQL server</p>
    //               <p className="details">22. RabbitMQ act a communicator middleware between both producer and consumers</p>
    //               <h5 className="fw-bolder my-4">Create Services (background services) project (Producer), which read data from MongoDB and send/ Worker publish the messages(data) to Asp.net Zero app (Consumers) using RabbitMQ</h5>
    //               <p className="details">1. Create new Worker Service Project DbSyncWorker(background service)</p>
    //               <img className="py-3" src={createNewProjDbSyncWorker} alt="Create New Project Db Sync Worker" />
    //               <p className="details">2.  Install Packages View - Other Windows - Package Manager Console</p>
    //               <img className="py-3" src={packageReference} alt="package Reference" />
    //               <p className="details">3. Create Generic Repository class IRepository.cs class</p>
    //               <img className="py-3" src={createGenericRepoClass} alt="Create Generic Repo Class" />
    //               <CodeSnippet codeString={usingSystem} />
    //               <p className="details">4. Implement IRepository Interface</p>
    //               <img className="py-3" src={implementIRepoInterface} alt="Implement IRepository Interface" />
    //               <CodeSnippet codeString={usingMongo} />
    //               <p className="details">5. Create Model class</p>
    //               <img className="py-3" src={createModalClass} alt="Create Modal Class" />
    //               <CodeSnippet codeString={usingMongoDb} />
    //               <img className="py-3" src={integrationEventLogs} alt="Integration Event Logs" />
    //               <CodeSnippet codeString={mongoDBBson} />
    //               <p className="details">6. Implement Model</p>
    //               <img className="py-3" src={waiDbSyncRepo} alt="Wai Db Sync Repo" />
    //               <CodeSnippet codeString={usingWai} />
    //               <p className="details">7. Create Context class</p>
    //               <img className="py-3" src={createContectClass} alt="Create Contect Class" />
    //               <CodeSnippet codeString={mongoDriver} />
    //               <p className="details">8. UOM Implementation</p>
    //               <img className="py-3" src={UOMImplementation} alt="UOM Implementation" />
    //               <CodeSnippet codeString={interfacaces} />
    //               <p className="details">9. Configure appsetting.json</p>
    //               <img className="py-3" src={configureAppsettings} alt="Configure Appsettings" />
    //               <CodeSnippet codeString={logging} />
    //               <img className="py-3" src={configureAppsettingClassWorker} alt="Configure Appsetting Class Worker" />
    //               <p className="details"> 10. Create Worker.Cs class</p>
    //               <img className="py-3" src={createWorkerClass} alt="Create Worker Class" />
    //               <CodeSnippet codeString={usingSystemCode} />
    //               <p className="details"> 11. Add a synch method ExecuteAsync which is call after every 5 second</p>
    //               <img className="py-3" src={addASyncMethod} alt="Add ASync Method" />
    //               <p className="details"> 12. Configure Service in Program.cs</p>
    //               <img className="py-3" src={configureService} alt="Configure Service" />
    //               <CodeSnippet codeString={DbSyncWorker} />
    //               <h5 className="fw-bolder my-4">Performing Changes in the ASP.Net zero Application. This application received message from producer and synch data into SQL server</h5>
    //               <p className="details">First create a new ASP.Net Zero application either using following the steps outlined here</p>
    //               <Link to="https://docs.aspnetzero.com/en/aspnet-core-angular/latest/Getting-Started-Angular" className="text-decoration-none">Getting Started Angular | Documentation Center (aspnetzero.com)</Link>
    //               <p className="details">Do changes as per below steps</p>
    //               <p className="details">1. Add package RabbitMQ in asp.net project</p>
    //               <img className="py-3" src={addPackage} alt="Add Package" />
    //               <p className="details">2. Add New Class MessageHandler.cs in Core project</p>
    //               <img className="py-3" src={addClassMessageHandler} alt="Add Class Message Handler" />
    //               <p className="details">3.Add method DoWork in MessageHandler.cs class: Periodic works should be done by implementing this method</p>
    //               <img className="py-3" src={doWorkMethod} alt="Do Work Method" />
    //               <CodeSnippet codeString={classMessageHandler} />
    //               <p className="details">4. Add Entry in (ProjectName)Module.cs class of Core project</p>
    //               <img className="py-3" src={postInitializeMethod} alt="Post Initialize Method" />
    //               <CodeSnippet codeString={workManager} />
    //               <p className="details">5.Changes in MyTraining1101DemoWebHostModule.cs of WebHost Project as</p>
    //               <img className="py-3" src={changesInMyTraining} alt="Changes In MyTraining" />
    //               <CodeSnippet codeString={workManagerAdd} /></>}
    //             </div>
    //             <div><i className="bi bi-hand-thumbs-up"><span className="px-2">10</span></i><i className="bi bi-chat-left-text"><span className="px-2">7</span></i></div>
    //             <div className="py-3">
    //               <h4 className="text-dark fw-bolder">Featured Comments</h4>
    //               <div className="d-flex align-items-center">
    //                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                   <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                   <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                     <div>
    //                       <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
    //                       <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
    //                     </div>
    //                   </span>
    //                 </div>
    //               </div>
    //               <p className="text-muted mx-5">Reply</p>
    //               <div className="d-flex align-items-center">
    //                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                   <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                   <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                     <div>
    //                       <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
    //                       <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
    //                     </div>
    //                   </span>
    //                 </div>
    //               </div>
    //               <p className="text-muted mx-5">Reply</p>
    //               <div className="d-flex align-items-center mx-5">
    //                 <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                   <img className="avatar bg-light white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                   <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                     <div>
    //                       <span>Joe Thomson</span><span className="featured-date ms-4">Today at 5:42PM</span>
    //                       <p className="mb-0 text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
    //                     </div>
    //                   </span>
    //                 </div>
    //               </div>
    //               <p className="text-muted mx-5">Reply</p>
    //             </div>
    //             <div className="row aarw">
    //               <div className="col-12">
    //                 <h5 className="related-title">More Articles from Joe Thomson</h5>
    //               </div>
    //               <div className="col-12">
    //                 <div className="col-12 col-md-6 col-lg-4 mb-4">
    //                   <div className="card-aa ca1 border-0 h-100">
    //                     <Link className="nav-link" to="/footer">
    //                       <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
    //                       <div className="card-body-aa px-3">
    //                         <div className="row mb-2">
    //                           <div className="col-md-8">
    //                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
    //                           </div>
    //                           <div className="col-md-4 card-date mt-1">19/02/2023</div>
    //                         </div>
    //                         <div className="position mb-2">Software Development</div>
    //                         <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
    //                         <div className="d-flex justify-content-between">
    //                           <p className="font-colored">Beginner -5 min</p>
    //                           <div>
    //                             <div className="rate p-0">
    //                               <span className="font-normal">4.5</span>
    //                               <input type="radio" id="star5" name="rate" value="5" />
    //                               <label htmlFor="star5" title="text">5 stars</label>
    //                               <input type="radio" id="star4" name="rate" value="4" />
    //                               <label htmlFor="star4" title="text">4 stars</label>
    //                               <input type="radio" id="star3" name="rate" value="3" />
    //                               <label htmlFor="star3" title="text">3 stars</label>
    //                               <input type="radio" id="star2" name="rate" value="2" />
    //                               <label htmlFor="star2" title="text">2 stars</label>
    //                               <input type="radio" id="star1" name="rate" value="1" />
    //                               <label htmlFor="star1" title="text">1 star</label>
    //                             </div>
    //                             <span className="font-normal">520</span>
    //                           </div>
    //                         </div>
    //                         <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
    //                       </div>
    //                     </Link>
    //                   </div>
    //                 </div>
    //                 <div className="col-12 col-md-6 col-lg-4 mb-4">
    //                   <div className="card-aa ca1 border-0 h-100">
    //                     <Link className="nav-link" to="/footer">
    //                       <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
    //                       <div className="card-body-aa px-3">
    //                         <div className="row mb-2">
    //                           <div className="col-md-8">
    //                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
    //                           </div>
    //                           <div className="col-md-4 card-date mt-1">19/02/2023</div>
    //                         </div>
    //                         <div className="position mb-2">Software Development</div>
    //                         <h6 className="aa-card-title mb-2">Microsoft Azure - Must htmlFor Beginner's Guide</h6>
    //                         <div className="d-flex justify-content-between">
    //                           <p className="font-colored">Beginner -5 min</p>
    //                           <div>
    //                             <div className="rate p-0">
    //                               <span className="font-normal">4.5</span>
    //                               <input type="radio" id="star5" name="rate" value="5" />
    //                               <label for="star5" title="text">5 stars</label>
    //                               <input type="radio" id="star4" name="rate" value="4" />
    //                               <label for="star4" title="text">4 stars</label>
    //                               <input type="radio" id="star3" name="rate" value="3" />
    //                               <label for="star3" title="text">3 stars</label>
    //                               <input type="radio" id="star2" name="rate" value="2" />
    //                               <label for="star2" title="text">2 stars</label>
    //                               <input type="radio" id="star1" name="rate" value="1" />
    //                               <label for="star1" title="text">1 star</label>
    //                             </div>
    //                             <span className="font-normal">520</span>
    //                           </div>
    //                         </div>
    //                         <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
    //                       </div>
    //                     </Link>
    //                   </div>
    //                 </div>
    //                 <div className="col-12 col-md-6 col-lg-4 mb-4">
    //                   <div className="card-aa ca1 border-0 h-100">
    //                     <Link className="nav-link" to="/footer">
    //                       <img className="card-img-top" src={imageLogo} alt="Implement Single Sign-On with ABP commercial" />
    //                       <div className="card-body-aa px-3">
    //                         <div className="row mb-2">
    //                           <div className="col-md-8">
    //                             <img src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" alt="Avatar" className="avatar rounded-circle w-10" /><span className="user-name m-2">Username</span>
    //                           </div>
    //                           <div className="col-md-4 card-date mt-1">19/02/2023</div>
    //                         </div>
    //                         <div className="position mb-2">Software Development</div>

    //                         <h6 className="aa-card-title mb-2">Microsoft Azure - Must for Beginner's Guide</h6>
    //                         <div className="d-flex justify-content-between">
    //                           <p className="font-colored">Beginner -5 min</p>
    //                           <div>
    //                             <div className="rate p-0">
    //                               <span className="font-normal">4.5</span>
    //                               <input type="radio" id="star5" name="rate" value="5" />
    //                               <label for="star5" title="text">5 stars</label>
    //                               <input type="radio" id="star4" name="rate" value="4" />
    //                               <label for="star4" title="text">4 stars</label>
    //                               <input type="radio" id="star3" name="rate" value="3" />
    //                               <label for="star3" title="text">3 stars</label>
    //                               <input type="radio" id="star2" name="rate" value="2" />
    //                               <label for="star2" title="text">2 stars</label>
    //                               <input type="radio" id="star1" name="rate" value="1" />
    //                               <label for="star1" title="text">1 star</label>
    //                             </div>
    //                             <span className="font-normal">520</span>
    //                           </div>
    //                         </div>
    //                         <p className="aa-description">Etiam convallis elementum sapien, a aliquam turpis aliquam vitae. Praesent sollicitudin felis vel mi facilisis posuere.<span className="visibility-hidden"> Commercial modular</span></p>
    //                       </div>
    //                     </Link>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
    //             <div className="row d-flex justify-content-between py-3">
    //               <div className="col-md-6">
    //                 <div className="d-flex align-items-center">
    //                   <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                     <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                     <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                       <div>
    //                         <span>Kirti Kulkarni</span>
    //                       </div>
    //                     </span>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="align-items-end d-flex justify-content-end">

    //                   <div className="footer-social d-flex gap-0">

    //                     <Link to="https://www.linkedin.com/company/hire-tech-team/">
    //                       <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#C1C7D0" className="bi bi-linkedin fs-6 mx-1 px-1" viewBox="0 0 16 16">
    //                         <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
    //                       </svg>
    //                     </Link>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <h6 className="about-author">ABOUT THE AUTHOR</h6>
    //             <p className="details">Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus. </p>
    //             <div>
    //               <h5 className="fw-medium mb-3">Latest Articles</h5>
    //               <Link className="nav-link" to="/blog/integratingElsaNet">
    //                 <div className="card mb-3 px-3 py-2">
    //                   <div className="row g-0">
    //                     <div className="col-md-8">
    //                       <h5 className="card-title">
    //                         <div className="d-flex align-items-center">
    //                           <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                               <div>
    //                                 <span className="fs-6">Kirti Kulkarni</span>
    //                               </div>
    //                             </span>
    //                           </div>
    //                         </div>
    //                       </h5>
    //                       <p className="card-text details">Integrating Elsa .NET Workflows with ABP Commercial</p>
    //                     </div>
    //                     <div className="col-md-4">
    //                       <div className="card-body">
    //                         <img src={integratingElsaImg} className="img-fluid" alt="Integrating Elsa Img" />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </Link>
    //               <Link className="nav-link" to="/blog/fileManagementModule">
    //                 <div className="card mb-3 px-3 py-2">
    //                   <div className="row g-0">
    //                     <div className="col-md-8">
    //                       <h5 className="card-title">
    //                         <div className="d-flex align-items-center">
    //                           <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                               <div>
    //                                 <span className="fs-6">Kirti Kulkarni</span>
    //                               </div>
    //                             </span>
    //                           </div>
    //                         </div>
    //                       </h5>
    //                       <p className="card-text details">File Management Module</p>
    //                     </div>
    //                     <div className="col-md-4">
    //                       <div className="card-body">
    //                         <img src={fileManagementImg} className="img-fluid" alt="File Management Module" />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </Link>
    //               <Link className="nav-link" to="/blog/workWithPdfAbpCommercial">
    //                 <div className="card mb-3 px-3 py-2">
    //                   <div className="row g-0">
    //                     <div className="col-md-8">
    //                       <h5 className="card-title">
    //                         <div className="d-flex align-items-center">
    //                           <div ng-reflect-ng-className="flex-grow-0 align-items-center" className="flex-grow-0 align-items-center gap-2 d-flex">
    //                             <img className="avatar bg-light rounded-circle white-box" src="https://t4.ftcdn.net/jpg/04/10/43/77/240_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg" ng-reflect-ng-className="bg-light,rounded-circle" ng-reflect-ng-style="[object Object]" />
    //                             <span className="avatar-initials flex-grow-1 align-items-center ms-2 fw-bold text-decoration-none" ng-reflect-ng-className="">
    //                               <div>
    //                                 <span className="fs-6">Kirti Kulkarni</span>
    //                               </div>
    //                             </span>
    //                           </div>
    //                         </div>
    //                       </h5>
    //                       <p className="card-text details">Work with PDF's in ABP Commercial Project using PDFTron</p>
    //                     </div>
    //                     <div className="col-md-4">
    //                       <div className="card-body">
    //                         <img src={pdfTronImg} className="img-fluid" alt="Work with PDF's in ABP Commercial Project using PDFTron" />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </Link>
    //             </div>
    //           </div>

    //         </div>
    //       </div>
    //     </section>
    //     </Container>

    //   </div>



    // </Layout>

  );
};

export default SamplePage;
