import React from 'react';
import { withPrefix } from 'gatsby';
import { Link } from 'gatsby';
import './sendLink.css'
import loginArrow from "../../static/AAA-images/login-arrow.svg"



const sendLink = () => {

    return (

        <>

            <section className="login-background" style={{ backgroundImage: `url(${withPrefix('AAA-images/login-bg.png')})` }}>
                <div className="align-items-center container d-flex justify-content-center vh-100">
                    <div className="w-32">
                        <div className="bg-white row rounded-3  w-22">

                            <div className="col-md-12">
                                <div className="py-5 px-3">
                                    <div className="pb-4">
                                        <div href="#" className="sendlink-arrow cmn-backarrow">
                                                <div >
                                                  <Link to={`/forgot`}>
                                                    <img src={loginArrow} height="40px" width="40px" />
                                                  </Link>
                                                </div>
                                                <div>
                                                  <h6 className="fw-bolder fs-3">Create New Password</h6>
                                                </div>
                                        </div>
                                    </div>
                                    <form className='subscribe'>
                                        <div className="mb-3">
                                            <label for="newPassword" className="form-label">New Password</label>
                                            <input type="password" className="form-control" id="newPassword" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;" />
                                        </div>
                                        <div className="mb-1">
                                            <label for="confirmPassword" className="form-label">Confirm Password</label>
                                            <input type="password" className="form-control" id="confirmPassword" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;" />
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-12 mt-2">
                                                <small>Password must:</small>
                                                <ul className='password-must small pt-1'>
                                                    <li>Be a minimum of 8 characters</li>
                                                    <li>Include at least one lowercase letter (a-z)</li>
                                                    <li>Include at least one uppercase letter (A-Z)3</li>
                                                    <li>Include at least one number (0-9)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pt-3 d-flex align-items-center justify-content-center">
                                            <button type="submit" className="btn button-primary text-white fw-bold">
                                                <Link to={`/signup`} className="text-white">Reset Password</Link>
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default sendLink;
