import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { validateEmail, isEmpty } from '../helpers/general';
import { withPrefix } from 'gatsby';
import googleLogo from "../../static/AAA-images/Google.svg"
import msLogo from "../../static/AAA-images/login-microsoft.svg"
import loginArrow from "../../static/AAA-images/login-arrow.svg"
import linkedinLogo from "../../static/AAA-images/linkedin.svg"
import './login.css';

// import AttributeGrid from '../components/AttributeGrid/AttributeGrid';
// import Layout from '../components/Layout/Layout';
// import FormInputField from '../components/FormInputField/FormInputField';
// import Button from '../components/Button';

const LoginPage = (props) => {
  const initialState = {
    email: '',
    password: '',
  };

  const errorState = {
    email: '',
    password: '',
  };

  const [loginForm, setLoginForm] = useState(initialState);
  const [errorForm, setErrorForm] = useState(errorState);
  const [errorMessage, setErrorMessage] = useState('');
  const [urlPath, setUrlPath] = useState('');
  const handleChange = (id, e) => {
    const tempForm = { ...loginForm, [id]: e };
    setLoginForm(tempForm);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let validForm = true;
    const tempError = { ...errorForm };

    if (validateEmail(loginForm.email) !== true) {
      tempError.email =
        'Please use a valid email address, such as user@example.com.';
      validForm = false;
    } else {
      tempError.email = '';
    }

    if (isEmpty(loginForm.password) === true) {
      tempError.password = 'Field required';
      validForm = false;
    } else {
      tempError.password = '';
    }

    if (validForm === true) {
      setErrorForm(errorState);

      //mock login
      if (loginForm.email !== 'error@example.com') {
        navigate('/account');
        window.localStorage.setItem('key', 'sampleToken');
      } else {
        window.scrollTo(0, 0);
        setErrorMessage(
          'There is no such account associated with this email address'
        );
      }
    } else {
      setErrorMessage('');
      setErrorForm(tempError);
    }
  };


  function loggedForm() {
    debugger
    localStorage.setItem('isLoggedIn', 'true');

  }
useEffect(() => {
 const urlValue = localStorage.getItem('currentpath');
 if(urlValue == '/blog/sample'){
  setUrlPath('/blog/sample')
 }else if(urlValue == '/blog/xamarinOfflineDataSync'){
  setUrlPath('/blog/xamarinOfflineDataSync')
 }else if(urlValue == '/blog/integratingElsaNet'){
  setUrlPath('/blog/integratingElsaNet')
 }else if(urlValue == '/blog/integratingAndEnablingChat'){
  setUrlPath('/blog/integratingAndEnablingChat')
 }else if(urlValue == '/blog/fileManagementModule'){
  setUrlPath('/blog/fileManagementModule')
 }else if(urlValue == '/blog/workWithPdfAbpCommercial'){
  setUrlPath('/blog/workWithPdfAbpCommercial')
 }else if(urlValue == '/blog/createCustomLoginPageAbp'){
  setUrlPath('/blog/createCustomLoginPageAbp')
 }else if(urlValue == '/blog/deployingAbpAngularAppAzure'){
  setUrlPath('/blog/deployingAbpAngularAppAzure')
 }else if(urlValue == '/blog/usingWebhooksAbpCommercial'){
  setUrlPath('/blog/usingWebhooksAbpCommercial')
 }else if(urlValue == '/blog/createDatabasePerTenantSQL'){
  setUrlPath('/blog/createDatabasePerTenantSQL')
 }
},[])

  return (
    // <Layout disablePaddingBottom={true}>
    //   <div
    //     className={`${styles.errorContainer} ${
    //       errorMessage !== '' ? styles.show : ''
    //     }`}
    //   >
    //     <span className={styles.errorMessage}>{errorMessage}</span>
    //   </div>

    //   <div className={styles.root}>
    //     <div className={styles.loginFormContainer}>
    //       <h1 className={styles.loginTitle}>Login</h1>
    //       <span className={styles.subtitle}>
    //         Please enter your e-mail and password
    //       </span>
    //       <form
    //         noValidate
    //         className={styles.loginForm}
    //         onSubmit={(e) => handleSubmit(e)}
    //       >
    //         <FormInputField
    //           id={'email'}
    //           value={loginForm.email}
    //           handleChange={(id, e) => handleChange(id, e)}
    //           type={'email'}
    //           labelName={'Email'}
    //           error={errorForm.email}
    //         />

    //         <FormInputField
    //           id={'password'}
    //           value={loginForm.password}
    //           handleChange={(id, e) => handleChange(id, e)}
    //           type={'password'}
    //           labelName={'Password'}
    //           error={errorForm.password}
    //         />
    //         <div className={styles.forgotPasswordContainer}>
    //           <Link to={'/forgot'} className={styles.forgotLink}>
    //             Forgot Password
    //           </Link>
    //         </div>

    //         <Button fullWidth type={'submit'} level={'primary'}>
    //           LOG IN
    //         </Button>
    //         <span className={styles.createLink}>New Customer? </span>
    //         <Button
    //           type={'button'}
    //           onClick={() => navigate('/signup')}
    //           fullWidth
    //           level={'secondary'}
    //         >
    //           create an account
    //         </Button>
    //       </form>
    //     </div>

    //     <div className={styles.attributeGridContainer}>
    //       <AttributeGrid />
    //     </div>
    //   </div>
    // </Layout>

    <>

      <section className="login-background" style={{ backgroundImage: `url(${withPrefix('AAA-images/login-bg.png')})` }}>
        <div className="align-items-center container d-flex justify-content-center vh-100">
          <div className="w-32">
            <div className="bg-white row rounded-3  w-22">

              <div className="col-md-12">
                <div className="py-5 px-3">
                  <div className="pb-4">
                    <div href="#" className="login-arrow cmn-backarrow">
                      <div >
                        <Link to={`/`}>
                          <img src={loginArrow} height="40px" width="40px" />
                        </Link>
                      </div>
                      <div>
                        <h6 className="fw-bolder fs-3">Log in</h6>
                      </div>
                    </div>
                  </div>
                  <form className='subscribe'>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">Email</label>
                      <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='natasha.james@mail.com' />
                    </div>
                    <div >
                      <label for="exampleInputPassword1" className="form-label">Password</label>
                      <input type="password" className="form-control" id="exampleInputPassword1" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;" />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div className="mb-3 form-check small">
                        <input type="checkbox" className="form-check-input small" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">  Remember Me</label>
                      </div>
                      <div>
                        <Link to={`/forgot`} className="text-decoration-none color small">Forgot Your Password?</Link>
                      </div>
                    </div>
                    {/* <div className="text-center py-4">
                                                <button type="submit" className="btn button-primary text-white">Log in</button>
                                            </div> */}
                                            {/* '/blog/sample' */}
                    <Link to={urlPath}>
                      <button type="submit" className="btn button-primary text-white px-3 p-1 " onClick={(e) =>loggedForm(e.target.urlPath)}>Log in</button>
                      {/* <button type="submit" className="btn btn-primary px-3  p-1" onClick={loggedForm}>Log in</button> */}
                    </Link>
                    <div className="text-center pt-2 pb-4 d-flex gap-3 justify-content-center">
                      <img src={googleLogo} height="40px" width="40px" className="mx-2 p-1" />
                      <img src={msLogo} height="40px" width="40px" className="mx-2 p-1" />
                      <img src={linkedinLogo} height="40px" width="40px" className="mx-2 p-1" />
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-2">
                        <small>Don't have an account?<Link to={`/signup`} className="color mx-2 text-decoration-none">Sign Up</Link></small>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>


  );
};

export default LoginPage;
